import { assertIsDefined } from '@sgme/fp';
import type { Thunk } from 'state';
import { getTileOverriddenClientId } from 'state/clientWorkspace/selectors';
import type { Way } from 'state/share/productModel/litterals';
import { clearUndefined } from 'utils/clearUndefined';
import { fieldData } from '../../../utils/fieldSelectors';
import type { OrderType } from '../fxOrdersModel';

export function orderClientChangedThunk(orderId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const isFrozen = sl.isOrderFrozen(state, orderId);
    if (!isFrozen) {
      const companyId = sl.getCompanyIdFromClientId(state, clientId);
      dispatch(ac.espStreamRestartThunk(orderId, undefined, undefined, companyId));

      const orderType = fieldData(sl.getOrderType(state, orderId)).data;

      if (orderType === 'TakeProfit') {
        dispatch(ac.orderPropertyChanged(orderId, { customerPrice: null, margin: null }));
      }
    }
  };
}

export function orderCreatedFromTemplateThunk(
  workspaceId: string,
  quoteId: string,
  templateId: string,
  way?: Way,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl, getNewGuid }) => {
    const state = getState();
    const template = sl.getOrderTemplateById(state, templateId);
    assertIsDefined(template, 'Unexpected undefined template');

    const orderId = getNewGuid();

    dispatch(orderCreatedThunk(workspaceId, quoteId, template.fields.type!, undefined, undefined, way, orderId));

    dispatch(ac.orderApplyTemplate(orderId, templateId));
  };
}

export function orderCreatedThunk(
  clientWorkspaceId: string,
  quoteId: string,
  orderType: OrderType,
  limitPrice?: string,
  customerPrice?: string,
  way?: Way,
  newId?: string,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl, getNewGuid }) => {
    const state = getState();

    const tilePosition = sl.getGridItemPosition(state, clientWorkspaceId, quoteId);
    const tileSize = sl.getGridItemSize(state, clientWorkspaceId, quoteId);

    // Position order
    // close to top right corner of tile if not out of the screen, else flip
    const left = tilePosition.left + tileSize.width;

    const position = {
      top: tilePosition.top + 20,
      left: left + tileSize.width < window.innerWidth ? left : tilePosition.left - Math.floor(tileSize.width / 4),
    };

    const orderId = newId ?? getNewGuid();

    dispatch(ac.clientWorkspaceNewTileAdded(clientWorkspaceId, orderId, 'Order', null, null, position));

    const overrideClientId = getTileOverriddenClientId(state, quoteId);

    if (overrideClientId !== null) {
      dispatch(ac.tileClientOverridden(orderId, overrideClientId));
    }

    const availableTypes = sl.getAvailableOrderTypes(state);

    if (!availableTypes.includes(orderType)) {
      return;
    }

    const currencyPair = sl.getCashCurrencyPair(state, quoteId).value;
    const amount = sl.getCashAmount(state, quoteId).value?.toString();
    const amountCurrency = sl.getCashAmountCurrency(state, quoteId).value;
    const bdrId = sl.getClientIdByQuoteId(state, quoteId);

    const userEmails = sl.getUserPreferenceData(state).emails;
    const userEmailsOrder = sl.getUserPreferenceData(state).splitNotificationsEmailsOrders.join(';');
    const orderDefaultEmails = sl.getOrderDefaultEmails(state, bdrId!);

    const emails = [...orderDefaultEmails.filter((mail) => !userEmails.includes(mail)), ...userEmails].join(';');

    dispatch(
      ac.orderPropertyChangedTakeProfitMarginThunk(
        orderId,
        clearUndefined({
          type: orderType,
          emailsOrders: userEmailsOrder,
          currencyPair,
          amount,
          amountCurrency,
          emails,
          limitPrice,
          customerPrice,
          way: way && (way === 'Ask' ? 'Sell' : 'Buy'),
        }),
      ),
    );

    if (currencyPair) {
      dispatch(ac.espStreamRestartThunk(orderId, currencyPair, undefined, undefined));
    }
  };
}

export function orderClosedThunk(quoteId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }
    dispatch(ac.espTileStreamUnsubscribeThunk(quoteId));
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, quoteId);
    return dispatch(ac.clientWorkspaceTileDeleted(quoteId, tabId, automatically));
  };
}
