import { connect } from 'react-redux';
import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import { type ActionCreators, actionCreators } from 'state/actions';
import { Catch } from './share/Catch';

export type AppCrashConnectOwnProps = {}

export interface AppCrashConnectDispatchProps {
  onCatch(error: Error, errorInfo: React.ErrorInfo): void;
}

export type AppCrashConnectActionCreators = ActionCreators;
/*
export type AppCrashConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type AppCrashConnectActionCreators = Pick<ActionCreators, AppCrashConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsAppCrash: MapDispatchToPropsHOF<
  AppCrashConnectDispatchProps,
  AppCrashConnectOwnProps,
  AppCrashConnectActionCreators
> = ac => dispatch => ({
  onCatch(_error: Error, errorInfo: React.ErrorInfo) {
    const errorMessage = (errorInfo.componentStack ?? "") + (_error.stack ?? "");

    dispatch(ac.createAppCrashedAction('React AppCrash', errorMessage));
  },
});

export const AppCrash = connect(null, mapDispatchToPropsAppCrash(actionCreators))(Catch);
