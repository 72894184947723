import type { SavedBlotterTab } from 'api/workspaceService/model';
import { extract } from 'utils/object';
import {
  type BlotterTabMetadata,
  type ColumnId,
  type FxColumnState,
  defaultAccumulatorTab,
  defaultCashTab,
  defaultOptionTab,
  defaultOrderTab,
} from './blotterModel';

export const updateBlotterColumns = (
  defaultColumns: readonly FxColumnState[],
  savedColumns: SavedBlotterTab['columnsState'],
): FxColumnState[] => {
  const defaultColIds = defaultColumns.map(extract('colId'));

  const cleanSavedColumns = savedColumns.filter(
    (column) => defaultColIds.includes(column.colId as ColumnId) || column.colId === 'ag-Grid-AutoColumn', // id column is stored like this
  ) as FxColumnState[];

  const userColIds = cleanSavedColumns.map(extract('colId'));

  const missingColumns = defaultColumns.filter((column) => !userColIds.includes(column.colId));

  return missingColumns.reduce((acc, col) => {
    const previousColumnIndex = defaultColumns.findIndex(({ colId }) => colId === col.colId) - 1;

    if (previousColumnIndex === -1) {
      return [col, ...acc];
    }
    const previousColumnId = defaultColIds[previousColumnIndex];

    const positionToInsert = acc.findIndex(({ colId }) => colId === previousColumnId) + 1;

    return [...acc.slice(0, positionToInsert), col, ...acc.slice(positionToInsert)];
  }, cleanSavedColumns);
};

const injectBlotterTab =
  (defaultTab: BlotterTabMetadata) =>
  (savedTab: SavedBlotterTab): BlotterTabMetadata => ({
    ...defaultTab,
    ...savedTab,
    columnsState: updateBlotterColumns(defaultTab.columnsState, savedTab.columnsState as readonly FxColumnState[]),
  });

export const injectDefaultCashTab = injectBlotterTab(defaultCashTab);
export const injectDefaultOptionTab = injectBlotterTab(defaultOptionTab);
export const injectDefaultOrderTab = injectBlotterTab(defaultOrderTab);
export const injectDefaultAccumulatorTab = injectBlotterTab(defaultAccumulatorTab);
