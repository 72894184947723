import {
  PureComponent,
  type GetDerivedStateFromProps,
  type ReactNode,
  type MouseEvent as ReactMouseEvent,
} from 'react';
import styled, { type CSSProperties } from 'styled-components';
import type { GridItemPosition, GridItemSize } from 'state/gridLayout/gridLayoutModels';
import { colWidth } from 'styles/constants';
import { positiveOrZeroPosition } from 'state/gridLayout/positivePositions';
import { logger } from '../../logging/logger';

export const gridItemContainerClassName = 'position-absolute d-flex flex-column align-items-center';

const GridItemContainer = styled.div.attrs<{isDragging?: boolean}>({
  className: gridItemContainerClassName,
})`
  ${({ isDragging }: { isDragging?: boolean }) =>
    isDragging === true ? '' : 'transition: all 120ms ease-in-out;'}
`;

interface GridItemProps {
  gridItemId: string;
  position: GridItemPosition;
  size: GridItemSize;
  zIndex?: number;
  noDragSelector?: string;
  children: ReactNode;

  onDrag(gridItemId: string, position: GridItemPosition): void;

  onDragEnd(): void;
}

interface GridItemState {
  position: GridItemPosition;
  isDragging: boolean;
  originalPosition: GridItemPosition;
  originalCenterOfMass: GridItemPosition;
}

const sensitivityThresholdPixel = 20;
type Events = 'mousemove' | 'mouseup';

// TODO: remove the class !
export class GridItem extends PureComponent<GridItemProps, GridItemState> {
  // static window event listener collection
  private static windowEventListeners: Record<Events, EventListener | null> = {
    mouseup: null,
    mousemove: null,
  };
  private isMouseDown = false;
  private offsetPosition: GridItemPosition | undefined;

  private static removeWindowEventListeners(warnOnLeak = false) {
    Object.entries(GridItem.windowEventListeners).forEach(([event, eventListener]) => {
      if (eventListener !== null) {
        if (warnOnLeak === true) {
          logger.logWarning(`GridItem: unexpected {event_s} event bound to window`, event);
        }
        window.removeEventListener(event, eventListener, true);
        GridItem.windowEventListeners[event as Events] = null;
      }
    });
  }

  private static addWindowEventListeners(listeners: Record<Events, EventListener>) {
    // avoid leak between component instances or after dragend, should be noop
    GridItem.removeWindowEventListeners(true);
    Object.entries(listeners).forEach(([event, eventListener]) => {
      // store event listeners before attaching them to the window
      GridItem.windowEventListeners[event as Events] = eventListener;
      window.addEventListener(event, eventListener, { capture: true });
    });
  }

  private addWindowEventListeners = () =>
    GridItem.addWindowEventListeners({
      mouseup: this.dragEnd,
      mousemove: this.onMouseMove as EventListener,
    });

  private onMouseDown = (event: ReactMouseEvent<any>) => {
    // ignore click if left button is not pressed
    if (event.buttons % 2 === 0) {
      return;
    }
    // ignore mousedown event if mousedown is pending
    // most likely to happen if other button is pressed during move
    if (this.isMouseDown === true) {
      return;
    }

    if (this.props.noDragSelector !== undefined) {
      // from target dom node (lowest dom node at mousedown coordinates)
      // to current target dom node (this component dom node)
      // depending on node tag and class, mousedown may not trigger drag
      let target = event.target as HTMLElement | null;
      while (target !== null && target !== event.currentTarget) {
        if (target.matches(this.props.noDragSelector)) {
          return;
        }
        target = target.parentElement;
      }
    }
    // consider mousedown a valid drag start
    this.dragStart(event);
  };

  private onMouseMove = (event: MouseEvent) => {
    // bound mousemove with incoherent state safety
    // might be deleted if warn is never fired
    if (this.isMouseDown === false) {
      GridItem.removeWindowEventListeners();
      logger.logWarning('mousemove bound on window without corresponding toggle registered');
      return;
    }
    // bound mousemove with incoherent button press
    // if left click is not pressed
    // mouseup event have been missed
    // happens when alt-tabing out of the browser during drag and drop
    if (event.buttons % 2 === 0) {
      return this.dragEnd(event);
    }
    return this.drag(event);
  };

  private dragStart = (event: ReactMouseEvent<any>) => {
    // avoid event beeing propagated to children nodes
    event.stopPropagation();
    // bind all events
    this.addWindowEventListeners();
    // registers initial position as offset
    this.offsetPosition = { top: event.clientY, left: event.clientX };
    // don't update draggingStatus to true yet, it's better UX
    this.isMouseDown = true;
  };

  private drag = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (this.offsetPosition === undefined) {
      logger.logWarning('[GridItem] offsetPosition should be set on mousedown event');
      this.offsetPosition = { top: 0, left: 0 };
    }
    const deltaPosition: GridItemPosition = {
      top: event.clientY - this.offsetPosition.top,
      left: event.clientX - this.offsetPosition.left,
    };
    // if component is not considered dragging, should we update draggingStatus
    if (this.state.isDragging === false) {
      // if Manhattan distance is below threshold, don't consider component as dragging
      if (Math.abs(deltaPosition.top) + Math.abs(deltaPosition.left) <= sensitivityThresholdPixel) {
        return;
      }
    }
    // update positions
    const centerOfMass = {
      top: this.state.originalCenterOfMass.top + deltaPosition.top,
      left: this.state.originalCenterOfMass.left + deltaPosition.left,
    };
    const position = {
      top: this.state.originalPosition.top + deltaPosition.top,
      left: this.state.originalPosition.left + deltaPosition.left,
    };
    this.setState(() => ({ position, isDragging: true }));

    // forward update to prop callback
    this.props.onDrag(this.props.gridItemId, positiveOrZeroPosition(centerOfMass));
  };

  private dragEnd = (event: Event) => {
    if (event !== undefined) {
      event.preventDefault();
      event.stopPropagation();
    }
    GridItem.removeWindowEventListeners();
    this.isMouseDown = false;
    if (this.state.isDragging === true) {
      this.setState(() => ({ isDragging: false }));
      this.props.onDragEnd();
    }
  };

  public static getDerivedStateFromProps: GetDerivedStateFromProps<GridItemProps, GridItemState> = (
    { position, size },
    prevState,
  ) => {
    // ignore props during drag
    if (prevState.isDragging === true) {
      return null;
    }

    // reset positions for next drag event
    const originalPosition = position;
    const calculatedOriginalCenterOfMass = {
      top: position.top + Math.floor(size.height / 2),
      left: position.left + Math.floor(colWidth / 2),
    };
    const originalCenterOfMass =
      prevState.originalCenterOfMass !== undefined &&
      (prevState.originalCenterOfMass.top !== calculatedOriginalCenterOfMass.top ||
        prevState.originalCenterOfMass.left !== calculatedOriginalCenterOfMass.left)
        ? calculatedOriginalCenterOfMass
        : prevState.originalCenterOfMass;
    // redraw with new position
    return { position, originalPosition, originalCenterOfMass };
  };

  constructor(props: GridItemProps) {
    super(props);
    const centerOfMass: GridItemPosition = {
      top: props.position.top + Math.floor(props.size.height / 2),
      left: props.position.left + Math.floor(colWidth / 2),
    };
    const originalPosition = props.position;
    const originalCenterOfMass = centerOfMass;
    this.state = {
      isDragging: false,
      position: props.position,
      originalPosition,
      originalCenterOfMass,
    };
  }

  public componentWillUnmount() {
    // clean in case the component is removed from the dom during drag
    GridItem.removeWindowEventListeners();
  }

  public render() {
    const { zIndex = 0 } = this.props;
    const { position, isDragging } = this.state;
    const style: CSSProperties = {
      ...positiveOrZeroPosition(position),
      zIndex: isDragging === true ? 500 + zIndex : undefined,
    };
    return (
      <GridItemContainer isDragging={isDragging} style={style} onMouseDown={this.onMouseDown}>
        {this.props.children}
      </GridItemContainer>
    );
  }
}
