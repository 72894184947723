import { useAppSelector } from '../../state';
import { bulkOrdersForBdrAndCurrencyPair } from '../../state/fxOrderBulks/fxOrderBulksSelectors';
import { OrderTable } from './OrderTable';

type CurrencyPairSectionProps = {
  bdrId: string;
  currencyPair: string;
};

export const CurrencyPairSection = (props: CurrencyPairSectionProps) => {
  const { bdrId, currencyPair } = props;

  const allOrderIds = useAppSelector((state) => bulkOrdersForBdrAndCurrencyPair(state, bdrId, currencyPair));

  return (
    <div>
      <h6 className="py-2" data-e2e={`currency-pair-${bdrId}-${currencyPair.replaceAll('/', '-')}`}>
        {currencyPair}
      </h6>

      <OrderTable orderIds={allOrderIds} bdrId={bdrId} currencyPair={currencyPair} isCompact />
    </div>
  );
};
