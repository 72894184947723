import type { Collection } from 'typings/utils';
import type { Predicate } from './predicates';

export function addKey<T>(map: Collection<T>, key: string, val: T): Collection<T> {
  if (map[key] !== undefined) {
    throw new Error('addKey cannot overwrite existing key');
  }
  return { ...map, [key]: val };
}

// export function removeKey<V extends Collection<any>, Key extends keyof V>(
//   map: V,
//   key: Key,
// ): Omit<V, Key> {
//   const { [key]: val, ...rest } = map;
//   return val !== undefined ? rest : map;
// }

// export function removeKeys<V extends Collection<any>, Keys extends Array<keyof V>>(
//   map: V,
//   keys: Keys,
// ): Omit<V, Keys[number]> {
//   return keys.reduce((acc, key) => removeKey(acc, key) as V /* just a trick */, map);
// }

// export const unpatchedEntries = <V extends Collection<any>, Keys extends Array<keyof V>>(
//   entriesMap: V,
//   patch: { [key in Keys[number]]: V[key] },
// ): Omit<V, Keys[number]> => removeKeys(entriesMap, Object.keys(patch)) as Omit<V, Keys[number]>;

export function removeKey<V>(map: Collection<V>, key: string): Collection<V> {
  const { [key]: val, ...rest } = map;
  return val !== undefined ? rest : map;
}

export function removeKeys<V>(map: Collection<V>, keys: readonly string[]): Collection<V> {
  return keys.reduce((acc, key) => removeKey(acc, key), map);
}

function compareObject<T, K extends keyof T>(a: T, b: T) {
  return ((key: K) => a[key] === b[key]) as Predicate<string>;
}

function isShallowContained<T>(origin: T, patch: Partial<T>): boolean {
  return Object.keys(patch).every(compareObject(origin, patch));
}

export type Patcher<T> = (keyState: T) => Partial<T> | null;
export type Putter<T> = (keyState: T) => T;
export type ComposedPatcher<T> = (keyState: T) => T;

export function patcherReducer<T>(previousState: T, patcher: Patcher<T>): T {
  const nextState = patcher(previousState);
  if (nextState === null || isShallowContained(previousState, nextState)) {
    return previousState;
  }
  return { ...previousState, ...nextState };
}

export function composeLeftPatcher<T>(...patchers: ReadonlyArray<Patcher<T>>): ComposedPatcher<T> {
  return (state: T) => patchers.reduce(patcherReducer, state);
}

/**
 * Apply a list of patchers to the value of a property of an object and clone the object
 * only if the property value changed.
 * @param map The object to update
 * @param key The name of the property
 * @param patchers The list of patchers
 * @returns The original or cloned/updated object
 */
export function updateKey<T>(
  map: Collection<T>,
  key: string,
  ...patchers: ReadonlyArray<Patcher<T>>
): Collection<T> {
  const origin = map[key];

  if (origin === undefined) {
    return map;
  }

  const patch = composeLeftPatcher(...patchers)(origin);

  if (patch === origin) {
    return map;
  }

  return {
    ...map,
    [key]: { ...origin, ...patch } as T,
  };
}

/**
 * update / set the value of the key, and not patch it as updateKey
 *
 * @param map
 * @param key
 * @param updater
 * @returns
 */
export function setKeyIfExisty<T>(
  map: Collection<T>,
  key: string,
  updater: (keyState: T) => T | null,
): Collection<T> {
  const origin = map[key];

  if (origin === undefined) {
    return map;
  }

  const value = updater(origin);

  if (value === origin) {
    return map;
  }

  return {
    ...map,
    [key]: value ?? undefined,
  };
}

/**
 * Returns a function that can get patchers and returns a function which when apply to an object
 * will use these patchers to compute a new property value.
 * If the property value is unchanged then the original object is return, otherwise a clone
 * of the object with the updated property is returned.
 * @param property The name of the property
 */
export const updateProperty =
  <K extends string>(property: K) =>
  <T>(...patchers: ReadonlyArray<Patcher<T>>) =>
  <C extends Record<K, T>>(obj: C): C => {
    const origin = obj[property];
    if (origin === undefined) {
      return obj;
    }
    const patch = composeLeftPatcher(...patchers)(origin);
    if (patch === origin) {
      return obj;
    }
    return {
      ...obj,
      [property]: { ...origin, ...patch },
    };
  };

export const updateArrayByMatchingKey =
  <T, K extends keyof T>(keyname: K, keyValue: T[K], ...patchers: ReadonlyArray<Patcher<T>>) =>
  (array: readonly T[]): readonly T[] => {
    let pristine = true;
    const newArray = array.map(data => {
      if (data[keyname] !== keyValue) {
        return data;
      }
      pristine = false;
      return composeLeftPatcher(...patchers)(data);
    });
    return pristine ? array : newArray;
  };

export const filterArrayByMatchingKey =
  <T, K extends keyof T>(keyname: K, keyValue: T[K]) =>
  (array: readonly T[]): readonly T[] => {
    let pristine = true;
    const newArray = array.filter(data => {
      if (data[keyname] !== keyValue) {
        return true;
      }
      pristine = false;
      return false;
    });
    return pristine ? array : newArray;
  };

export const insertAtIndex =
  <T>(data: readonly T[], index: number) =>
  (array: readonly T[]): readonly T[] => {
    if (data.length === undefined || data.length < 1) {
      return array;
    }
    const leftHandSlice = array.slice(0, index);
    const rightHandSlice = array.slice(index);
    return leftHandSlice.concat(data, rightHandSlice);
  };

export const entriesDiff = <T>(origin: Collection<T>, delta: Collection<T>) =>
  Object.entries(delta).filter(([key, value]) => value !== origin[key]);

export const unpatchedEntries = <V>(
  entriesMap: Collection<V>,
  patch: { [key: string]: any },
): Collection<V> => removeKeys(entriesMap, Object.keys(patch));

export function patchState<T>(state: T) {
  return (...patchers: ReadonlyArray<Patcher<T>>): T =>
    patchers.reduce((previousState: T, patcher: Patcher<T>) => {
      const nextState = patcher(previousState);
      if (nextState === null || isShallowContained(previousState, nextState)) {
        return previousState;
      }
      return { ...previousState, ...nextState };
    }, state);
}

export function updateValueByProperty<K extends string>(key: K) {
  return function withUpdater<V>(updater: Putter<V>) {
    return function apply<C extends Record<K, V>>(obj: C): Record<K, V> | null {
      const oldValue = obj[key];
      const newValue = updater(oldValue);
      if (oldValue === newValue) {
        return null;
      }
      return { [key]: newValue } as Record<K, V>;
    };
  };
}

export function patchValueByProperty<S, K extends keyof S>(
  obj: S,
  key: K,
  ...patchers: ReadonlyArray<Patcher<S[K]>>
) {
  const oldValue = obj[key];
  const newValue = composeLeftPatcher(...patchers)(oldValue);

  if (oldValue === newValue) {
    return obj;
  }

  return { ...obj, [key]: newValue };
}
