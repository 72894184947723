import type { IStreamError } from '../../globalError/globalErrorModel';
import type { QuoteContributionType, Way } from '../../share/productModel/litterals';
import type { IFxAmericanForwardExecutionData } from '../model/fxAmericanForwardProductModel';
import type { IQuote } from '../model/fxAmericanForwardStreamModel';

export interface AmericanForwardStreamRequested {
  type: 'AMERICAN_FORWARD_STREAM_REQUESTED';
  americanForwardId: string;
  forceTradable: boolean;
  bypassCheck: boolean;
}

export function americanForwardStreamRequested(
  americanForwardId: string,
  forceTradable: boolean,
  bypassCheck: boolean,
): AmericanForwardStreamRequested {
  return {
    type: 'AMERICAN_FORWARD_STREAM_REQUESTED',
    americanForwardId,
    forceTradable,
    bypassCheck,
  };
}


export interface CreateAmericanForwardRequestedEpic {
  type: 'AMERICAN_FORWARD_RFS_STARTED';
  cashId: string;
  streamId: string;
  rfsStartedAt: Date;
}

export function createAmericanForwardRequestedEpic(
  cashId: string,
  streamId: string,
  rfsStartedAt: Date,
): CreateAmericanForwardRequestedEpic {
  return {
    type: 'AMERICAN_FORWARD_RFS_STARTED',
    cashId,
    streamId,
    rfsStartedAt,
  };
}


export interface AmericanForwardStreamStarted {
  type: 'AMERICAN_FORWARD_STREAM_STARTED';
  americanForwardId: string;
  streamId: string;
  rfsStartedAt: Date;
  forceTradeable: boolean;
}

export function americanForwardStreamStarted(
  americanForwardId: string,
  streamId: string,
  rfsStartedAt: Date,
  forceTradeable: boolean,
): AmericanForwardStreamStarted {
  return {
    type: 'AMERICAN_FORWARD_STREAM_STARTED',
    americanForwardId,
    streamId,
    rfsStartedAt,
    forceTradeable,
  };
}

export interface AmericanForwardStreamCanceled {
  type: 'AMERICAN_FORWARD_STREAM_CANCELED';
  americanForwardId: string;
  streamId: string;
  shouldKeepAsExpired: boolean;
}

export function americanForwardStreamCanceled(
  americanForwardId: string,
  streamId: string,
  shouldKeepAsExpired: boolean,
): AmericanForwardStreamCanceled {
  return {
    type: 'AMERICAN_FORWARD_STREAM_CANCELED',
    americanForwardId,
    streamId,
    shouldKeepAsExpired,
  };
}





export interface AmericanForwardStreamInvalidated {
  type: 'AMERICAN_FORWARD_STREAM_INVALIDATED';
  americanForwardId: string;
  streamId: string;
}

export function americanForwardStreamInvalidated(
  americanForwardId: string,
  streamId: string,
): AmericanForwardStreamInvalidated {
  return {
    type: 'AMERICAN_FORWARD_STREAM_INVALIDATED',
    americanForwardId,
    streamId,
  };
}

export interface AmericanForwardStreamLastRemoved {
  type: 'AMERICAN_FORWARD_STREAM_LAST_REMOVED';
  americanForwardId: string;
  streamId: string;
}

export function americanForwardStreamLastRemoved(
  americanForwardId: string,
  streamId: string,
): AmericanForwardStreamLastRemoved {
  return {
    type: 'AMERICAN_FORWARD_STREAM_LAST_REMOVED',
    americanForwardId,
    streamId,
  };
}

export interface AmericanForwardStreamCanceledSent {
  type: 'AMERICAN_FORWARD_STREAM_CANCELED_SENT';
  quoteId: string;
  streamId: string;
}

export function americanForwardStreamCanceledSent(
  quoteId: string,
  streamId: string,
): AmericanForwardStreamCanceledSent {
  return {
    type: 'AMERICAN_FORWARD_STREAM_CANCELED_SENT',
    quoteId,
    streamId,
  };
}

export interface AmericanForwardStreamCancelFailed {
  type: 'AMERICAN_FORWARD_STREAM_CANCEL_FAILED';
  americanForwardId: string;
  streamId: string;
}

export function americanForwardStreamCancelFailed(
  americanForwardId: string,
  streamId: string,
): AmericanForwardStreamCancelFailed {
  return {
    type: 'AMERICAN_FORWARD_STREAM_CANCEL_FAILED',
    americanForwardId,
    streamId,
  };
}


export interface AmericanForwardStreamFailed {
  type: 'AMERICAN_FORWARD_STREAM_FAILED';
  americanForwardId: string;
  streamId: string;
  error: IStreamError;
}

export function americanForwardStreamFailed(
  americanForwardId: string,
  streamId: string,
  error: IStreamError,
): AmericanForwardStreamFailed {
  return {
    type: 'AMERICAN_FORWARD_STREAM_FAILED',
    americanForwardId,
    streamId,
    error,
  };
}

export interface AmericanForwardStreamTerminated {
  type: 'AMERICAN_FORWARD_STREAM_TERMINATED';
  americanForwardId: string;
  streamId: string;
  shouldKeepAsExpired: boolean;
}

export function americanForwardStreamTerminated(
  americanForwardId: string,
  streamId: string,
  shouldKeepAsExpired: boolean,
): AmericanForwardStreamTerminated {
  return {
    type: 'AMERICAN_FORWARD_STREAM_TERMINATED',
    americanForwardId,
    streamId,
    shouldKeepAsExpired,
  };
}

export interface AmericanForwardRfsClearError {
  type: 'AMERICAN_FORWARD_RFS_CLEAR_ERROR';
  quoteId: string;
}

export function americanForwardRfsClearError(quoteId: string): AmericanForwardRfsClearError {
  return { type: 'AMERICAN_FORWARD_RFS_CLEAR_ERROR', quoteId };
}

export interface AmericanForwardTraderNotificationReceived {
  type: 'AMERICAN_FORWARD_TRADER_NOTIFICATION_RECEIVED';
  americanForwardId: string;
  streamId: string;
  traderId: string | null;
  quoteContribution: QuoteContributionType | null;
}

export function americanForwardTraderNotificationReceived(
  americanForwardId: string,
  streamId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): AmericanForwardTraderNotificationReceived {
  return {
    type: 'AMERICAN_FORWARD_TRADER_NOTIFICATION_RECEIVED',
    americanForwardId,
    streamId,
    traderId,
    quoteContribution,
  };
}

export interface AmericanForwardQuoteReceived {
  type: 'AMERICAN_FORWARD_QUOTE_RECEIVED';
  americanForwardId: string;
  streamId: string;
  quote: IQuote;
  tiering: string | null;
  skippedLimitCheck: boolean;
  quoteContribution: QuoteContributionType | null;
}

export function americanForwardQuoteReceived(
  americanForwardId: string,
  streamId: string,
  quote: IQuote,
  tiering: string | null,
  skippedLimitCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): AmericanForwardQuoteReceived {
  return {
    type: 'AMERICAN_FORWARD_QUOTE_RECEIVED',
    americanForwardId,
    streamId,
    quote,
    tiering,
    skippedLimitCheck,
    quoteContribution,
  };
}

export function americanForwardExecutionSentSucceeded(executionId: string): AmericanForwardExecutionSentSucceeded {
  return {
    type: 'AMERICAN_FORWARD_EXECUTION_SENT_SUCCEEDED',
    executionId,
  };
}

export interface AmericanForwardExecutionSentSucceeded {
  type: 'AMERICAN_FORWARD_EXECUTION_SENT_SUCCEEDED';
  executionId: string;
}

export interface AmericanForwardExecutionSent {
  type: 'AMERICAN_FORWARD_EXECUTION_SENT';
  americanForwardId: string;
  quoteId: string;
  executionId: string;
  execution: IFxAmericanForwardExecutionData;
  instrument: 'AmericanForward';
}

export function americanForwardExecutionSent(
  americanForwardId: string,
  quoteId: string,
  executionId: string,
  execution: IFxAmericanForwardExecutionData,
): AmericanForwardExecutionSent {
  return {
    type: 'AMERICAN_FORWARD_EXECUTION_SENT',
    americanForwardId,
    quoteId,
    executionId,
    execution,
    instrument: 'AmericanForward',
  };
}

export interface AmericanForwardExecutionFailed {
  type: 'AMERICAN_FORWARD_EXECUTION_FAILED';
  streamError: IStreamError;
  executionId: string;
}

export function americanForwardExecutionFailed(executionId: string, streamError: IStreamError): AmericanForwardExecutionFailed {
  return {
    type: 'AMERICAN_FORWARD_EXECUTION_FAILED',
    streamError,
    executionId,
  };
}

export function americanForwardExecutionReceived(executionId: string): AmericanForwardExecutionReceived {
  return {
    type: 'AMERICAN_FORWARD_EXECUTION_RECEIVED',
    executionId,
  };
}
export interface AmericanForwardExecutionReceived {
  type: 'AMERICAN_FORWARD_EXECUTION_RECEIVED';
  executionId: string;
}



export interface AmericanForwardExecutionRequested {
  type: 'AMERICAN_FORWARD_EXECUTION_REQUESTED';
  americanForwardId: string;
  streamId: string;
  way: Way;
}

export function americanForwardExecutionRequested(
  americanForwardId: string,
  streamId: string,
  way: Way,
): AmericanForwardExecutionRequested {
  return {
    type: 'AMERICAN_FORWARD_EXECUTION_REQUESTED',
    americanForwardId,
    streamId,
    way,
  };
}
