import { noop } from '@sgme/fp';
import { createEventHandler } from 'components/share/createEventHandler';
import { useState } from 'react';
import type { RenderPropChildren } from 'typings/utils';
import { type ObjectPick, pick } from 'utils/object';
import { DecimalKey, type DecimalKeyChildrenProps } from './DecimalKey';

export interface NumberInputProps {
  value: string;
  locale: string;
  withAccelerators?: boolean;
  /**
   * @default false
   */
  allowNegative?: boolean;
  /**
   * @default false
   */
  integerOnly?: boolean;
  autofocus?: boolean;
  onSubmit(value: string): void;
  onBlurPristine?(): void;
  onFocus?(event: React.FocusEvent<HTMLInputElement>): void;
  onBlur?(): void;
  onDirty?(isDirty: boolean): void;
}
export interface NumberInputChildrenProps {
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur: React.FocusEventHandler<HTMLInputElement>;
  onFocus: React.FocusEventHandler<HTMLInputElement>;
  onKeyDown: React.KeyboardEventHandler<HTMLInputElement>;
  onPaste: React.ClipboardEventHandler<HTMLInputElement>;
  onCopy: React.ClipboardEventHandler<HTMLInputElement>;
  onCut: React.ClipboardEventHandler<HTMLInputElement>;
}
export const NumberInputRenderProps: React.FunctionComponent<
  RenderPropChildren<NumberInputChildrenProps> & NumberInputProps
> = ({ children, ...props }) => {
  const [frozenValue, setFrozenValue] = useState<string>();
  return (
    <DecimalKey
      decimal={frozenValue || props.value || ''}
      locale={props.locale}
      autofocus={props.autofocus}
      withAccelerators={props.withAccelerators}
      allowNegative={props.allowNegative}
      integerOnly={props.integerOnly}
    >
      {(decimalKey) => (
        <NumberInputHandler
          value={{
            ...getNumberInputHandlerValueFromDecimalKey(decimalKey),
            ...getNumberInputHandlerValueFromProps(props),
            frozenValue,
            setFrozenValue,
          }}
        >
          {(events) =>
            children({
              value: decimalKey.valueLocale,
              onChange: noop,
              ...events,
            })
          }
        </NumberInputHandler>
      )}
    </DecimalKey>
  );
};

/**
 * handlers
 */
const valueFromDecimalKey = ['onKeyDown', 'onPaste', 'onCopy', 'onCut', 'reset', 'currentValue'] as const;
type ScopedHandlersValueFromDecimalkey = ObjectPick<DecimalKeyChildrenProps, (typeof valueFromDecimalKey)[number]>;
export const getNumberInputHandlerValueFromDecimalKey = pick(...valueFromDecimalKey);
const valueFromProp = ['onFocus', 'value', 'onDirty', 'onSubmit', 'onBlur', 'onBlurPristine'] as const;
type ScopedHandlersValueFromProps = ObjectPick<NumberInputProps, (typeof valueFromProp)[number]>;
export const getNumberInputHandlerValueFromProps = pick(...valueFromProp);
type ScopedHandlersValue = ScopedHandlersValueFromDecimalkey &
  ScopedHandlersValueFromProps & {
    frozenValue: string | undefined;
    setFrozenValue: (value?: string) => void;
  };

export const NumberInputHandler = createEventHandler<
  ScopedHandlersValue,
  HTMLInputElement,
  'onFocus' | 'onKeyDown' | 'onBlur' | 'onPaste' | 'onCopy' | 'onCut'
>({
  onCut:
    ({ onCut, onDirty = noop }) =>
    (event) => {
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart === null || selectionEnd === null) {
        return;
      }
      const setSelectionRange = eventSetSelectionRange(event);
      const clip = onCut(selectionStart, selectionEnd, (position, dirty) => {
        setSelectionRange(position);
        onDirty(dirty);
      });

      event.clipboardData.setData('text', clip);
      event.preventDefault();
    },
  onCopy:
    ({ onCopy }) =>
    (event) => {
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart === null || selectionEnd === null) {
        return;
      }
      const clip = onCopy(selectionStart, selectionEnd);
      event.clipboardData.setData('text', clip);
      event.preventDefault();
    },
  onPaste:
    ({ onPaste, onDirty = noop }) =>
    (event) => {
      const clipboardData = event.clipboardData;
      if (clipboardData) {
        const clipboard = clipboardData
          .getData('text')
          .trim()
          .split(/[\r\n\t]+/, 1)[0]
          .trim()
          .split('');
        const { selectionStart, selectionEnd } = event.currentTarget;
        if (selectionStart !== null && selectionEnd !== null) {
          const setSelectionRange = eventSetSelectionRange(event);
          onPaste(clipboard, selectionStart, selectionEnd, (position, dirty) => {
            setSelectionRange(position);
            onDirty(dirty);
          });
        }
      }
    },
  onFocus:
    ({ onFocus = noop, value, setFrozenValue }) =>
    (event) => {
      event.currentTarget.select();
      setFrozenValue(value);
      onFocus(event);
    },
  onKeyDown:
    ({ value, onDirty = noop, reset, onKeyDown }) =>
    (event) => {
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          return;
        case 'Escape':
          reset((c) => {
            onDirty(c !== value);
          });
          return;
      }
      const { selectionStart, selectionEnd } = event.currentTarget;
      if (selectionStart !== null && selectionEnd !== null) {
        const setSelectionRange = eventSetSelectionRange(event);
        onKeyDown(event.key, selectionStart, selectionEnd, (position, dirty) => {
          setSelectionRange(position);
          onDirty(dirty);
        });
      }
    },
  onBlur:
    ({ currentValue, onSubmit, onBlurPristine = noop, onBlur = noop, setFrozenValue, frozenValue }) =>
    () => {
      if (frozenValue !== currentValue) {
        onSubmit(currentValue);
      } else {
        onBlurPristine();
      }
      onBlur();
      setFrozenValue();
    },
});

function eventSetSelectionRange(event: React.SyntheticEvent<HTMLInputElement>) {
  event.persist();
  const { currentTarget } = event;
  const setSelectionRange = currentTarget.setSelectionRange.bind(currentTarget);
  return (position: number | null) => {
    if (position === null) {
      return;
    }
    event.preventDefault();
    setSelectionRange(position, position);
  };
}
