import type { IFormData, SharedKey, ValueKey } from 'state/share/form';
import type {
  AlgoOrderType,
  ClippingMode,
  FixingMarginType,
  FixingPriceType,
  LimitOrderType,
  LiquidityPool,
  OrderType,
  Speed,
} from 'state/fxOrders/fxOrdersModel';
import { isAlgoOrder } from 'state/fxOrders/fxOrdersModel';
import { makeValuesPredicate } from '@sgme/fp';
import type { Side } from 'state/share/productModel/litterals';

export type OrderErrorCode =
  | 'order.validationFailed'
  | 'order.serverError'
  | 'order.timeout'
  | 'order.unexpected'
  | 'order.internal_error';

export type BlotterEntry = TradeBlotterEntry | OrderBlotterEntry;

export interface TradeBlotterEntry extends BlotterEntryCommon, BlotterTradeProperties {
  instrument: BlotterTradeInstrument;
  strategyReference: string | null;
  childrenIds?: readonly string[];
  electronicReference: string | null;
  values: BlotterTradeValues;
}

export type OrderResultMode = 'Display' | 'Edit' | 'Save' | 'Cancel';
export type BlotterClickAction = 'Cancel' | 'Pause' | 'Resume' | 'Fill' | 'Edit' | 'Display';
export type ModeStatus = 'Idle' | 'Pending' | 'Success' | 'StillPending' | 'Timeouted' | 'Error';
export interface OrderCommonBlotterEntry extends BlotterEntryCommon {
  instrument: BlotterOrderInstrument;
  mode: OrderResultMode;
  modeStatus: ModeStatus;
  errorCode?: OrderErrorCode;
  isReadyToSubmit?: boolean;
}

export type OrderBlotterEntry = OrderBlotterLimitEntry | OrderBlotterAlgoEntry;

export type OrderBlotterLimitEntry = OrderCommonBlotterEntry & BlotterOrderLimitProperties;
export type OrderBlotterAlgoEntry = OrderCommonBlotterEntry & BlotterOrderAlgoPropertiesWithMetaData;

interface BlotterEntryCommon {
  id: string;
  receivedDate: number;
}

export type BlotterTradeInstrument = 'Cash' | 'Option' | 'Accumulator';
export type BlotterOrderInstrument = 'Order';
export type BlotterInstrument = BlotterTradeInstrument | BlotterOrderInstrument;

export const cashTradeTypes = ['Spot', 'Outright', 'Ndf'] as const;
export const isCashTrade = makeValuesPredicate(cashTradeTypes);
export const swapTradeTypes = ['Swap', 'Nds'] as const;
export const isSwapTrade = makeValuesPredicate(swapTradeTypes);
export const optionTradeTypes = ['Vanilla', 'Strategy', 'Straddle', 'Strangle', 'RiskReversal'] as const;

export const accumulatorTypes = ['TargetAccumulator', 'ForwardAccumulator'] as const;

export const isOptionTrade = makeValuesPredicate(optionTradeTypes);
export const tradeTypes = [...cashTradeTypes, ...swapTradeTypes, ...optionTradeTypes, ...accumulatorTypes] as const;
export type TradeType = (typeof tradeTypes)[number];

export interface BlotterTradeValues {
  status: BlotterTradeStatus | null;
  product: TradeType;
  currencyPair: string | null;
  way: Side | null;
  dealtAmount: number | null;
  dealtCurrency: string | null;
  farDealtAmount: number | null;
  contraAmount: number | null;
  contraCurrency: string | null;
  farContraAmount: number | null;
  date: string | null;
  spot: number | null;
  points: number | null;
  venue: string | null;
  account: string | null;
  accountLongName: string | null;
  salesFullName: string | null;
  premiumCurrency: string | null;
  premiumSide: 'Pay' | 'Receive' | null;
  strike: number | null;
  expiry: string | null;
  premium: number | null;
  farDate: string | null;
  nearDate: string | null;
  farRate: number | null;
  nearRate: number | null;
  spotDate: string | null;
  portfolio: string | null;
  updateTime: string;
  electronicAccount: string | null;
  optionType: BlotterTradeOptionType | null;
  deliveryDate: string | null;
  premiumPaymentDate: string | null;
  deliveryType: string | null;
  cutOffPlace: string | null;
  counterpartyBdrId: number | null;
}

export type BlotterTradeInputs = {}

export type BlotterTradeProperties = IFormData<BlotterTradeValues, BlotterTradeInputs>;

export type BlotterTradeValuePropertyKey = ValueKey<BlotterTradeValues, BlotterTradeInputs>;

export type BlotterTradeSharedPropertyKey = SharedKey<BlotterTradeValues, BlotterTradeInputs>;

// values and inputs below which are not nullable below have been changed to match IFxOrderValues type properties
// as they both represent the same state of an order for which oms validation business logic has been mutualized
// forcing us to harmonize the models. Ideally it should be identical, this is just the first step.
export interface BlotterOrderLimitValues {
  product: LimitOrderType;
  limitPrice: number | null;
  customerPrice: number | null;
  fixingDateUtc?: string | null;
  fixingPriceType?: FixingPriceType | null;
  fixingMarginType?: FixingMarginType | null;
  fixingBenchmark?: string | null;
  fixingPlace?: string | null;
  fixingTime?: string | null;
  marginInBps?: number | null;
  margin?: number | null;
  price?: number | null;
  // TODO ABO, SGEFX-5010: check if we want to put that in notificationData when we get activeOrderList
  //  and how to hydrate this values in blotter order model
  fixingTypes?: string[] | null;
  isCcyForcedBidAsk?: boolean | null;
  isStandardGroup?: boolean | null;
  fixingTimesOfSelectedType?: string[] | null;
  sourceNameOfSelectedType?: string[] | null;
  canModifyMarginInBps?: boolean;
}

export interface BlotterOrderLimitInputs {
  product: LimitOrderType;
  limitPrice: string | null;
  customerPrice: string | null;
  fixingPriceType: FixingPriceType | null;
  fixingMarginType?: FixingMarginType | null;
  marginInBps: number | null;
  margin: number | null;
  // TODO ABO, SGEFX-5010: how to hydrate this values in blotter order model
  fixingBenchmark?: string | null;
  fixingTime?: string | null;
  fixingPlace?: string | null;
}

export interface BlotterOrderAlgoValues {
  product: AlgoOrderType;
  limitPrice?: string | number | null;
  startDate?: string | null;
  startTime?: string | null;
  endDate?: string | null;
  endTime?: string | null;
  maturityDate?: string | null;
  liquidityPool?: readonly LiquidityPool[];
  clippingMode?: ClippingMode;
  clipSize?: number | null;
  randomize?: boolean;
  swapPoints?: number | null;
  forwardPrice?: number | null;
  averagePrice?: number | null;
  noWorseThan?: number | null;
  spreadCapture?: boolean;
  alphaSeeker?: boolean;
  speed: Speed;
}

export interface BlotterOrderAlgoInputs {
  product: AlgoOrderType;
  startDate: string | null;
  startTime: string | null;
  endDate: string | null;
  endTime: string | null;
  liquidityPool: readonly LiquidityPool[] | null;
  clippingMode: ClippingMode;
  clipSize: string | null;
  randomize: boolean;
  noWorseThan?: string | null;
  spreadCapture: boolean;
  alphaSeeker: boolean;
  speed: Speed;
}

export interface BlotterOrderCommonValues {
  product: OrderType;
  status: BlotterOrderStatus | null;
  account: string;
  currencyPair: string;
  way: Side | null;
  notional: number | null;
  notionalCurrency: string | null;
  executionPrice: number | null;
  executedNotional: number | null;
  remainingAmount: number | null;
  rejectReason: string | null;
  updateTime: string;
  isGtc: boolean | null;
  expiryDay: string | null;
  expiryTime: string | null;
  isReadyToSubmit?: boolean;
}

export interface BlotterOrderCommonInputs {
  product: OrderType;
  notional: string | null;
  isGtc: boolean | null;
}

export type BlotterAllOrderLimitValues = BlotterOrderCommonValues & BlotterOrderLimitValues;
export type BlotterAllOrderAlgoValues = BlotterOrderCommonValues & BlotterOrderAlgoValues;

export type BlotterOrderValues = BlotterOrderCommonValues & (BlotterOrderLimitValues | BlotterOrderAlgoValues);

export type BlotterOrderInputs = BlotterOrderCommonInputs & (BlotterOrderLimitInputs | BlotterOrderAlgoInputs);
export type BlotterAllOrderLimitInputs = BlotterOrderCommonInputs & BlotterOrderLimitInputs;
export type BlotterAllOrderAlgoInputs = BlotterOrderCommonInputs & BlotterOrderAlgoInputs;

export type BlotterOrderAllValuesKeys =
  | keyof BlotterOrderCommonValues
  | keyof BlotterOrderLimitValues
  | keyof BlotterOrderAlgoValues;

export type BlotterOrderAllInputsKeys =
  | keyof BlotterOrderCommonInputs
  | keyof BlotterOrderLimitInputs
  | keyof BlotterOrderAlgoInputs;

export type BlotterOrderLimitProperties = IFormData<
  BlotterOrderCommonValues & BlotterOrderLimitValues,
  BlotterOrderCommonInputs & BlotterOrderLimitInputs
>;

export interface BlotterOrderAlgoMetadata {
  togglePausePending: boolean;
  fillNowPending: boolean;
  currentEspStreamId: string;
}

export type BlotterOrderAlgoProperties = IFormData<
  BlotterOrderCommonValues & BlotterOrderAlgoValues,
  BlotterOrderCommonInputs & BlotterOrderAlgoInputs
>;

export type BlotterOrderAlgoPropertiesWithMetaData = BlotterOrderAlgoProperties & BlotterOrderAlgoMetadata;

export type BlotterTradeStatus =
  | 'Block Processed'
  | 'Booking Pre Validated'
  | 'BookingToBeChecked'
  | 'BookingValidated'
  | 'Cancelled'
  | 'Cleared'
  | 'ClearingPending'
  | 'Destroyed'
  | 'Electronic CTP unmapped'
  | 'Expired'
  | 'FO Destroyed'
  | 'FO Pre-Validated'
  | 'FO Validated'
  | 'Fxo MO Pending'
  | 'Fxo Trader Pending'
  | 'Hedge Pending'
  | 'Initial'
  | 'Missed Deal'
  | 'MO Validation Pending'
  | 'Pending Full Termination'
  | 'Pending Liquidity'
  | 'Pending Migration'
  | 'Pending RFQ'
  | 'RFQ Pending'
  | 'RFQ Sales Cancelled'
  | 'RFQ Validated'
  | 'Sales Cancelled'
  | 'Sales Pending'
  | 'Sales RFQ'
  | 'Simulation'
  | 'SimulDestroyed'
  | 'Trader Cancelled'
  | 'Trader Pending'
  | 'Trader RFQ'
  | 'Waiting Sales'
  | 'Liquidity Allocated'
  | 'Netted'
  | 'Conversion Executed';

export type BlotterTradeOptionType =
  | 'CE'
  | 'Call'
  | 'PE'
  | 'Put'
  | 'CECSD1'
  | 'PECSD1'
  | 'PEFWD'
  | 'CEFWD'
  | 'PECSD2'
  | 'CECSD2'
  | 'STRAT'
  | 'STRATNET'
  | 'FXOTargetAccumulator';

export const blotterOrderStatuses = [
  'Submitted',
  'Watched',
  'Executing',
  'Done',
  'Finished',
  'Rejected',
  'InError',
  'Cancelled',
  'Partially done',
  'Expired',
  'Paused',
  'Updating',
  'Cancelling',
  'Running',
  'New',
] as const;

export type BlotterOrderStatus = (typeof blotterOrderStatuses)[number];

export const blotterOrderQuickActions = ['View', 'Edit', 'Cancel', 'Pause', 'Resume', 'Fill'] as const;
export type BlotterOrderQuickAction = (typeof blotterOrderQuickActions)[number];

export function isOrderEditable(status: BlotterOrderStatus | null, orderId: string): boolean {
  if (isSgmeOrder(orderId) === false) {
    return false;
  }
  switch (status) {
    case 'Watched':
    case 'Executing':
    case 'Paused':
    case 'Running':
    case 'New':
      return true;
    default:
      return false;
  }
}

export function isSgmeOrder(orderId: string): boolean {
  // for a lack of a better check for now
  return orderId.toUpperCase().startsWith('SGME');
}

const quickActionsRules: Record<
  BlotterOrderQuickAction,
  (status: BlotterOrderStatus, product: OrderType, orderId: string) => boolean
> = {
  View: () => true,
  Pause: (status, product) => status !== 'Paused' && isAlgoOrder(product),
  Resume: (status, product) => status === 'Paused' && isAlgoOrder(product),
  Fill: (status, product, orderId) => isOrderEditable(status, orderId) && isAlgoOrder(product),
  Edit: () => true,
  Cancel: () => true,
};

export function getBlotterOrderQuickActions(
  status: BlotterOrderStatus,
  product: OrderType,
  orderId: string,
): BlotterOrderQuickAction[] {
  if (!isOrderEditable(status, orderId)) {
    return ['View'];
  }
  return blotterOrderQuickActions.filter(quickAction => quickActionsRules[quickAction](status, product, orderId));
}

export function isOrderBlotterEntry(entry: BlotterEntry): entry is OrderBlotterEntry {
  return entry.instrument === 'Order';
}

export function isTradeBlotterEntry(entry: BlotterEntry): entry is TradeBlotterEntry {
  return entry.instrument === 'Cash' || entry.instrument === 'Option' || entry.instrument === 'Accumulator';
}
