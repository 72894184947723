import { combineReducers } from 'redux';
import { blotterReducer } from 'state/blotter/reducer';
import { clientWorkspaceReducer } from 'state/clientWorkspace';
import { fxAccumulatorsReducer } from 'state/fxAccumulators/reducer';
import { fxBulksReducer } from 'state/fxBulks/reducer';
import { fxCashsReducer } from 'state/fxCashs/reducer';
import { fxOptionsReducer } from 'state/fxOptions/reducers';
import { fxSwapsReducer } from 'state/fxSwaps/reducer';
import { globalErrorReducer } from 'state/globalError';
import { gridCollectionReducer } from 'state/gridLayout';
import type { AppState } from 'state/model';
import { referenceDataReducer } from 'state/referenceData/referenceDataReducer';
import { sessionReducer } from 'state/session/sessionReducer';
import { streamingConnectionReducer } from 'state/streamingConnection';
import { userPreferencesReducer } from 'state/userPreferences';
import { rootReducerTimer } from 'utils/Timer/Timers';
import { espStreamsReducer } from './esp/espStreamsReducer';
import { executionsReducer } from './executions/executionsReducer';
import { freeLayoutCollectionReducer } from './freeLayout/reducer';
import { fxAmericanForwardReducer } from './fxAmericanForward/reducers';
import { fxOrderBulksSlice } from './fxOrderBulks/fxOrderBulkSlice';
import { fxOrdersReducer } from './fxOrders/reducers';
import { fxSmartRfsReducer } from './fxSmartRfs/fxSmartRfsReducer';
import { mifid2PanelReducer } from './mifidPanel/reducers';
import { toastsReducer } from './toasts/toastsReducer';

export const rootReducer = rootReducerTimer(
  combineReducers<AppState>({
    clientWorkspace: clientWorkspaceReducer,
    fxOptions: fxOptionsReducer,
    fxAmericanForwards: fxAmericanForwardReducer,
    fxCashs: fxCashsReducer,
    fxSwaps: fxSwapsReducer,
    fxBulks: fxBulksReducer,
    fxOrderBulks: fxOrderBulksSlice.reducer,
    fxAccumulators: fxAccumulatorsReducer,
    fxOrders: fxOrdersReducer,
    fxSmartRfs: fxSmartRfsReducer,
    espStreams: espStreamsReducer,
    executions: executionsReducer,
    referenceData: referenceDataReducer,
    globalError: globalErrorReducer,
    streamingConnection: streamingConnectionReducer,
    gridLayout: gridCollectionReducer,
    freeLayout: freeLayoutCollectionReducer,
    session: sessionReducer,
    userPreferences: userPreferencesReducer,
    blotter: blotterReducer,
    mifid2Panel: mifid2PanelReducer,
    toasts: toastsReducer,

    // impossible to register a RTK query middleware
    // [fxOrderBulkApi.reducerPath]: fxOrderBulkApi.reducer,
  }),
);
