import type { ITradeCaptureMetaData } from 'api/tradeCapture/tradeCaptureModel';
import type { OptionSavedTile } from 'api/workspaceService/model';
import type { TradeType } from 'state/blotter/blotterEntryModel';
import type { RestoredTiles } from 'state/clientWorkspace';
import type { IFxExecutionData } from 'state/executions/executionsStateModel';
import type { IStreamError } from 'state/globalError/globalErrorModel';
import type { TileInstrument } from 'state/referenceData';
import type { IPatch } from 'state/share/patchModels';
import type { CurrencyChoice, HedgeType, Side, Way } from 'state/share/productModel/litterals';
import type { TradeCaptureError } from 'state/tile/fxTileModel';
import type { StrictExclude } from 'utils/object';
import type { PriceType } from '../model/common';
import type { FxOptionHedgesPatchedValuesRecords, IFxHedgeInputs } from '../model/optionHedges';
import type { DisplayPriceType, IFxOptionInputs, IFxOptionValues } from '../model/optionProduct';
import type {
  FxOptionLegInputs,
  FxOptionLegsPatchedValuesRecords,
  IFxOptionTypedStrategyLegInputs,
  IFxVanillaLegInputs,
  IFxVanillaLegValues,
  LegType,
  OptionType,
  SettlementType,
} from '../model/optionsLegs';
import type { IQuote } from '../model/optionsStreams';

export type FxOptionPatchedValues = IPatch<IFxOptionValues> &
  ITradeCaptureMetaData & {
    displayPriceType?: DisplayPriceType;
    isProductObsolete: boolean;
    legIds: string[];
  };

export type FxOptionPatchedValuesRecords = Record<string, FxOptionPatchedValues>;

export interface FxOptionPatchWithTradeCaptureMetaData {
  option: FxOptionPatchedValuesRecords;
  legs: FxOptionLegsPatchedValuesRecords;
  hedges: FxOptionHedgesPatchedValuesRecords;
}

export interface IFxOptionExecutionData extends IFxExecutionData {
  instrument: 'Option';
  strategyType: StrictExclude<LegType, 'FxOptionMultileg'>;
  way: Way;
  quote: Readonly<IQuote>;
  displayPriceType: DisplayPriceType;
  legs: Record<string, IFxVanillaLegValues>;
}

export type IFxOptionTypedStrategyLegInputsWithSubLegs = IFxOptionTypedStrategyLegInputs & {
  subLegs: Record<SubLegId, Partial<IFxVanillaLegInputs>>; // TODO: remove sublegs ?
};

export type SubLegId = string;

export interface OptionTileOpenFromBlotter {
  instrument: Extract<TileInstrument, 'Option'>;
  productName: 'FxOption';
  strategyType: TradeType;
  hedgeType: HedgeType;
  optionType: OptionType | undefined;
  currencyPair: string | null;
  priceType: PriceType;
  premiumCurrency: CurrencyChoice | undefined;
  side: Side;
  expiryDate: string | null | undefined;
  strike: string | null | undefined;
  notionalAmount: string | null | undefined;
  notionalCurrency: CurrencyChoice | undefined;
  deliveryDate: string | null | undefined;
  settlementType: SettlementType | null | undefined;
  settlementPlace: string | null | undefined;
  premiumDate: string | null | undefined;
  legs: LegOpenFromBlotter[];
}

export interface LegOpenFromBlotter {
  side: Side;
  optionType: OptionType | undefined;
  expiryDate: string | null | undefined;
  strike: string | null | undefined;
  notionalAmount: string | null | undefined;
  notionalCurrency: CurrencyChoice | undefined;
  deliveryDate: string | null | undefined;
  settlementType: SettlementType | null | undefined;
  settlementPlace: string | null | undefined;
  premiumDate: string | null | undefined;
}

export interface OptionTileReset {
  type: 'OPTION_TILE_RESET';
  quoteId: string;
  currencyPair?: string;
}

export function optionTileReset(quoteId: string, currencyPair?: string): OptionTileReset {
  return {
    type: 'OPTION_TILE_RESET',
    quoteId,
    currencyPair,
  };
}

export interface OptionTileRestoreEpic {
  type: 'OPTION_TILE_RESTORE_EPIC';
  tiles: RestoredTiles<OptionSavedTile>;
}

export function optionTileRestoreEpic(tiles: RestoredTiles<OptionSavedTile>): OptionTileRestoreEpic {
  return {
    type: 'OPTION_TILE_RESTORE_EPIC',
    tiles,
  };
}

export interface OptionTileOpenFromBlotterEpic {
  type: 'OPTION_TILE_OPEN_FROM_BLOTTER_EPIC';
  tileId: string;
  tile: OptionTileOpenFromBlotter;
}

export function optionTileOpenFromBlotterEpic(
  tileId: string,
  tile: OptionTileOpenFromBlotter,
): OptionTileOpenFromBlotterEpic {
  return {
    type: 'OPTION_TILE_OPEN_FROM_BLOTTER_EPIC',
    tileId,
    tile,
  };
}

export interface OptionExecutionRequested {
  type: 'OPTION_EXECUTION_REQUESTED';
  optionId: string;
  streamId: string;
  way: Way;
}

export function optionExecutionRequested(optionId: string, streamId: string, way: Way): OptionExecutionRequested {
  return {
    type: 'OPTION_EXECUTION_REQUESTED',
    optionId,
    streamId,
    way,
  };
}

export interface OptionExecutionFailed {
  type: 'OPTION_EXECUTION_FAILED';
  streamError: IStreamError;
  executionId: string;
}

export function optionExecutionFailed(executionId: string, streamError: IStreamError): OptionExecutionFailed {
  return {
    type: 'OPTION_EXECUTION_FAILED',
    streamError,
    executionId,
  };
}

export function optionExecutionSentSucceeded(executionId: string): OptionExecutionSentSucceeded {
  return {
    type: 'OPTION_EXECUTION_SENT_SUCCEEDED',
    executionId,
  };
}

export interface OptionExecutionSentSucceeded {
  type: 'OPTION_EXECUTION_SENT_SUCCEEDED';
  executionId: string;
}

export interface OptionExecutionSent {
  type: 'OPTION_EXECUTION_SENT';
  optionId: string;
  quoteId: string;
  executionId: string;
  execution: IFxOptionExecutionData;
  instrument: 'Option';
}

export function optionExecutionSent(
  optionId: string,
  quoteId: string,
  executionId: string,
  execution: IFxOptionExecutionData,
): OptionExecutionSent {
  return {
    type: 'OPTION_EXECUTION_SENT',
    optionId,
    quoteId,
    executionId,
    execution,
    instrument: 'Option',
  };
}

export interface OptionStrategyTypeChanged {
  type: 'OPTION_STRATEGY_TYPE_CHANGED';
  optionId: string;
  strategyType: Exclude<LegType, 'FxOptionMultileg'>;
  optionType?: OptionType;
}

export function optionStrategyTypeChanged(
  optionId: string,
  strategyType: Exclude<LegType, 'FxOptionMultileg'>,
  optionType?: OptionType,
): OptionStrategyTypeChanged {
  return { type: 'OPTION_STRATEGY_TYPE_CHANGED', optionId, strategyType, optionType };
}

export interface OptionPropertyChanged {
  type: 'OPTION_PROPERTY_CHANGED';
  optionId: string;
  patch: Partial<IFxOptionInputs> & {
    legs?: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputsWithSubLegs>; // TODO: remove sublegs ?
    hedges?: Record<string, Partial<IFxHedgeInputs>>;
  };
}

export function optionPropertyChanged(
  optionId: string,
  patch: Partial<IFxOptionInputs> & {
    legs?: Record<string, Partial<FxOptionLegInputs> | IFxOptionTypedStrategyLegInputsWithSubLegs>; // TODO: remove sublegs ?
    hedges?: Record<string, Partial<IFxHedgeInputs>>;
  },
): OptionPropertyChanged {
  return {
    type: 'OPTION_PROPERTY_CHANGED',
    optionId,
    patch,
  };
}

export interface OptionPropertyRemoveError {
  type: 'OPTION_PROPERTY_REMOVE_ERROR';
  optionId: string;
  keys: ReadonlyArray<keyof IFxOptionValues>;
}

export function optionPropertyRemoveError(
  optionId: string,
  keys: ReadonlyArray<keyof IFxOptionValues>,
): OptionPropertyRemoveError {
  return {
    type: 'OPTION_PROPERTY_REMOVE_ERROR',
    optionId,
    keys,
  };
}

export interface OptionNotionalCurrencyChanged {
  type: 'OPTION_NOTIONAL_CURRENCY_CHANGED';
  optionId: string;
  legsId: readonly string[];
  notionalCurrency: CurrencyChoice;
}

export function optionNotionalCurrencyChanged(
  optionId: string,
  legsId: readonly string[],
  notionalCurrency: CurrencyChoice,
): OptionNotionalCurrencyChanged {
  return {
    type: 'OPTION_NOTIONAL_CURRENCY_CHANGED',
    optionId,
    legsId,
    notionalCurrency,
  };
}

export interface OptionCopyPasted {
  type: 'OPTION_COPY_PASTED';
}

export function optionCopyPasted(): OptionCopyPasted {
  return {
    type: 'OPTION_COPY_PASTED',
  };
}

export interface OptionPropertiesRequested {
  type: 'OPTION_PROPERTIES_REQUESTED';
  optionId: string;
  sessionId: string;
}

export function optionPropertiesRequested(optionId: string, sessionId: string): OptionPropertiesRequested {
  return {
    type: 'OPTION_PROPERTIES_REQUESTED',
    optionId,
    sessionId,
  };
}

export interface OptionPropertiesReceived {
  type: 'OPTION_PROPERTIES_RECEIVED';
  quoteId: string;
  legsPatch: FxOptionLegsPatchedValuesRecords;
  hedgesPatch: FxOptionHedgesPatchedValuesRecords;
  isReadyToPrice: boolean | null;
  isPriceObsolete: boolean;
  isProductObsolete: boolean;
  optionPatch: FxOptionPatchedValues;
  displayPriceType?: DisplayPriceType;
  idVersion: number;
}

export function optionPropertiesReceived(
  quoteId: string,
  legsPatch: FxOptionLegsPatchedValuesRecords,
  hedgesPatch: FxOptionHedgesPatchedValuesRecords,
  optionPatch: FxOptionPatchedValues,
  idVersion: number,
  isReadyToPrice: boolean | null,
  isPriceObsolete = false,
  isProductObsolete = false,
  displayPriceType?: DisplayPriceType,
): OptionPropertiesReceived {
  return {
    type: 'OPTION_PROPERTIES_RECEIVED',
    quoteId,
    legsPatch,
    hedgesPatch,
    optionPatch,
    isReadyToPrice,
    isPriceObsolete,
    isProductObsolete,
    displayPriceType,
    idVersion,
  };
}

export interface OptionPropertiesRequestFailed {
  type: 'OPTION_PROPERTIES_REQUEST_FAILED';
  quoteId: string;
  error: TradeCaptureError;
}

export function optionPropertiesRequestFailed(
  quoteId: string,
  error: TradeCaptureError,
): OptionPropertiesRequestFailed {
  return {
    type: 'OPTION_PROPERTIES_REQUEST_FAILED',
    quoteId,
    error,
  };
}

export interface OptionDisplayPriceTypeChanged {
  type: 'OPTION_DISPLAY_PRICE_TYPE_CHANGED';
  optionId: string;
  displayPriceType: DisplayPriceType;
}

export function optionDisplayPriceTypeChanged(
  optionId: string,
  displayPriceType: DisplayPriceType,
): OptionDisplayPriceTypeChanged {
  return {
    type: 'OPTION_DISPLAY_PRICE_TYPE_CHANGED',
    optionId,
    displayPriceType,
  };
}

export interface OptionExecutionReceived {
  type: 'OPTION_EXECUTION_RECEIVED';
  executionId: string;
}

export function optionExecutionReceived(executionId: string): OptionExecutionReceived {
  return {
    type: 'OPTION_EXECUTION_RECEIVED',
    executionId,
  };
}

export interface OptionFillReportReceived {
  type: 'OPTION_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function optionFillReportReceived(executionId: string, tradeIds: readonly string[]): OptionFillReportReceived {
  return {
    type: 'OPTION_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}

export interface OptionToggleStrategy {
  type: 'OPTION_TOGGLE_STRATEGY';
  optionId: string;
  isStrategy: boolean;
}

export function optionToggleStrategy(optionId: string, isStrategy: boolean): OptionToggleStrategy {
  return {
    type: 'OPTION_TOGGLE_STRATEGY',
    optionId,
    isStrategy,
  };
}

export interface OptionToggleGroup {
  type: 'OPTION_TOGGLE_GROUP';
  quoteId: string;
  isGroup: boolean;
}

export function optionToggleGroup(quoteId: string, isGroup: boolean): OptionToggleGroup {
  return {
    type: 'OPTION_TOGGLE_GROUP',
    quoteId,
    isGroup,
  };
}

export interface OptionToggleExpand {
  type: 'OPTION_TOGGLE_EXPAND';
  quoteId: string;
  expiry: string;
}

export function optionToggleExpand(quoteId: string, expiry: string): OptionToggleExpand {
  return {
    type: 'OPTION_TOGGLE_EXPAND',
    quoteId,
    expiry,
  };
}

export interface OptionRemoveGroup {
  type: 'OPTION_REMOVE_GROUP';
  quoteId: string;
  expiry: string;
}

export function optionRemoveGroup(quoteId: string, expiry: string): OptionRemoveGroup {
  return {
    type: 'OPTION_REMOVE_GROUP',
    quoteId,
    expiry,
  };
}

export interface OptionMassExpand {
  type: 'OPTION_MASS_EXPAND';
  quoteId: string;
  expiries: readonly string[];
}

export function optionMassExpand(quoteId: string, expiries: readonly string[]): OptionMassExpand {
  return {
    type: 'OPTION_MASS_EXPAND',
    quoteId,
    expiries,
  };
}

type Orientation = 'horizontal' | 'vertical';

export interface OptionOrientationToggled {
  type: 'OPTION_ORIENTATION_TOGGLED';
  tileId: string;
  orientation: Orientation;
}

export function optionOrientationToggled(tileId: string, orientation: Orientation): OptionOrientationToggled {
  return { type: 'OPTION_ORIENTATION_TOGGLED', tileId, orientation };
}
