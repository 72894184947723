import { updateKey, removeKey, addKey, setKeyIfExisty } from 'utils/stateMap';

import type { Action } from '../../actions';
import type { Reducer } from 'redux';
import { immutableReferenceUpdate } from 'utils/immutableReferenceUpdate';
import type {
  FxAmericanForwardStreamMap,
  FxAmericanForwardStreamState,
} from '../model/fxAmericanForwardStreamModel';

// STREAMS

export const fxAmericanForwardStreamsReducer: Reducer<FxAmericanForwardStreamMap> = (
  state: FxAmericanForwardStreamMap = {},
  action: Action,
): FxAmericanForwardStreamMap => {
  switch (action.type) {
    case 'AMERICAN_FORWARD_STREAM_STARTED':
      return addKey<FxAmericanForwardStreamState>(state, action.streamId, {
        version: 0,
        status: 'AWAITING',
        forceTradeable: action.forceTradeable,
        traderId: null,
      });

    case 'AMERICAN_FORWARD_TRADER_NOTIFICATION_RECEIVED':
      return updateKey<FxAmericanForwardStreamState>(state, action.streamId, () => ({
        status: 'AWAITING',
        traderId: action.traderId,
      }));

    case 'AMERICAN_FORWARD_QUOTE_RECEIVED':
      return setKeyIfExisty<FxAmericanForwardStreamState>(state, action.streamId, (stream) => {
        if (stream.status === 'INVALID') {
          return stream;
        }

        const traderId = stream.status === 'AWAITING' || stream.status === 'PRICING' ? stream.traderId : null;

        const result: FxAmericanForwardStreamState = {
          status: 'PRICING',
          quote: stream.status === 'PRICING' ? immutableReferenceUpdate(stream.quote, action.quote) : action.quote,
          initialRfsWindow: stream.status === 'PRICING' ? stream.initialRfsWindow : action.quote.rfsWindow,
          tiering: action.tiering,
          skippedLimitCheck: action.skippedLimitCheck,
          forceTradeable: stream.forceTradeable,
          traderId,
          quoteContribution: action.quoteContribution,
          version: stream.version,
        };

        return result;
      });

    case 'AMERICAN_FORWARD_STREAM_CANCELED':
    case 'AMERICAN_FORWARD_STREAM_TERMINATED':
      if (!action.shouldKeepAsExpired) {
        return removeKey(state, action.streamId);
      }
      return updateKey<FxAmericanForwardStreamState>(state, action.streamId, () => ({
        status: 'EXPIRED',
      }));

    case 'AMERICAN_FORWARD_STREAM_FAILED':
    case 'AMERICAN_FORWARD_STREAM_LAST_REMOVED':
      return removeKey(state, action.streamId);

    case 'AMERICAN_FORWARD_EXECUTION_SENT':
      return updateKey<FxAmericanForwardStreamState>(state, action.executionId, () => ({
        status: 'EXPIRED',
      }));

    case 'AMERICAN_FORWARD_STREAM_INVALIDATED':
      return setKeyIfExisty<FxAmericanForwardStreamState>(state, action.streamId, (stream) => ({
        status: 'INVALID',
        version: stream.version,
      }));

    default:
      return state;
  }
};
