import { assertIsDefined, assertUnreachable, isEmpty, isNotDefined } from '@sgme/fp';
import { SPOT_TENOR } from 'components/share/tenors';
import { logger } from 'logging/logger';
/* eslint-disable no-case-declarations */
import type { Thunk } from 'state';
import { TileExecutionStatus } from 'state/executions/executionsStateModel';
import type { AppState } from 'state/model';
import {
  type Instrument,
  type InstrumentChoice,
  type SwitchableInstrument,
  isSwitchableInstrument,
} from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import { getInputs } from 'state/share/productMappers';
import { getAllHedgesInputs, getAllLegsInputs } from 'state/share/productMappers/optionMappers';
import { assertUnhandled } from 'utils/error';
import { fieldData } from 'utils/fieldSelectors';
import { isMaturityDate } from '../../components/UserPreferences/DefaultMaturityDate';

export function checkCurrencyPairInversion(quoteId: string, currencyPair: string | null): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const allCcyPairs = sl.getAllCcyPairs(state);

    if (currencyPair === null) {
      return;
    }

    const currencyPairExtended = allCcyPairs[currencyPair];

    if (currencyPairExtended?.isInverted) {
      const instrument = sl.getQuoteInstrument(state, quoteId);

      if (instrument === 'SmartRfs') {
        assertUnhandled('should not have smartRfs instrument using currency Related component', instrument);
      }

      dispatch(
        ac.localFieldValidationSet(instrument, quoteId, 'currencyPair', 'warning', 'currency.invertedCurrencyPair'),
      );
    }
  };
}

export function currencyPairChangeThunk(quoteId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const instrument = sl.getQuoteInstrument(state, quoteId);

    if (instrument === 'SmartRfs') {
      assertUnhandled('should not have smartRfs instrument using currency related component', instrument);
    }

    dispatch(ac.localFieldValidationClear(instrument, quoteId, 'currencyPair'));
    dispatch(ac.checkCurrencyPairInversion(quoteId, currencyPair));

    const missingCurrencies = currencyPair.split('/').filter((ccy) => sl.getEspLimit(state, ccy) === undefined);

    if (!isEmpty(missingCurrencies)) {
      dispatch(ac.retrieveEspLimits(missingCurrencies));
    }

    const closedDates = sl.getCurrencyClosedDates(state, currencyPair);

    if (isEmpty(closedDates)) {
      dispatch(ac.retrieveClosedDates([currencyPair]));
    }

    switch (instrument) {
      case 'Cash':
        dispatch(ac.cashCurrencyPairChangeThunk(quoteId, currencyPair));
        break;

      case 'Option': {
        dispatch(ac.optionRfsCancelThunk(quoteId));
        dispatch(ac.espTileStreamUnsubscribeThunk(quoteId));

        const isTileFirstTCRequest = sl.getOptionTradeCaptureIdVersion(state, quoteId) === null;
        const { defaultHedgeType, optionStrategyGroupLegs } = sl.getUserPreferenceData(state);
        const defaultPriceType = sl.getDefaultPriceTypeForOption(state, quoteId);

        if (!isTileFirstTCRequest || defaultPriceType !== 'AMOUNT') {
          dispatch(ac.optionDisplayPriceTypeChanged(quoteId, [1, defaultPriceType]));
        }

        if (isTileFirstTCRequest) {
          if (optionStrategyGroupLegs) {
            dispatch(ac.optionToggleGroup(quoteId, true));
          }

          dispatch(
            ac.optionPropertyChanged(quoteId, {
              currencyPair,
              ...(defaultHedgeType !== 'Live' ? { hedgeType: defaultHedgeType } : {}),
              ...(defaultPriceType !== 'AMOUNT'
                ? { legs: { [`${quoteId}/0/0`]: { premiumCurrency: 1, premiumTypeString: defaultPriceType } } }
                : {}),
            }),
          );
        } else {
          dispatch(ac.optionTileReset(quoteId, currencyPair));
        }
        break;
      }
      case 'Swap':
        dispatch(ac.swapCurrencyPairChangeThunk(quoteId, currencyPair));
        break;

      case 'Accumulator':
        dispatch(ac.accumulatorRfsCancelThunk(quoteId));
        dispatch(ac.espTileStreamUnsubscribeThunk(quoteId));

        if ((sl.getAccumulatorTradeCaptureIdVersion(state, quoteId) ?? 0) > 0) {
          dispatch(ac.accumulatorTileReset(quoteId));
        }

        dispatch(ac.accumulatorPropertiesChanged(quoteId, { currencyPair }));
        break;

      case 'Order':
        dispatch(ac.orderCurrencyPairChangeThunk(quoteId, currencyPair));
        break;

      case 'Bulk':
        dispatch(ac.bulkCurrencyPairChangedThunk(quoteId, currencyPair));
        break;

      case 'BlotterOrder':
        return assertUnhandled('Submitted order currency pair cannot be changed', instrument);

      case 'AmericanForward':
        dispatch(ac.americanForwardPropertiesChanged(quoteId, { currencyPair, premiumPaymentAmount: '0' }));
        break;

      default:
        assertUnreachable(instrument, 'Currency pair change not handled');
    }
  };
}

export function resetTileAfterExecutionThunk(quoteId: string, status: TileExecutionStatus): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    dispatch(ac.tileExecutionOverlayHidden(quoteId));

    if (sl.isResetTileOnSuccessfulExecution(state) && status === TileExecutionStatus.done) {
      const productName = sl.getProductName(state, quoteId);

      switch (productName) {
        case 'FxSpot': {
          const resetedValues = getCashResetedValues(state, quoteId, sl);

          return dispatch(ac.cashPropertiesChanged(quoteId, resetedValues));
        }

        case 'FxSwap':
        case 'FxNdSwap': {
          dispatch(ac.tileInstrumentChangedThunk(quoteId, 'Cash'));

          const resetedValues = getSwapResetedToCashValues(state, quoteId, sl);

          return dispatch(ac.cashPropertiesChanged(quoteId, resetedValues));
        }

        case 'FxFwd': {
          const resetedValues = getCashResetedDeliverableForwardValues(state, quoteId, sl);

          return dispatch(ac.cashPropertiesChanged(quoteId, resetedValues));
        }

        case 'FxNdf': {
          const resetedValues = getCashResetedNonDeliverableForwardValues(state, quoteId, sl);

          return dispatch(ac.cashPropertiesChanged(quoteId, resetedValues));
        }
      }
    }
  };
}

function getCashResetedValues(state: AppState, quoteId: string, sl: Selectors) {
  const amount = sl.getUserDefaultNotional(state);
  const amountCurrency = fieldData(sl.getCashAmountCurrency(state, quoteId)).data;

  const defaultMaturity = sl.getUserDefaultMaturityDate(state);

  const maturityDate = defaultMaturity !== null ? (isMaturityDate(defaultMaturity) ? defaultMaturity : null) : null;
  const maturityDateTenor =
    defaultMaturity !== null ? (!isMaturityDate(defaultMaturity) ? defaultMaturity : null) : SPOT_TENOR;

  return { amount, amountCurrency, maturityDate, maturityDateTenor };
}

function getCashResetedDeliverableForwardValues(state: AppState, quoteId: string, sl: Selectors) {
  const resetedValues = getCashResetedValues(state, quoteId, sl);

  return {
    ...resetedValues,
  };
}

function getCashResetedNonDeliverableForwardValues(state: AppState, quoteId: string, sl: Selectors) {
  const resetedValues = getCashResetedValues(state, quoteId, sl);
  const canBeDelivered = isCanBeDelivered(state, quoteId, sl);

  return canBeDelivered
    ? {
        isNonDeliverable: false,
        ...resetedValues,
      }
    : resetedValues;
}

function isCanBeDelivered(state: AppState, quoteId: string, sl: Selectors) {
  const currencyPair = sl.getCashCurrencyPair(state, quoteId).value;
  const extCcyPair = currencyPair ? sl.getCurrencyPairDetails(state, currencyPair) : null;

  return extCcyPair !== null && extCcyPair.canBeDelivered;
}

export function nonDeliverableFlagChangeThunk(quoteId: string, isNonDeliverable: boolean): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const instrument = sl.getQuoteInstrument(state, quoteId);

    switch (instrument) {
      case 'Cash':
        if (!isNonDeliverable && sl.isResetTileOnSuccessfulExecution(state)) {
          dispatch(
            ac.cashPropertiesChanged(quoteId, {
              isNonDeliverable: false,
              maturityDateTenor: SPOT_TENOR,
            }),
          );
          dispatch(
            ac.cashDefaultFixingSourceOverridden(quoteId, {
              defaultFixingSource: null,
              defaultSndFixingSource: null,
            }),
          );
        } else {
          dispatch(ac.cashPropertiesChanged(quoteId, { isNonDeliverable }));
        }
        break;

      case 'Swap':
        dispatch(
          ac.swapPropertyChanged(quoteId, {
            isNonDeliverable,
            isOffMarket: false,
            nearPriceReference: null,
            farPriceReference: null,
          }),
        );
        break;

      default:
        logger.logWarning(`instrument {instrument_s} has no support for ND currency pair`, instrument);
    }
  };
}

export function tileClosedThunk(quoteId: string, automatically = false): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    const instrument = sl.getQuoteInstrument(state, quoteId);

    switch (instrument) {
      case 'Cash':
        return dispatch(ac.cashTileClosed(quoteId, automatically));
      case 'Option':
        return dispatch(ac.optionTileClosed(quoteId, automatically));
      case 'AmericanForward':
        return dispatch(ac.americanForwardTileClosed(quoteId, automatically));
      case 'Swap':
        return dispatch(ac.swapTileClosed(quoteId, automatically));
      case 'Accumulator':
        return dispatch(ac.accumulatorTileClosedThunk(quoteId, automatically));
      case 'Bulk':
        return;
      case 'Order':
      case 'BlotterOrder':
        return dispatch(ac.orderClosedThunk(quoteId, automatically));
      case 'SmartRfs': {
        const tabId = sl.getClientWorkspaceIdByQuoteId(state, quoteId);
        return dispatch(ac.clientWorkspaceTileDeleted(quoteId, tabId, automatically));
      }
      default:
        assertUnreachable(instrument, 'Instrument not handled in tile closed thunk');
    }
  };
}

function getSwapResetedToCashValues(state: AppState, quoteId: string, sl: Selectors) {
  const amount = sl.getUserDefaultNotional(state);
  const amountCurrency = fieldData(sl.getSwapAmountCurrency(state, quoteId)).data;

  const defaultMaturity = sl.getUserDefaultMaturityDate(state);

  const maturityDate = defaultMaturity !== null ? (isMaturityDate(defaultMaturity) ? defaultMaturity : null) : null;
  const maturityDateTenor =
    defaultMaturity !== null ? (!isMaturityDate(defaultMaturity) ? defaultMaturity : null) : SPOT_TENOR;

  return { amount, amountCurrency, maturityDate, maturityDateTenor };
}

/**
 * @todo get all rfs request actions
 */

export function rfsRequestThunk(quoteId: string, forceTradable = false, bypassCheck = false): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    const instrument = sl.getQuoteInstrument(state, quoteId);

    switch (instrument) {
      case 'Cash':
        return dispatch(ac.cashRfsRequestedThunk(quoteId));
      case 'Accumulator':
        dispatch(ac.accumulatorRfsCancelThunk(quoteId));
        return dispatch(ac.accumulatorRfsRequestEpic(quoteId, forceTradable, bypassCheck));
      case 'Option':
        return dispatch(ac.optionStreamRequestedThunk(quoteId, forceTradable, bypassCheck));
      case 'AmericanForward':
        return dispatch(ac.americanForwardRequestedThunk(quoteId, forceTradable, bypassCheck));
      case 'Swap':
      case 'Bulk':
      case 'Order':
      case 'BlotterOrder':
      case 'SmartRfs':
        return;
      default:
        assertUnreachable(instrument, 'Instrument not handled in rfs request thunk');
    }
  };
}

export function resetTileThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    const instrument = sl.getQuoteInstrument(state, quoteId);

    switch (instrument) {
      case 'Cash':
        dispatch(ac.cashRfsCancelThunk(quoteId));
        return dispatch(ac.cashTileReset(quoteId));
      case 'Accumulator':
        dispatch(ac.accumulatorRfsCancelThunk(quoteId));
        return dispatch(ac.accumulatorTileReset(quoteId));
      case 'Option':
        dispatch(ac.clientWorkspaceTileMinimizedThunk());
        dispatch(ac.optionStreamCancelThunk(quoteId));
        return dispatch(ac.optionTileReset(quoteId));
      case 'Swap':
        dispatch(ac.swapRfsCancelThunk(quoteId));
        return dispatch(ac.swapTileReset(quoteId));
      case 'Bulk':
        dispatch(ac.bulkRfsCancelThunk(quoteId));
        return dispatch(ac.bulkResetAll(quoteId));
      case 'AmericanForward':
        dispatch(ac.americanForwardRfsCancelThunk(quoteId));
        return dispatch(ac.americanForwardTileReset(quoteId));
      case 'Order':
      case 'BlotterOrder':
      case 'SmartRfs':
        return;
      default:
        assertUnreachable(instrument, 'Instrument not handled in reset tile thunk');
    }
  };
}

/**
 * Wrapper for resetTileThunk and keep currencyPair if it's filled
 * @param quoteId string
 * @returns Thunk<void>
 */
export function partialResetTileThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const currencyPair = sl.getTileCurrencyPair(getState(), quoteId).value;

    dispatch(ac.resetTileThunk(quoteId));

    if (currencyPair !== null) {
      dispatch(ac.currencyPairChangeThunk(quoteId, currencyPair));
    }
  };
}

export function tileDuplicateThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac, getNewGuid }) => {
    const state = getState();
    const instrument = sl.getQuoteInstrument(state, quoteId);

    if (!isSwitchableInstrument(instrument)) {
      // duplicate not handled on Bulk and Order instruments
      return;
    }

    const productName = sl.getProductName(state, quoteId)!;

    const instrumentChoice: InstrumentChoice =
      instrument !== 'Accumulator'
        ? instrument
        : productName === 'FxForwardAccumulator'
          ? 'ForwardAccumulator'
          : 'TargetAccumulator';

    const activeTab = sl.getClientWorkspaceActiveTab(state)!;
    const { top, left } = sl.getGridItemPosition(state, activeTab, quoteId);
    const { width } = sl.getGridItemSize(state, activeTab, quoteId);
    const newTileId = getNewGuid();

    const newPosition = {
      left: left + width,
      top,
    };

    const isOptionGrouped = instrument === 'Option' ? sl.getUserPreferenceData(state).optionStrategyGroupLegs : null;

    const isOptionGreekAndMktExpanded =
      instrument === 'Option' ? sl.getUserPreferenceData(state).optionExpandGreekAndMkt : null;

    dispatch(
      ac.clientWorkspaceTileDuplicated(
        activeTab,
        quoteId,
        newTileId,
        instrumentChoice,
        productName,
        newPosition,
        isOptionGrouped,
        isOptionGreekAndMktExpanded,
      ),
    );

    dispatch(ac.gridItemDrag(activeTab, newTileId, newPosition));
    dispatch(ac.gridItemDragEnd(activeTab));

    const ccyPair = sl.getTileCurrencyPair(state, quoteId).value;

    if (isNotDefined(ccyPair)) {
      return;
    }

    // we don't handle TC Update action for smartRfs
    if (instrument === 'SmartRfs') {
      return;
    }

    dispatch(getTCUpdateActions(quoteId, newTileId, instrument));
  };
}

function getTCUpdateActions<I extends Exclude<SwitchableInstrument, 'SmartRfs'>>(
  existingTileId: string,
  newTileId: string,
  instrument: I,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const inputs = getInputs(instrument)(sl, state, existingTileId);

    switch (instrument) {
      case 'Cash': {
        const { bidMargin, askMargin, bidForwardMargin, askForwardMargin } = sl.getCashState(state, existingTileId);

        dispatch(ac.cashPropertiesChanged(newTileId, inputs));
        dispatch(
          ac.cashLocalPropertyChanged(newTileId, {
            bidMargin,
            askMargin,
            bidForwardMargin,
            askForwardMargin,
          }),
        );
        return;
      }
      case 'Swap': {
        const swapMargins = sl.getSwapMargin(state, existingTileId);

        dispatch(ac.swapPropertyChanged(newTileId, inputs));
        dispatch(ac.swapLocalPropertyChanged(newTileId, swapMargins));
        return;
      }
      case 'Option': {
        const allLegsInput = getAllLegsInputs(sl, state, existingTileId);
        const allHedgesInput = getAllHedgesInputs(sl, state, existingTileId);

        if (sl.getOptionIsStrategy(state, existingTileId)) {
          dispatch(ac.optionToggleStrategyThunk(newTileId, true));
        }

        dispatch(
          ac.optionPropertyChanged(newTileId, {
            ...inputs,
            legs: allLegsInput,
            hedges: allHedgesInput,
          }),
        );
        return;
      }
      case 'Accumulator':
        dispatch(ac.accumulatorPropertiesChanged(newTileId, inputs));
        return;

      case 'AmericanForward':
        const solvingType = sl.getAmericanForwardSolvingType(state, existingTileId);
        dispatch(ac.americanForwardSolvingTypeChanged(newTileId, solvingType));
        dispatch(ac.americanForwardPropertiesChanged(newTileId, inputs));
        return;

      default:
        assertUnhandled(`Unknown TC updates actions for instrument ${instrument}`, instrument);
    }
  };
}

export function tileClientOverridenThunk(quoteId: string, clientId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    const currentClientId = sl.getClientIdByQuoteId(state, quoteId);

    if (currentClientId === clientId) {
      return;
    }

    const tabId = sl.getClientWorkspaceIdByQuoteId(state, quoteId);
    const { clientId: defaultClientId } = sl.getClientWorkspaceTab(state, tabId);

    if (clientId === defaultClientId) {
      return dispatch(tileClientResetThunk(quoteId));
    }

    dispatch(ac.tileClientOverridden(quoteId, clientId));
    dispatch(ac.orderRequestEmailsEpic(clientId, tabId));

    const instrument = sl.getQuoteInstrument(state, quoteId);

    dispatch(clientChangedThunk(quoteId, instrument, clientId));
  };
}

export function tileClientResetThunk(quoteId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isTilePresent(state, quoteId)) {
      return;
    }

    dispatch(ac.tileClientReset(quoteId));

    const instrument = sl.getQuoteInstrument(state, quoteId);
    const tabId = sl.getClientWorkspaceIdByQuoteId(state, quoteId);
    const { clientId } = sl.getClientWorkspaceTab(state, tabId);

    assertIsDefined(clientId, 'Unexpected null clientId in tileClientResetThunk');

    dispatch(ac.orderRequestEmailsEpic(clientId, tabId));

    dispatch(clientChangedThunk(quoteId, instrument, clientId));
  };
}

function clientChangedThunk(quoteId: string, instrument: Instrument, clientId: string): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    switch (instrument) {
      case 'Cash':
        dispatch(ac.cashClientChangedThunk(quoteId, clientId));
        return;
      case 'Option':
        dispatch(ac.optionClientChangedThunk(quoteId, clientId));
        return;
      case 'Swap':
        dispatch(ac.swapClientChangedThunk(quoteId));
        return;
      case 'Accumulator':
        dispatch(ac.accumulatorClientChangedThunk(quoteId, clientId));
        return;
      case 'AmericanForward':
        dispatch(ac.americanForwardClientChangedThunk(quoteId, clientId));
        return;
      case 'Order':
        dispatch(ac.orderClientChangedThunk(quoteId, clientId));
        return;
      case 'BlotterOrder':
      case 'Bulk':
      case 'SmartRfs': // todo-5153 do we want to handle client change ?
        return;
      default:
        assertUnreachable(instrument, 'Unhandled instrument');
    }
  };
}
