import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';

export type LockButtonConnectOwnProps = {}

export interface LockButtonConnectStateProps {
  canToggleLock: boolean;
  locked: boolean;
}

export interface LockButtonConnectDispatchProps {
  toggleLock(): void;
}

// you can whitelist selectors after implementation
export type LockButtonConnectSelectors = Selectors;
/*
export type LockButtonConnectSelectorsKeys = 'aSelector'; // example
export type LockButtonConnectSelectors = Pick<Selectors, LockButtonConnectSelectorsKeys>;
*/

export const mapStateToPropsLockButton: MapStateToPropsHOF<
  LockButtonConnectStateProps,
  LockButtonConnectOwnProps,
  AppState,
  LockButtonConnectSelectors
> = sl => state => ({
  locked: !sl.userCanTrade(state),
  canToggleLock: sl.getUserInfo(state).canTrade,
});

// you can whitelist action creators after implementation
export type LockButtonConnectActionCreators = ActionCreators;
/*
export type LockButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type LockButtonConnectActionCreators = Pick<ActionCreators, LockButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsLockButton: MapDispatchToPropsHOF<
  LockButtonConnectDispatchProps,
  LockButtonConnectOwnProps,
  LockButtonConnectActionCreators
> = ac => dispatch => ({
  toggleLock: () => dispatch(ac.toggleLockTraddingThunk()),
});
