import type { TargetAccuType } from 'state/fxAccumulators/fxAccumulatorsModel';
import type { Instrument } from 'state/referenceData';
import type { StrictExtract } from 'utils/object';

export type InstrumentWithImport = StrictExtract<Instrument, 'Option' | 'Bulk' | 'Accumulator'>;

export const INSTRUMENT_TO_PREFIX: Record<InstrumentWithImport, string> = {
  Option: 'optiontile',
  Bulk: 'bulkPricer',
  Accumulator: 'accumulator',
};

export type InstrumentExample = 'Option' | 'BulkTrade' | 'BulkStopLoss' | 'TargetAccumulator' | 'ForwardAccumulator';

export const INSTRUMENT_TO_EXAMPLE: Record<InstrumentExample | TargetAccuType, string> = {
  Option: 'Strategy',
  BulkTrade: 'BulkTrade',
  BulkStopLoss: 'BulkStopLoss',
  ForwardAccumulator: 'ForwardSchedule',
  TargetAccumulator: 'TargetSchedule',
  TargetAccu: 'TargetSchedule',
  EKI: 'TargetEKISchedule',
  PIVOT: 'TargetPIVOTSchedule',
  PIVOTEKI: 'TargetPIVOTEKISchedule',
};
