import type { IReferenceDataApi } from 'epics/retrieveReferenceData';
import { logger } from 'logging/logger';
import { map } from 'rxjs/operators';
import type { ClosedDates, EspLimits, User } from 'state/referenceData/referenceDataModel';
import { extract } from 'utils/object';
import { type ISgmeHttp, correlationIdHeaderName } from 'utils/sgmeHttp';
import { v4 as getNewGuid } from 'uuid';

export const referenceDataApi = (http: ISgmeHttp, getGuid = getNewGuid, _logger = logger): IReferenceDataApi => ({
  getUserData() {
    const correlationId = getGuid();
    const headers = { [correlationIdHeaderName]: correlationId };
    _logger.logInformation('Requesting user/current with {correlationId_s}', headers['X-Correlation-ID']);
    return http.getJSON<User>('api/user/current', headers).pipe(map(extract('payload')));
  },
  getClosedDates(currencyPairs: readonly string[]) {
    return http.post<ClosedDates>('api/dates/closed', currencyPairs).pipe(map(extract('payload')));
  },
  getEspLimits(currencies: readonly string[]) {
    return http.post<EspLimits>('api/user/espLimits', currencies).pipe(map(extract('payload')));
  },
  timeout: 10_000,
});
