import { isEmpty } from '@sgme/fp';

/**
 * given a string representation of a number and separators
 * return a formated string representation number
 */
export function formatNumberAsStringToLocale(
  number: string,
  localeThousandSeparator: string,
  localeDecimalSeparator: string,
): string {
  if (!isEmpty(number) && !'0123456789'.includes(number[0])) {
    return (
      number[0] +
      formatNumberAsStringToLocale(number.slice(1), localeThousandSeparator, localeDecimalSeparator)
    );
  }
  // splits integer and decimal part
  const [integer, decimal] = number.split('.') as [string, string | undefined];
  // only format integer part
  const localeInteger = splitIntegerByThousands(integer).join(localeThousandSeparator);
  // concatenate decimal part if it exists
  const localeNumber =
    decimal === undefined ? localeInteger : [localeInteger, decimal].join(localeDecimalSeparator);

  return localeNumber;
}

/**
 * splits a string into an array of three char strings, except the first between 1 and 3
 */
function splitIntegerByThousands(number: string) {
  const splitNumber: string[] = [];
  // first item length
  const leftHandLength = ((number.length - 1) % 3) + 1;
  // number of items
  const splitInto = Math.floor((number.length - 1) / 3);

  for (let splitIndex = 0; splitIndex <= splitInto; splitIndex++) {
    // split 3 by 3 (except first)
    splitNumber.push(
      number.substring(leftHandLength + splitIndex * 3 - 3, leftHandLength + splitIndex * 3),
    );
  }

  return splitNumber;
}

/**
 * calculates the cursor position offset
 * from the localized string (input field)
 * to the non-localized representation of the number
 */
export function getCursorOffsetFromSelectionToValue(
  cursor: number,
  localized: string,
  separator: string,
) {
  let offset = 0;
  for (let index = 0; index < localized.length && index < cursor; index++) {
    if (localized[index] === separator) {
      offset++;
    }
  }
  return offset;
}

/**
 * calculates the cursor position offset
 * from non-localized representation of the number
 * to the localized string (input field)
 */
export function getCursorOffsetFromValueToSelection(
  cursor: number,
  localized: string,
  separator: string,
) {
  let offset = 0;
  for (let index = 0; index < localized.length && cursor > 0; index++) {
    if (localized[index] === separator) {
      offset++;
    } else {
      cursor--;
    }
  }
  return offset;
}

export function stringToNumber(value: string | null): number | null {
  return value === '' || value === null ? null : Number.parseFloat(value);
}
