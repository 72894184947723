import isDeepEqual from 'fast-deep-equal';
import type { Middleware } from 'redux';
import { getSharedWorkspacesToSendWith } from '../../epics/metaSelectors/workspace/saveWorkspaceSelectors';
import type { DispatchExt } from '../../state';
import type { Action } from '../../state/globalActions';
import type { AppState } from '../../state/model';
import { selectors } from '../../state/selectors';

export const sharedWorkspaceMiddleware: Middleware<DispatchExt, AppState> =
  ({ getState }) =>
  (next) =>
  (action: Action) => {
    const currentState = getState();
    const actionResult = next(action);
    const newState = getState();

    const allSharedCurrentWorkspaces = getSharedWorkspacesToSendWith(selectors)(currentState);
    const allSharedNewWorkspaces = getSharedWorkspacesToSendWith(selectors)(newState);

    const hasChanges = !isDeepEqual(allSharedCurrentWorkspaces, allSharedNewWorkspaces);

    if (hasChanges) {
      console.log(`=== changes detected in a shared workspace`);
    }

    actionResult;
  };

// const DATA_NAME_BY_INSTRUMENT: Record<Instrument, keyof AppState> = {
//   Cash: 'fxCashs',
//   Swap: 'fxSwaps',
//   Option: 'fxOptions',
//   Accumulator: 'fxAccumulators',
//   AmericanForward: 'fxAmericanForwards',
//   Order: 'fxOrders',
//   Bulk: 'fxBulks',
//   BlotterOrder: 'blotter',
//   SmartRfs: 'fxSmartRfs',
// };
