import type { GridItemSizeChange } from 'state/gridLayout/gridLayoutActions';
import { colWidth } from 'styles/constants';
import { extract } from 'utils/object';
import {
  type Patcher,
  composeLeftPatcher,
  entriesDiff,
  filterArrayByMatchingKey,
  insertAtIndex,
  updateArrayByMatchingKey,
  updateKey,
} from 'utils/stateMap';
import type { Column, GridItemSize, GridLayout, GridState, Locus } from '../gridLayoutModels';
import { reduceGridItemVerticalOffsetPropagation, shiftRight } from './moveGridItems';
import {
  findLocusInsertionIndexInColumn,
  getDownwardNeighbourGridItem,
  getLociById,
  getRightNeighbourGridItemCoordinates,
  getSizeById,
} from './utils';

const verticalSizeChanged =
  (action: GridItemSizeChange, oldSize: GridItemSize) =>
  (gridLayout: GridLayout): Partial<GridLayout> => {
    const deltaHeight = action.size.height - oldSize.height;
    if (deltaHeight === 0) {
      return gridLayout;
    }
    const gridItemSize = getSizeById(gridLayout, action.gridItemId);
    const columnUpdater = updateArrayByMatchingKey<Locus, 'gridItemId'>('gridItemId', action.gridItemId, (locus) => ({
      bottom: locus.top + gridItemSize.height,
    }));
    const columns = gridLayout.columns.map(columnUpdater);
    const newGridState = { ...gridLayout, columns };

    return reduceGridItemVerticalOffsetPropagation(
      newGridState,
      getDownwardNeighbourGridItem(newGridState, action.gridItemId),
    );
  };

const updateGridItemSizeByGridItemId =
  (gridItemId: string, patcher: Patcher<GridItemSize>) => (previousGridState: GridLayout) => ({
    gridItemSizes: updateKey(previousGridState.gridItemSizes, gridItemId, patcher),
  });

const horizontalSizeChanged =
  (action: GridItemSizeChange, oldSize: GridItemSize) =>
  (gridState: GridLayout): GridLayout => {
    const deltaCol = Math.floor(action.size.width / colWidth) - Math.floor(oldSize.width / colWidth);
    const templateLocus = getLociById(gridState, action.gridItemId)[0].locus;

    const findLocusInsertionIndex = findLocusInsertionIndexInColumn(templateLocus.top, templateLocus.bottom);

    if (deltaCol === 0) {
      // small size change that should not trigger any columns update
      return gridState;
    } else if (deltaCol > 0) {
      // a gridItem increase in size
      let gridStateTmp: GridLayout = gridState;
      // for each additional column the grid item will now occupy
      for (let i = 0; i < deltaCol; i++) {
        // get neighbouring grid items to push rightward
        const { neighbours, colIndex } = getRightNeighbourGridItemCoordinates(gridStateTmp, action.gridItemId);

        const getInsertionLocusIndex = (column: Column) =>
          neighbours === undefined || neighbours[0] === undefined
            ? findLocusInsertionIndex(column)
            : neighbours[0].locusIndex;

        gridStateTmp = composeLeftPatcher(
          // recursive update of neighbours
          shiftRight(neighbours),
          // pre emptive update of current item size of one addition column width wide
          updateGridItemSizeByGridItemId(action.gridItemId, ({ width }) => ({
            width: width + colWidth,
          })),
          // add loci to the immediate right hand side column
          ({ columns }) => ({
            columns: columns.map((column, index) =>
              index !== colIndex
                ? column
                : insertAtIndex([{ ...templateLocus }], getInsertionLocusIndex(column))(column),
            ),
          }),
        )(gridStateTmp);
      }
      // trigger upward update on all modified tiles and their immediate downward neighbour
      gridStateTmp = entriesDiff(gridState.gridItemPositions, gridStateTmp.gridItemPositions).reduce(
        (previousGridState, [gridItemId]) => ({
          ...previousGridState,
          ...reduceGridItemVerticalOffsetPropagation(
            previousGridState,
            [{ gridItemId }].concat(getDownwardNeighbourGridItem(previousGridState, gridItemId)),
          ),
        }),
        gridStateTmp,
      );
      // trigger upward update on all immediate downward neighbour of the current tile
      return {
        ...gridStateTmp,
        ...reduceGridItemVerticalOffsetPropagation(
          gridStateTmp,
          getDownwardNeighbourGridItem(gridStateTmp, action.gridItemId),
        ),
      };
    } else {
      const deleteLocusInColumns = getLociById(gridState, action.gridItemId).slice(1).map(extract('colIndex'));
      // get downward neighbours
      const downWardNeighbours = getDownwardNeighbourGridItem(gridState, action.gridItemId);
      // delete deltaCol loci
      const colUpdater = filterArrayByMatchingKey<Locus, 'gridItemId'>('gridItemId', action.gridItemId);
      const columns = gridState.columns.map((column, index) =>
        deleteLocusInColumns.includes(index) ? colUpdater(column) : column,
      );
      return {
        ...gridState,
        ...reduceGridItemVerticalOffsetPropagation({ ...gridState, columns }, downWardNeighbours),
      };
    }
  };

export const gridItemSizeChanged =
  (action: GridItemSizeChange) =>
  ({ gridLayout }: GridState): Pick<GridState, 'gridLayout'> => {
    const {
      gridItemId,
      size: { height, width },
    } = action;
    const oldSize = getSizeById(gridLayout, gridItemId);
    const updateHeight = updateGridItemSizeByGridItemId(gridItemId, () => ({
      height,
    }));
    const updateWidth = updateGridItemSizeByGridItemId(gridItemId, () => ({
      width,
    }));
    return {
      gridLayout: composeLeftPatcher(
        updateHeight,
        verticalSizeChanged(action, oldSize),
        horizontalSizeChanged(action, oldSize),
        updateWidth,
      )(gridLayout),
    };
  };
