import { isString } from '@sgme/fp';
import { getApiUrl } from '../../../bootstrap/sgmeConfiguration';
import type { ValidationResponseDetailContent } from './validate';

export type PostSubmitOrderBody = {
  isGtc: boolean;
  expiryDay: string | undefined;
  expiryTime: string | undefined;
  bdrId: string;
  ccyPair: string;
  way: 'buy' | 'sell';
  amountInCcy1: number | undefined;
  amountInCcy2: number | undefined;
  limitPrice: string;
  base64Emails: string;
  triggerMode: string;
  localTimeWithOffSet: number;
};

export type PostSubmitOrderResponse =
  | {
      type: 'success';
      orderId: string;
    }
  | {
      type: 'failure';
      errors: ValidationResponseDetailContent[];
    };

const apiUrl = getApiUrl();

export const postSubmitOrder = (body: PostSubmitOrderBody, bearer: string): Promise<PostSubmitOrderResponse> => {
  return fetch(`${apiUrl}api/oms/submit/StopLoss`, {
    method: 'POST',
    headers: {
      Authorization: bearer,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then(async (response) => {
    if (response.status === 200 || response.status === 201) {
      return {
        type: 'success',
        orderId: await response.json(),
      };
    }

    // when there is an error, OMS returns
    // - either an object
    // - or a string with an error
    const error = await response.json();

    return {
      type: 'failure',
      errors: isString(error)
        ? [
            {
              field: 'orderId',
              errorDescription: error,
              errorCode: -1,
            },
          ]
        : error.validationResponseDetailContent,
    };
  });
};
