import { type ComponentType, type FunctionComponent, createContext, useContext } from 'react';

const defaultValue = '@@_MISSING_TABID_PROVIDER';

export const TabIdContext = createContext<string>(defaultValue);

TabIdContext.displayName = 'TabIdContext';

export const { Provider: TabIdProvider, Consumer: TabIdConsumer } = TabIdContext;

export function withTabId<
  P extends {
    tabId: string;
  },
>(Component: ComponentType<P>) {
  const WithTabId: FunctionComponent<Omit<P, 'tabId'>> = (props) => {
    const tabId = useContext(TabIdContext);
    return <Component {...(props as P)} tabId={tabId} />;
  };
  return WithTabId;
}
