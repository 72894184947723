import { throwIfUndefined } from 'utils/maps';
import type { AppState } from '../../model';
import { getAllCcyPairs, getTileMaxDate } from '../../referenceData/referenceDataSelectors';
import { isNotDefined } from '@sgme/fp';
import { isAfter, parseISO } from 'date-fns';
import type { Predicate } from 'utils/predicates';
import type { CurrencyPair } from 'state/referenceData';
import { mapOrDefault } from 'utils/optional';

export const getAmericanForwardState = (state: AppState, americanForwardTileId: string) =>
  throwIfUndefined(
    state.fxAmericanForwards.americanForwards[americanForwardTileId],
    `American Forward state for tile ${americanForwardTileId} does not exist`,
  );

export function isValidDateAmericanForward(state: AppState, americanForwardId: string) {
  const maxDate = getTileMaxDate(state, americanForwardId);

  if (maxDate === undefined) {
    return true;
  }

  const expiryDate = getAmericanForwardState(state, americanForwardId).values.expiryDate;

  return isNotDefined(expiryDate) || !isAfter(parseISO(expiryDate), maxDate);
}

export function getMarkupCurrency(state: AppState, americanForwardId: string): string | null {
  const {
    values: { markupCurrency },
  } = getAmericanForwardState(state, americanForwardId);

  return markupCurrency;
}
const isCurrencyPairCompatible = (compatiblePredicate: Predicate<CurrencyPair>) => {
  const optionalPredicate = mapOrDefault(compatiblePredicate, false);
  return (state: Readonly<AppState>, ccyPair: string) => optionalPredicate(getAllCcyPairs(state)[ccyPair]);

}

export const isCurrencyPairAmericanForwardCompatible = isCurrencyPairCompatible(({ isOptionCurrency }) => isOptionCurrency);

export function getAmericanForwardHedge(state: AppState, americanForwardId: string) {
  const {
    values: { hedgeAmount, hedgeCurrency, hedgePaymentDate, hedgeRate },
  } = getAmericanForwardState(state, americanForwardId);

  return { hedgeAmount, hedgeCurrency, hedgePaymentDate, hedgeRate }
}

export function getAmericanForwardCurrencyPair(state: AppState, americanForwardId: string) {
  const {
    values: { currencyPair },
  } = getAmericanForwardState(state, americanForwardId);

  if (currencyPair !== null) {
    const [ccy1, ccy2] = (currencyPair || '/').split('/');
    return { ccy1, ccy2 };
  }

  return null;
}

export function getAmericanForwardMarkupCurrencyChoice(state: AppState, americanForwardId: string) {
  const currencyPair = getAmericanForwardCurrencyPair(state, americanForwardId);

  if (currencyPair === null) {
    return null;
  }

  const markupCurrency = getMarkupCurrency(state, americanForwardId);

  switch (markupCurrency) {
    case currencyPair.ccy1:
      return 1;
    case currencyPair.ccy2:
      return 2;
    default:
      return null;
  }
}

export function isAmericanForwardHedgeAmountValidated(state: AppState, americanForwardId: string) {
  const americanForward = getAmericanForwardState(state, americanForwardId);

  return isNotDefined(americanForward.errors.hedgeAmount) && isNotDefined(americanForward.warnings.hedgeAmount);
}
