import type { MapStateToMetadataHOF } from '../../../typings/redux-utils';
import type { AppState } from '../../../state/model';
import type { Selectors } from '../../../state/selectors';
import { clearUndefined } from '../../../utils/clearUndefined';
import type {
  TradeCaptureAmericanForward,
  TradeCaptureAmericanForwardHedgeType,
  TradeCaptureAmericanForwardLegRequest,
  TradeCaptureAmericanForwardRequestWrapper,
} from '../../../api/tradeCapture/americanForward/tradeCaptureModel';
import type {
  FxAmericanForwardInputs,
  HedgeType,
  PriceType,
} from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import type { DateInputCultureInfo } from '../../../state/userPreferences';
import { parseWithCultureInfo } from '../../../utils/parseDateWithCultureInfo';
import { isDefined, isNotDefined } from '@sgme/fp';
import type { CurrencyChoice } from '../../../state/share/productModel/litterals';
import type { PremiumType } from '../../../api/tradeCapture/option/tradeCaptureOptionModel';

export type AmericanForwardStoreModelChanges = Partial<FxAmericanForwardInputs>;

export type TradeCaptureToBackendMetadata = {
  patch: AmericanForwardStoreModelChanges;
} & WithQuoteId;

interface WithQuoteId {
  quoteId: string;
}

export type TradeCaptureToBackendMetaSelectorsKeys =
  | 'getAmericanForwardTradeCaptureIdVersion'
  | 'getUserPreferenceData'
  | 'getAmericanForwardProductName'
  | 'getAmericanForwardMarkupCurrency';

export type TradeCaptureToBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureToBackendMetaSelectorsKeys>;

export const metaSelectorTradeCaptureToBackendWith: MapStateToMetadataHOF<
  TradeCaptureAmericanForwardRequestWrapper,
  TradeCaptureToBackendMetadata,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = (sl) => {
  const toTradeCapturePatch = metaSelectorAmericanForwardToTradeCapturePatchWith(sl);
  const toTradeCaptureLegPatch = metaSelectorAmericanForwardToTradeCaptureLegPatchWith(sl);

  return (state, { quoteId, patch }) => {
    const idVersion = getNextIdVersion(sl.getAmericanForwardTradeCaptureIdVersion(state, quoteId));
    const productChangedFields = toTradeCapturePatch(state, patch);
    const legChangedFields = toTradeCaptureLegPatch(state, {
      quoteId,
      ...patch,
    });

    return {
      idVersion,
      changedFields: {
        ...productChangedFields,
        legs: {
          0: legChangedFields,
        },
      },
    };
  };
};

const metaSelectorAmericanForwardToTradeCapturePatchWith: MapStateToMetadataHOF<
  Partial<TradeCaptureAmericanForward>,
  Partial<FxAmericanForwardInputs>,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> = (_sl) => (_state, patch) => {
  const hedgeAmount =
    patch.hedgeAmount !== undefined
      ? patch.hedgeCurrency === 1
        ? { hedgeAmountInCcy1String: patch.hedgeAmount }
        : { hedgeAmountInCcy2String: patch.hedgeAmount }
      : undefined;

  const leg = clearUndefined({ premiumDate: patch.premiumPaymentDate });

  const product = clearUndefined({
    currencyPair: patch.currencyPair,
    hedgeType: mapToTcHedgeType(patch.hedgeType as 'Live' | 'Spot' | 'Forward'),
    ...hedgeAmount,
    hedgePriceString: patch.hedgeRate,
    markupCurrency: patch.markupCurrency,
  });

  return {
    ...product,
    legs: {
      0: leg,
    },
  };
};

const metaSelectorAmericanForwardToTradeCaptureLegPatchWith: MapStateToMetadataHOF<
  Partial<TradeCaptureAmericanForwardLegRequest>,
  Partial<FxAmericanForwardInputs> & WithQuoteId,
  AppState,
  TradeCaptureToBackendMetaSelectorSelectors
> =
  (sl) =>
  (state, { quoteId, ...patch }) => {
    const { dateInputCultureInfo: cultureInfo } = sl.getUserPreferenceData(state);

    const productName = sl.getAmericanForwardProductName(state, quoteId);

    const amount =
      patch.notionalAmount !== undefined
        ? patch.notionalCurrency === 1
          ? { amount1String: isDefined(patch.notionalAmount) ? String(patch.notionalAmount) : null }
          : { amount2String: isDefined(patch.notionalAmount) ? String(patch.notionalAmount) : null }
        : undefined;

    return clearUndefined<Partial<TradeCaptureAmericanForwardLegRequest>>({
      productName,
      side: patch.side,
      ...amount,
      callabilityStartString: dateToPatch(patch.callabilityStart, patch.callabilityStartTenor, cultureInfo),
      expiryDateString: dateToPatch(patch.expiryDate, patch.expiryDateTenor, cultureInfo),
      deliveryDateString: dateToPatch(patch.deliveryDate, patch.deliveryDateTenor, cultureInfo),
      cutOffMarketPlace: patch.marketPlace,
      premiumPaymentAmountBidString: patch.premiumPaymentAmount,
      premiumPaymentAmountAskString: patch.premiumPaymentAmount,
      forwardRateString: patch.forwardRate,
      premiumType: mapToPremiumType(patch.premiumTypeString, patch.premiumCurrency),
    });
  };

function getNextIdVersion(lastIdVersion: number | null): number {
  return lastIdVersion === null ? 0 : lastIdVersion + 1;
}

const dateToPatch = (
  date: string | undefined | null,
  tenor: string | undefined | null,
  cultureInfo: DateInputCultureInfo,
) => {
  const ref = date || tenor;
  return ref ? parseWithCultureInfo(cultureInfo, ref) : undefined;
};

function mapToTcHedgeType(value: HedgeType | undefined): TradeCaptureAmericanForwardHedgeType | undefined {
  if (value === 'Live') {
    return 'None';
  }

  return value;
}

const priceAndCcyToPremiumType: Record<PriceType, [ccy1: PremiumType, ccy2: PremiumType]> = {
  PPS: ['Pip12', 'Pip21'],
  AMOUNT: ['AmountCurrency1', 'AmountCurrency2'],
  PERCENT: ['PercentageCurrency1', 'PercentageCurrency2'],
};

function mapToPremiumType(
  priceType: PriceType | null | undefined,
  amountCcy: CurrencyChoice | null | undefined,
): Omit<PremiumType, 'SmiledVolatility1' | 'SmiledVolatility2'> | undefined {
  if (isNotDefined(priceType) || isNotDefined(amountCcy)) {
    return undefined;
  }

  return priceAndCcyToPremiumType[priceType][amountCcy - 1];
}
