import { useDispatch } from 'react-redux';
import { actionCreators } from '../../../state/actions';

export type CloseTabButtonProps = {
  tabId: string;
};

export const CloseTabButton = (props: CloseTabButtonProps) => {
  const { tabId } = props;

  const dispatch = useDispatch();

  const onClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    dispatch(actionCreators.clientWorkspaceTabClosedThunk(tabId));
  };

  return (
    <button type="button" className="align-self-baseline btn btn-flat-secondary btn-icon p-0 ms-1" onClick={onClick}>
      <i className="icon icon-sm px-2">close</i>
    </button>
  );
};
