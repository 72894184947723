import { assertUnreachable, isDefined, isNonEmpty, makeValuesPredicate } from '@sgme/fp';
import type { Thunk } from 'state';
import { type AlgoOrderType, getOrderLiquidityPoolOptions, isAlgoOrder } from 'state/fxOrders/fxOrdersModel';
import type { PropertyErrors } from 'state/share/productModel/litterals';
import { assertUnhandled } from 'utils/error';
import { strictKeys } from 'utils/object';
import type {
  BlotterEntry,
  BlotterOrderAlgoInputs,
  BlotterOrderCommonInputs,
  BlotterOrderInputs,
  BlotterOrderValues,
} from '../blotterEntryModel';
import { type BlotterTab, type DetailsPanelMode, detailsPanelConfirmationModes } from '../blotterModel';

const isConfirmation = makeValuesPredicate(detailsPanelConfirmationModes);

const mapToTab = (entry: BlotterEntry): BlotterTab => {
  switch (entry.instrument) {
    case 'Order':
      return 'order';
    case 'Option':
      return 'option';
    case 'Accumulator':
      return 'accumulator';
    case 'Cash':
      return isDefined(entry.strategyReference) && isNonEmpty(entry.strategyReference) ? 'option' : 'cash';
    default:
      assertUnreachable(entry, 'Unable to map blotter entry instrument to a blotter tab');
  }
};

export function blotterDisplayDetailPanelThunk(
  selectedId: string | null,
  mode: DetailsPanelMode = 'Display',
  childrenIds: readonly string[] | null = null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    if (selectedId === null) {
      return;
    }

    const state = getState();
    const entry = sl.getBlotterDataById(state, selectedId);

    if (entry.instrument === 'Order') {
      return;
    }

    if (!sl.getBlotterIsOpen(state)) {
      dispatch(ac.blotterToggle(true));
    }

    const entryTab = mapToTab(entry);
    if (sl.getBlotterActiveTab(state) !== entryTab) {
      dispatch(ac.blotterTabChanged(entryTab));
    }

    const currentOpenedPanel = sl.getBlotterDetailsPanelSelectedId(state);

    if (currentOpenedPanel !== null) {
      const currentMode = sl.getBlotterDetailsPanelMode(state);
      if (currentMode === 'Edit' && entryTab === 'order') {
        dispatch(ac.blotterResetOrderInputThunk(currentOpenedPanel));
      }
    }

    dispatch(ac.blotterShowDetailsPanel(selectedId, mode, childrenIds));

    // bypass confirmation for algo orders
    if (entryTab === 'order') {
      const order = sl.getBlotterOrderById(state, selectedId).values;
      const isAlgo = isAlgoOrder(order.product);
      if (isAlgo && isConfirmation(mode)) {
        dispatch(blotterDetailsPanelConfirmMessageThunk(selectedId));
      }
    }
  };
}

export function blotterResetOrderInputThunk(orderId: string | null): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    if (orderId === null) {
      return;
    }

    const state = getState();
    const order = sl.getBlotterOrderById(state, orderId);

    // todo-5010 check type
    // @ts-ignore
    strictKeys(order.inputs).forEach((field) => dispatch(ac.blotterOrderResetProperty(order.id, field)));
  };
}

export function detailsPanelReceivedErrorsThunk(
  orderId: string | null,
  errors: PropertyErrors<BlotterOrderValues>,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    if (orderId === null) {
      return;
    }

    dispatch(ac.blotterSetDetailsPanelMode('SaveError'));
    dispatch(ac.blotterOrderErrorsReveived(orderId, errors));
  };
}

export function blotterSetDetailsPanelModeThunk(mode: DetailsPanelMode): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    dispatch(ac.blotterSetDetailsPanelMode(mode));

    // bypass confirmation for algo orders
    const selectedId = sl.getBlotterDetailsPanelSelectedId(state);
    if (selectedId && sl.getBlotterActiveTab(state) === 'order') {
      const order = sl.getBlotterOrderById(state, selectedId).values;
      const isAlgo = isAlgoOrder(order.product);

      if (isAlgo && isConfirmation(mode)) {
        dispatch(blotterDetailsPanelConfirmMessageThunk(selectedId));
      }
    }
  };
}

export function blotterDetailsPanelConfirmMessageThunk(orderId: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const currentMode = sl.getBlotterDetailsPanelMode(state);
    switch (currentMode) {
      case 'SaveConfirm':
        dispatch(ac.blotterOrderModifyEpic(orderId));
        return;
      case 'CancelConfirm':
        dispatch(ac.blotterOrderCancellationEpic(orderId));
        return;
      case 'PauseConfirm':
        dispatch(ac.blotterOrderPauseEpic(orderId));
        return;
      case 'ResumeConfirm':
        dispatch(ac.blotterOrderResumeEpic(orderId));
        return;
      case 'FillConfirm':
        dispatch(ac.blotterOrderFillEpic(orderId));
        return;
      default:
        assertUnhandled('Unhandled mode', currentMode);
    }
  };
}

export function blotterChangeOrderTypeThunk(orderId: string, newOrderType: AlgoOrderType): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    const updatePropertied = getUpdatedProperties(newOrderType);
    dispatch(ac.blotterEditedOrderPropertiesChanged(orderId, updatePropertied));
  };
}

type ResetProperties = Pick<
  BlotterOrderCommonInputs & BlotterOrderAlgoInputs,
  'product' | 'alphaSeeker' | 'clippingMode' | 'clipSize' | 'liquidityPool' | 'randomize' | 'speed' | 'spreadCapture'
>;

const getUpdatedProperties = (newOrderType: AlgoOrderType): ResetProperties => {
  const resetProperties: ResetProperties = {
    product: newOrderType,
    alphaSeeker: false,
    clippingMode: 'Automatic',
    clipSize: null,
    liquidityPool: getOrderLiquidityPoolOptions(newOrderType),
    randomize: true,
    speed: 'Normal',
    spreadCapture: true,
  };
  switch (newOrderType) {
    case 'Falcon':
      return {
        ...resetProperties,
        speed: 'Normal',
        liquidityPool: ['Markets'],
      };
    case 'Nightjar':
      return {
        ...resetProperties,
        alphaSeeker: false,
        speed: 'Normal',
      };
    case 'Twap':
      return {
        ...resetProperties,
        clippingMode: 'Automatic',
        spreadCapture: true,
        randomize: true,
      };
    default:
      assertUnreachable(newOrderType, 'Changing an order to this type is not allowed');
  }
};

export function blotterOrderPropertyChangedTakeProfitMarginThunk(
  quoteId: string,
  patch: Partial<BlotterOrderInputs>,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.blotterEditedOrderPropertiesChanged(quoteId, { ...patch }));
  };
}

export function blotterEditedOrderPropertyChangedTakeProfitMarginThunk(
  quoteId: string,
  patch: Partial<BlotterOrderInputs>,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.blotterEditedOrderPropertiesChanged(quoteId, { ...patch }));
  };
}
