import type { Trigger } from '../fxOrders/fxOrdersModel';
import type { Way } from '../share/productModel/litterals';

export type FxOrderBulksState = {
  orderIds: string[];
  orderById: Record<string, StopLossOrder>;
  orderByBdrId: StopLossByBdrid;
};

export type BdrId = string;
export type CurrencyPair = string;

export type StopLossByBdrid = Record<BdrId, StopLossByCurrencyPair>;
export type StopLossByCurrencyPair = Record<CurrencyPair, StopLossOrder[]>;

export type StopLossOrder = {
  id: string;
  omsId: string | undefined;
  currencyPair: CurrencyPair;
  bdrId: BdrId;
  way: Way;
  notional: number;
  currency: string; // currency one or two
  currencyIndex: 1 | 2;
  limitPrice: number;
  expiryDate: Date | undefined; // undefined = "GTC"
  trigger: Trigger;
  notificationEmails: string[];

  status: StopLossOrderStatus;

  errors: StopLossOrderError[];
};

export enum StopLossOrderStatus {
  IDLE = 0,
  VALIDATING = 1,
  VALID = 2,
  INVALID = 3,
  PENDING = 4,
  SUCCESS = 5,
  FAILED = 6,
}

export type StopLossOrderError = {
  field?: StopLossOrderFieldName;
  message: string;
  code: number;
};

export enum StopLossOrderFieldName {
  id = 'id',
  currencyPair = 'currencyPair',
  bdrId = 'bdrId',
  way = 'way',
  notional = 'notional',
  currency = 'currency',
  limitPrice = 'limitPrice',
  expiryDate = 'expiryDate',
  trigger = 'trigger',
  notificationEmails = 'notificationEmails',
}

/*
  Actions to create a new order
  - CLIENTWORKSPACE_NEW_TILE_ADDED -> workspace and tile IDs
  - ORDER_PROPERTY_CHANGED -> patch with inputs
  - ORDER_VALIDATION_REQUESTED -> change the orderStatus
  - ESP_TIERING_REQUEST_EPIC -> start listening ESP
  - ESP_STREAM_KEY_REQUEST_PENDING
  - ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED -> update espStreams + currentEspStreamId in the order
  - ORDER_VALIDATION_RECEIVED -> reset inputs and updates values + reset orderStatus and set clockOffset

  Actions to change notional
  - ORDER_PROPERTY_CHANGED -> update order inputs + some data in espStreams
  - ORDER_VALIDATION_REQUESTED -> change the orderStatus
  - ORDER_VALIDATION_RECEIVED -> reset inputs and updates values + reset orderStatus and set clockOffset

 */

export const createInitialOrderBulksState = (): FxOrderBulksState => ({
  orderIds: [],
  orderById: {},
  orderByBdrId: {},
});
