import { isDefined, isEmpty, isNonEmpty, isNotDefined } from '@sgme/fp';
import { pick } from 'utils/object';
import type { FxOptionLegState } from '../../../../state/fxOptions/model/optionsLegs';
import { isFxOptionMultileg } from '../../../../state/fxOptions/utilities';

export interface NestedLegs {
  [key: string]: any;
  legs?: Record<string, NestedLegs>;
}
export interface FlattenLegs {
  productName: string;
  legIds?: string[];
  [key: string]: any;
}

// TODO: update with typings and refacto for human readable
// TODO: merge flattent and unflatten legs in one place
export function unflattenObject(
  allLegs: Record<string, FlattenLegs>,
  nestedLegs: NestedLegs = {},
  parentId = '',
  flattenLegs: Record<string, FlattenLegs> = {},
) {
  const legForIteration = !isEmptyObject(flattenLegs) ? flattenLegs : allLegs;

  for (const [legId, leg] of Object.entries(legForIteration)) {
    if (findPath(nestedLegs, legId)) {
      return nestedLegs;
    }

    // get values
    const { legIds, ...rest } = leg;

    // when we have no legIds on FxOptionLeg, we reinitialise product with one vanilla leg
    if (isFxOptionMultileg(leg as FxOptionLegState) && (isNotDefined(leg?.legIds) || isEmpty(leg?.legIds))) {
      nestedLegs[legId] = {
        ...rest,
        legs: {
          [`${legId}/0`]: {
            productName: 'Vanilla',
          },
        },
      };
      return nestedLegs;
    }

    // test if children or not
    if (isDefined(legIds) && isNonEmpty(legIds)) {
      // I have children
      const pickedLegs = pick(...legIds)(allLegs);

      if (parentId) {
        // typedStrategy - stranggle
        nestedLegs[parentId].legs[legId] = { ...rest, legs: {} };
      } else {
        // initiliasation de l'objet FxOptionMultileg
        nestedLegs[legId] = { ...rest, legs: {} };
      }

      nestedLegs = unflattenObject(allLegs, nestedLegs, legId, pickedLegs);
    } else if (parentId) {
      // I am the children
      const foundPath = findPath(nestedLegs, parentId);
      const path = foundPath ? `${foundPath}.${parentId}.legs` : `${parentId}.legs`;

      nestedLegs = updateValueOnTargetPath(nestedLegs, path.split('.'), {
        [legId]: { ...rest },
      });
    }
  }

  return nestedLegs;
}

export function isEmptyObject(obj: Record<string, unknown>): obj is Record<never, unknown> {
  return Object.keys(obj).length === 0;
}

/**
 * Find path in object from target key
 * Return result as a dot notation of nested path
 * The first matching result is return
 * Ex: for key "xxx-xxx-xxx/0/0/call" => "xxx-xxx-xxx.legs.0.legs.0.legs"
 * @param ob
 * @param key
 * @returns
 * TODO: update with typings and refacto for human readable
 */
export function findPath<T>(ob: T, key: string) {
  const path: string[] = [];
  const keyExists = (obj: string | any[] | T | any): boolean => {
    if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
      return false;
    } else if ({}.hasOwnProperty.call(obj, key)) {
      return true;
    } else if (Array.isArray(obj)) {
      const parentKey = path.length ? path.pop() : '';

      for (let i = 0; i < obj.length; i++) {
        path.push(`${parentKey}[${i}]`);
        const result = keyExists(obj[i]);
        if (result) {
          return result;
        }
        path.pop();
      }
    } else {
      for (const k in obj) {
        if ({}.hasOwnProperty.call(obj, k)) {
          path.push(k);
          const result = keyExists(obj[k]);
          if (result) {
            return result;
          }
          path.pop();
        }
      }
    }
    return false;
  };

  keyExists(ob);

  return path.join('.');
}

/**
 * Update value on target path for nested object.
 * Allow update deep object with new object
 * @param original
 * @param keys A path split inside an array (Ex: "xxx-xxx-xxx.legs.0.legs.0.legs" => ["xxx-xxx-xxx","legs","0","legs","0","legs"]) see findPath() before
 * @param value
 * @returns
 */
export function updateValueOnTargetPath(
  original: Record<string, any>,
  keys: string[],
  value: Record<string, any>,
): Record<string, any> {
  if (isEmpty(keys)) {
    return { ...original, ...value };
  }
  const currentKey = keys[0];
  /* is it possible to be an array ?
  if (Array.isArray(original)) {
    return original.map((v, index) =>
      index === currentKey
        ? deepUpdate(v, keys.slice(1), value) // (A)
        : v,
    ); // (B)
  } else */
  if (typeof original === 'object' && isDefined(original)) {
    return Object.fromEntries(
      Object.entries(original).map((keyValuePair) => {
        const [nestedKey, nestedValue] = keyValuePair;
        if (nestedKey === currentKey) {
          // Keep going deeper
          return [nestedKey, updateValueOnTargetPath(nestedValue, keys.slice(1), value)]; // (C)
        } else {
          // It's another property string or number
          return keyValuePair; // (D)
        }
      }),
    );
  } else {
    // Primitive value
    return original;
  }
}

/**
 * Iterate through all property and update target state leg key value
 * This update is done only on targetKeys -> avoid
 * Allow to update "xxx-xxx-xxx/0/0/call" => "call"
 * Regex is apply to keep only the value on right of the last "/" charactere
 * @param obj
 * @param targetKeys
 * @returns
 */
export function updateStateLegKeyToTradeCaptureLegKey(obj: Record<string, NestedLegs>, targetKeys: string[]) {
  const keyValues = Object.keys(obj).map((key) => {
    let newKey = key;

    if (targetKeys.includes(key)) {
      const execArray = /([^/]+)$/.exec(key);

      if (isDefined(execArray) && isNonEmpty(execArray)) {
        newKey = execArray[0];
      }
    }

    const legs = obj[key]?.legs;
    if (isDefined(legs)) {
      obj[key].legs = updateStateLegKeyToTradeCaptureLegKey(legs, targetKeys);
    }

    return {
      [newKey]: obj[key],
    };
  });

  return Object.assign({}, ...keyValues);
}

/**
 * Merge two object in one object
 * Obj1 will be overwrite by Obj2 on same keys.
 * Just work on the root keys.
 * If you merge concern a nested key, you need to create the whole object
 * @param obj1
 * @param obj2
 * @returns
 */
export function mergeValues<LeftObject, RightObject>(
  obj1: Record<string, LeftObject>,
  obj2: Record<string, RightObject>,
): Record<string, LeftObject & RightObject> {
  const allKeys = [...new Set([...Object.keys(obj1), ...Object.keys(obj2)])];

  return allKeys.reduce((acc: Record<string, LeftObject & RightObject>, key) => {
    acc[key] = {
      ...obj1[key],
      ...obj2[key],
    };

    return acc;
  }, {});
}
