import { type MouseEvent, type ReactNode, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { FormattedMessage } from 'react-intl';

export interface FileImportInputProps {
  file: File | undefined;
  onFileChange: (file: File | undefined) => void;
}

const ImportFileLabel = styled.label`
  top: 0;
  left: 0;
`;

/**
 * Unit dividers
 * @description Keep sorted from biggest to smallest
 */
const unitDividers: Record<string, number> = {
  megabyte: 1024 ** 2,
  kilobyte: 1024,
  byte: 1,
};
const unitDividersArray = Object.entries(unitDividers);

const FileSize: React.FunctionComponent<{ size: number }> = ({ size }) => {
  const [unit, divider] = unitDividersArray.filter(([, lowerBound]) => size > lowerBound - 1)[0];
  return (
    <span className="text-end">
      {(size / divider).toFixed(2)} <FormattedMessage id={`import.fileSize.unit.${unit}`} />
    </span>
  );
};

const selectValues = {
  select: (select: ReactNode) => (
    <label htmlFor="bulk_file" className="text-info">
      {select}
    </label>
  ),
};

const FileUpload: React.FunctionComponent<{
  onFileChange: (file: File | undefined) => void;
}> = ({ onFileChange }) => {
  const [dragging, setDragging] = useState(false);

  const onDrag = useCallback(() => setDragging(true), [setDragging]);
  const onUndrag = useCallback(() => setDragging(false), [setDragging]);

  const onChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      onFileChange(e.currentTarget.files?.item(0) ?? undefined);
    },
    [onFileChange],
  );

  const onDrop = useCallback(
    (e: React.DragEvent) => {
      e.preventDefault();
      onUndrag();
      onFileChange(e.dataTransfer.files?.item(0) ?? undefined);
    },
    [onUndrag, onFileChange],
  );

  // Prevents dropping on document
  const preventDrop = (event: Event) => event.preventDefault();

  useEffect(() => {
    document.addEventListener('dragover', preventDrop);
    document.addEventListener('drop', preventDrop);

    return () => {
      document.removeEventListener('dragover', preventDrop);
      document.removeEventListener('drop', preventDrop);
    };
  });

  const forceToOpenOSFileImporter = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
  };

  return (
    <div
      className={`position-relative d-block${dragging ? ' bg-lvl2' : ''}`}
      onClick={forceToOpenOSFileImporter}
    >
      <div className="upload-squares" />
      <div className="upload-inner p-3 text-center d-flex align-items-center justify-content-around">
        <i className="icon icon-xl text-light mx-2">attach_file</i>
        <p className="text-secondary m-0 line-height-1">
          <FormattedMessage id="modal.import.dragOrSelect" values={selectValues} />
        </p>
      </div>
      <ImportFileLabel
        className="position-absolute h-100 w-100 cursor-pointer"
        htmlFor="file"
        onDragEnter={onDrag}
        onDragLeave={onUndrag}
        onDragEnd={onUndrag}
        onDrop={onDrop}
      />
      <input
        className="invisible"
        type="file"
        id="file"
        name="file"
        accept=".csv,.tsv"
        data-e2e="select-file"
        onChange={onChange}
      />
      <div className="upload-squares" />
    </div>
  );
};

const FileUploaded: React.FunctionComponent<{
  file: File;
  reset: () => void;
}> = ({ file, reset }) => (
  <div className="bg-lvl2 py-4 d-flex align-items-center">
    <svg
      className="text-info px-4"
      height="48"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 41.817 50"
    >
      <g transform="translate(-878 -148)">
        <path
          fill="currentColor"
          stroke="currentColor"
          strokeWidth="2px"
          d="M0,2.515V48H39.817V16.4L23.415,0H0Z"
          transform="translate(879 149)"
        />
        <g transform="translate(887 163)">
          <path fill="none" d="M0,0H24V24H0Z" />
          <path fill="#fff" d="M9,16.17,4.83,12,3.41,13.41,9,19,21,7,19.59,5.59Z" />
        </g>
      </g>
    </svg>
    <div className="ps-3 flex-grow-1 text-info">
      <span className="fw-medium">{file.name}</span>
      <br />
      <FileSize size={file.size} />
    </div>
    <button className="btn btn-lg btn-flat-light mx-4" onClick={reset}>
      <i className="icon">close</i>
    </button>
  </div>
);

export const FileImportInput: React.FunctionComponent<FileImportInputProps> = ({
  file,
  onFileChange,
}) => {
  const reset = () => onFileChange(undefined);

  return file === undefined ? (
    <FileUpload onFileChange={onFileChange} />
  ) : (
    <FileUploaded file={file} reset={reset} />
  );
};
