import type { Thunk } from 'state';
import type { QuoteContributionType } from '../../share/productModel/litterals';
import type { IHedge, IQuote } from '../model/fxAmericanForwardStreamModel';

export function americanForwardRequestedThunk(
  americanForwardId: string,
  forceTradable: boolean,
  bypassCheck: boolean,
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac }) => {
    dispatch(ac.americanForwardRfsCancelThunk(americanForwardId))
    dispatch(ac.americanForwardStreamRequested(americanForwardId, forceTradable, bypassCheck))
  }
}


export function americanForwardStreamRefreshThunk(
  americanForwardId: string,
  isReadyToPrice: boolean | null,
  isPriceObsolete: boolean,
  isProductObsolete: boolean,
): Thunk<void> {
  return (dispatch, getState, { actionCreators: ac, selectors: sl }) => {
    const state = getState();
    const currentStreamId = sl.getTileCurrentStreamId(state, americanForwardId);

    // Ici la logique pour request RFS avec solving de premium ou de forward rate

    if (currentStreamId !== null) {
      if (isReadyToPrice && !isPriceObsolete && !isProductObsolete) {
        // When TC replies ready to price but price/product aren't obsolete, we do nothing
        return;
      }

      // TODO ABO, american Forward, we already have this thunks/tradeCapture
      // if (isProductObsolete) {
      //   dispatch(ac.americanForwardRfsCancelThunk(americanForwardId, false));
      // }
    }

    const isObsolete = !isPriceObsolete || isProductObsolete;
    const isValidDate = sl.isValidDateAmericanForward(state, americanForwardId);

    if (isReadyToPrice && isObsolete && isValidDate) {
      dispatch(ac.americanForwardStreamRequested(americanForwardId, false, false));
    }
  };
}

export function americanForwardTraderNotificationReceivedThunk(
  americanForwardId: string,
  streamId: string,
  traderId: string | null,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isQuotePresentWithInstrument(state, americanForwardId, 'AmericanForward')) {
      return;
    }

    const lastStreamId = sl.getAmericanForwardLastStreamId(state, americanForwardId);
    if (lastStreamId !== null) {
      dispatch(ac.americanForwardStreamLastRemoved(americanForwardId, lastStreamId));
    }
    dispatch(ac.americanForwardTraderNotificationReceived(americanForwardId, streamId, traderId, quoteContribution));
  };
}

export function americanForwardRfsCancelThunk(americanForwardId: string, forceKeepExpired?: false): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const currentStreamId = sl.getTileCurrentStreamId(state, americanForwardId);

    if (currentStreamId !== null) {
      const shouldKeepPrice =
        forceKeepExpired !== undefined
          ? forceKeepExpired
          : sl.getAmericanForwardStreamStatus(state, currentStreamId) === 'PRICING';

      dispatch(ac.americanForwardStreamCanceled(americanForwardId, currentStreamId, shouldKeepPrice));
    }

    dispatch(ac.americanForwardRfsClearError(americanForwardId));
  };
}

export function americanForwardQuoteReceivedThunk(
  americanForwardId: string,
  streamId: string,
  quote: IQuote<readonly IHedge[]>,
  tiering: string | null,
  bypassCheck: boolean,
  quoteContribution: QuoteContributionType | null,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    if (!sl.isQuotePresentWithInstrument(state, americanForwardId, 'AmericanForward')) {
      return;
    }

    const lastStreamId = sl.getAmericanForwardLastStreamId(state, americanForwardId);
    if (lastStreamId !== null) {
      dispatch(ac.americanForwardStreamLastRemoved(americanForwardId, lastStreamId));
    }

    dispatch(
      ac.americanForwardQuoteReceived(americanForwardId, streamId, quote, tiering, bypassCheck, quoteContribution),
    );
  };
}
