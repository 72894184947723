import {
  type ComponentProps,
  type FunctionComponent,
  type MouseEvent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import { useIntl } from 'react-intl';
import DatePicker, { registerLocale } from 'react-datepicker';
import { format, isWeekend, isValid, parseISO, getYear } from 'date-fns';
import { enUS, fr, de } from 'date-fns/locale';
import { Tenors, type ITenorsProps } from './Tenors';
import { formatDateWithLocaleAndFormat } from 'utils/dateFormats';
import './DatePickerWrapper.scss';
import { isEmpty } from '@sgme/fp';

registerLocale('en', enUS);
registerLocale('fr', fr);
registerLocale('de', de);

const DATE_FORMAT_ISO = 'yyyy-MM-dd';

interface IDatePickerProps {
  date: string;
  closedDates?: readonly string[];
  disabled?: boolean;
  onDatePickerRef(reference: DatePicker | null): void;
  onSelect(date: string): void;
  onTenorPick?(tenor: string): void;
  onClose?(): void;
  onOpen?(): void;
  maxTenor?: string | undefined;
  maxDate: Date | undefined;
  placement?: 'top' | 'bottom' | 'left' | 'right';
}

export type DatePickerWithTenorsProps = IDatePickerProps & ITenorsProps;

type RenderCustomHeader = ComponentProps<typeof DatePicker>['renderCustomHeader'];

const headerClass =
  'd-flex justify-content-between align-items-center text-secondary text-uppercase fw-bold';

const HeaderButton = styled.button.attrs({ type: 'button', className: 'px-1' })`
  border: 0;
  background-color: transparent;
  font-size: 18px;
  color: var(--bs-primary);
`;

const DatePickerHeader: RenderCustomHeader = ({
  date,
  decreaseMonth,
  increaseMonth,
  changeYear,
}) => {
  const currentYear = getYear(date);
  const increaseYear = () => changeYear(currentYear + 1);
  const decreaseYear = () => changeYear(currentYear - 1);
  return (
    <div className={headerClass}>
      <span>
        <HeaderButton onClick={decreaseYear}>«</HeaderButton>
        <HeaderButton onClick={decreaseMonth}>‹</HeaderButton>
      </span>
      <CurrentMonth date={date} />
      <span>
        <HeaderButton onClick={increaseMonth}>›</HeaderButton>
        <HeaderButton onClick={increaseYear}>»</HeaderButton>
      </span>
    </div>
  );
};

const CurrentMonth: FunctionComponent<{ date: Date }> = ({ date }) => {
  const { locale } = useIntl();
  return (
    <span className="pt-1 text-primary">
      {formatDateWithLocaleAndFormat(locale)('MMMM yyyy')(date)}
    </span>
  );
};
export function DatePickerWithTenors({
  closedDates = [],
  onTenorPick,
  onSelect,
  onDatePickerRef,
  onOpen,
  onClose,
  disabled,
  currentTenor,
  tenors,
  maxTenor,
  maxDate,
  date: dateString,
  placement = 'bottom'
}: DatePickerWithTenorsProps) {
  const { locale } = useIntl();

  const [self, setSelf] = useState<DatePicker | null>(null);

  const ref = (reference: DatePicker | null) => {
    setSelf(reference);
    onDatePickerRef(reference);
    if (reference !== null) {
      (reference as any).setOpen = withOpenStatusCallback(onOpen, onClose)(reference.setOpen);
    }
  };

  const onTenorSelect = useCallback(
    (data: string) => {
      if (self !== null) {
        self.setOpen(false);
      }
      if (onTenorPick !== undefined) {
        onTenorPick(data);
      } else {
        onSelect(data);
      }
    },
    [self, onTenorPick, onSelect],
  );

  const onDateSelect = (selectedDate: Date) => {
    onSelect(format(new Date(selectedDate), DATE_FORMAT_ISO));
  };

  const isOpenDate = useCallback(
    (possiblyOpenDate: Date): boolean => {
      if (!isValid(possiblyOpenDate)) {
        return false;
      }
      return (
        !isWeekend(possiblyOpenDate) &&
        (closedDates === undefined ||
          !closedDates.includes(format(possiblyOpenDate, DATE_FORMAT_ISO)))
      );
    },
    [closedDates],
  );

  const handleClickOutside = useCallback(() => {
    self?.setOpen(false);
  }, [self]);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [handleClickOutside]);

  const stopPropagation = useCallback((e: MouseEvent<HTMLDivElement>) => e.stopPropagation(), []);
  const date = dateString === '' ? null : parseISO(dateString);

  return (
    <div onClick={stopPropagation}>
      <DatePicker
        ref={ref}
        selected={isValid(date) ? date : null}
        locale={locale}
        dateFormat={DATE_FORMAT_ISO}
        fixedHeight
        disabled={disabled}
        readOnly={disabled}
        calendarClassName={`panel panel-default calendar${isEmpty(tenors) ? ' calendarWithoutTenors' : ''}`}
        customInput={<></>}
        renderCustomHeader={DatePickerHeader}
        onChange={onDateSelect}
        filterDate={isOpenDate}
        popperPlacement={placement}
        maxDate={maxDate}
        popperModifiers={[{ name: 'preventOverflow', enabled: true }]}
      >
        <Tenors
          currentTenor={currentTenor}
          tenors={tenors}
          onSelect={onTenorSelect}
          maxTenor={maxTenor}
        />
      </DatePicker>
    </div>
  );
}

export const DatePickerWrapper = DatePickerWithTenors;

/**
 * the date picker does not expose callback props when "open" status changes
 * while wrapping the component, we "decorate" the setOpen method exposed by the instance
 * this is functioning because it's the same setOpen method used internally in the date picker source code
 * so we can spy internal call of setOpen by the date picker and catch close event triggerd by
 * - click on date
 * - click outside
 * - click on tenor
 */
function withOpenStatusCallback(onOpen?: () => void, onClose?: () => void) {
  return function decorateMethod(inner: DatePicker['setOpen']): DatePicker['setOpen'] {
    return function setOpen(this: DatePicker, open, skipSetBlur) {
      if (open === true && onOpen !== undefined) {
        onOpen();
      }
      if (open === false && onClose !== undefined) {
        onClose();
      }
      inner.call(this, open, skipSetBlur);
    };
  };
}
