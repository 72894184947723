import { getSharedProperty, getValueProperty, type SharedKey, type ValueKey } from 'state/share/form';
import type {
  FxAmericanForwardInputs as AmericanForwardInputs,
  FxAmericanForwardValues as AmericanForwardValues,
  IFxAmericanForwardMetadata,
} from '../model/fxAmericanForwardProductModel';
import { getAmericanForwardState } from './selectors';
import type { AppState } from 'state/model';

export type AmericanForwardMetadataStatus<T extends keyof IFxAmericanForwardMetadata> = IFxAmericanForwardMetadata[T];

export function getAmericanForwardMetadata<T extends keyof IFxAmericanForwardMetadata>(property: T) {
  return (state: AppState, americanForwardId: string): AmericanForwardMetadataStatus<T> =>
    getAmericanForwardState(state, americanForwardId)[property];
}

// common AmericanForward value
type AmericanForwardValuePropertyKey = ValueKey<AmericanForwardValues, AmericanForwardInputs>;

export function getAmericanForwardValue<T extends AmericanForwardValuePropertyKey>(prop: T) {
  const get = getValueProperty<AmericanForwardValues, AmericanForwardInputs, T>(prop);
  return (state: AppState, americanForwardId: string) => get(getAmericanForwardState(state, americanForwardId));
}

// common AmericanForward input
type AmericanForwardSharedPropertyKey = SharedKey<AmericanForwardValues, AmericanForwardInputs>;

export function getAmericanForwardInput<T extends AmericanForwardSharedPropertyKey>(prop: T) {
  const get = getSharedProperty<AmericanForwardValues, AmericanForwardInputs, T>(prop);
  return (state: AppState, americanForwardId: string) => get(getAmericanForwardState(state, americanForwardId));
}
