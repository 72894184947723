import type { TradeCaptureError } from '../../tile/fxTileModel';
import type { FxAmericanForwardPatch } from '../model/fxAmericanForwardProductModel';
import type { RestoredTiles } from '../../clientWorkspace';
import type { AmericanForwardSavedTile } from '../../../api/workspaceService/updaters/updater9/model';

export interface AmericanForwardTileRestoreEpic {
  type: 'AMERICAN_FORWARD_TILE_RESTORE_EPIC';
  tiles: RestoredTiles<AmericanForwardSavedTile>;
}

export function americanForwardTileRestoreEpic(
  tiles: RestoredTiles<AmericanForwardSavedTile>,
): AmericanForwardTileRestoreEpic {
  return {
    type: 'AMERICAN_FORWARD_TILE_RESTORE_EPIC',
    tiles,
  };
}

export interface AmericanForwardPropertiesRequested {
  type: 'AMERICAN_FORWARD_PROPERTIES_REQUESTED';
  quoteId: string;
  sessionId: string;
}

export function americanForwardPropertiesRequested(
  quoteId: string,
  sessionId: string,
): AmericanForwardPropertiesRequested {
  return {
    type: 'AMERICAN_FORWARD_PROPERTIES_REQUESTED',
    quoteId,
    sessionId,
  };
}

export interface AmericanForwardPropertiesRequestFailed {
  type: 'AMERICAN_FORWARD_PROPERTIES_REQUEST_FAILED';
  quoteId: string;
  error: TradeCaptureError;
}

export function americanForwardPropertiesRequestFailed(
  quoteId: string,
  error: TradeCaptureError,
): AmericanForwardPropertiesRequestFailed {
  return {
    type: 'AMERICAN_FORWARD_PROPERTIES_REQUEST_FAILED',
    quoteId,
    error,
  };
}

export interface AmericanForwardPropertiesReceived {
  type: 'AMERICAN_FORWARD_PROPERTIES_RECEIVED';
  quoteId: string;
  patch: FxAmericanForwardPatch;
  isReadyToPrice: boolean | null;
  isPriceObsolete: boolean;
  isProductObsolete: boolean;
  idVersion: number;
}

export function americanForwardPropertiesReceived(
  quoteId: string,
  patch: FxAmericanForwardPatch,
  idVersion: number,
  isReadyToPrice: boolean | null,
  isPriceObsolete = false,
  isProductObsolete = false,
): AmericanForwardPropertiesReceived {
  return {
    type: 'AMERICAN_FORWARD_PROPERTIES_RECEIVED',
    quoteId,
    patch,
    isReadyToPrice,
    isPriceObsolete,
    isProductObsolete,
    idVersion,
  };
}
