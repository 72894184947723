import { type Draft, type PayloadAction, createSlice } from '@reduxjs/toolkit';
import { mapWithKey } from 'utils/object/map';
import { createRecord, groupBy } from '../../utils/array';
import {
  type FxOrderBulksState,
  type StopLossByBdrid,
  type StopLossOrder,
  type StopLossOrderError,
  StopLossOrderFieldName,
  StopLossOrderStatus,
  createInitialOrderBulksState,
} from './fxOrderBulkModel';
import { sortByBrdAndCurrencyPair } from './fxOrderBulksUtilities';

export const fxOrderBulksSlice = createSlice({
  name: 'fxOrderBulks',
  initialState: createInitialOrderBulksState(),

  reducers: {
    stopLossOrdersLoaded(state: Draft<FxOrderBulksState>, action: PayloadAction<{ stopLosses: StopLossOrder[] }>) {
      const allStopLosses = action.payload.stopLosses;
      const orderByBdrId = groupStopLossOrders(allStopLosses);

      const allSortedStopLosses = Object.values(orderByBdrId)
        .flatMap((orderByCurrencyPair) => Object.values(orderByCurrencyPair).flat())
        .sort(sortByBrdAndCurrencyPair);

      state.orderIds = allSortedStopLosses.map((stopLoss) => stopLoss.id);

      state.orderById = createRecord(allStopLosses, (stopLoss) => stopLoss.id);

      state.orderByBdrId = orderByBdrId;
    },

    stopLossOrderStatusChanged(
      state: Draft<FxOrderBulksState>,
      action: PayloadAction<{ orderId: string; status: Exclude<StopLossOrderStatus, StopLossOrderStatus.INVALID> }>,
    ) {
      const order = state.orderById[action.payload.orderId];

      if (order === undefined) {
        return;
      }

      order.status = action.payload.status;
    },

    stopLossOrderInvalid(
      state: Draft<FxOrderBulksState>,
      action: PayloadAction<{ orderId: string; errors: StopLossOrderError[] }>,
    ) {
      const order = state.orderById[action.payload.orderId];

      if (order === undefined) {
        return;
      }

      order.status = StopLossOrderStatus.INVALID;
      order.errors = action.payload.errors.map(transformError);
    },

    stopLossOrderSuccess(state: Draft<FxOrderBulksState>, action: PayloadAction<{ orderId: string; omsId: string }>) {
      const order = state.orderById[action.payload.orderId];

      if (order === undefined) {
        return;
      }

      order.status = StopLossOrderStatus.SUCCESS;
      order.omsId = action.payload.omsId;
    },
  },
});

export const { stopLossOrdersLoaded, stopLossOrderStatusChanged, stopLossOrderInvalid, stopLossOrderSuccess } =
  fxOrderBulksSlice.actions;

const groupStopLossOrders = (allStopLosses: StopLossOrder[]): StopLossByBdrid => {
  const stopLossByBdrid = groupBy(allStopLosses, (stopLoss) => stopLoss.bdrId);

  return mapWithKey(stopLossByBdrid, (group) => groupBy(group, (stopLoss) => stopLoss.currencyPair));
};

const transformError = (error: any) => {
  return {
    ...error,
    field: FIELD_NAME_MAPPING[error.field] ?? error.field,
  };
};

const FIELD_NAME_MAPPING: Record<string, StopLossOrderFieldName> = {
  currencyPair: StopLossOrderFieldName.currencyPair,
  bdrId: StopLossOrderFieldName.bdrId,
  way: StopLossOrderFieldName.way,
  amountInCcy1: StopLossOrderFieldName.notional,
  amountInCcy2: StopLossOrderFieldName.notional,
  limitPrice: StopLossOrderFieldName.limitPrice,
  expiryDay: StopLossOrderFieldName.expiryDate,
  trigger: StopLossOrderFieldName.trigger,
  notificationEmails: StopLossOrderFieldName.notificationEmails,
};

export type AllFxOrderBulkActions = typeof fxOrderBulksSlice.actions;
export type FxOrderBulkAction = AllFxOrderBulkActions[keyof AllFxOrderBulkActions];
