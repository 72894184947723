import { isDefined, isNotDefined } from '@sgme/fp';
import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import type { PropertyErrors } from 'state/share/productModel/litterals';
import { validationPartialReducer } from 'state/share/validationReducer';
import { clearUndefined } from 'utils/clearUndefined';
import { strictEntries } from 'utils/object';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import type { BlotterEditedOrderValidationReceived } from '../../blotter/action';
import type { BlotterOrderLimitValues, OrderBlotterLimitEntry } from '../../blotter/blotterEntryModel';
import type { OrderPropertyChanged, OrderValidationReceived } from '../fxOrdersActions';
import {
  type FxOrderState,
  type FxOrderStateMap,
  type IFxOrderInputs,
  type IFxOrderMetadata,
  type IFxOrderProperties,
  type IFxOrderValues,
  type OrderType,
  emptyFxOrderState,
  getOrderLiquidityPoolOptions,
} from '../fxOrdersModel';

const orderValidationReducer = validationPartialReducer<'Order', FxOrderState>('Order');

export const ordersReducer: Reducer<FxOrderStateMap> = (
  state: FxOrderStateMap = {},
  action: Action,
): FxOrderStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_NEW_TILE_ADDED':
      if (action.instrument === 'Order') {
        return addKey(state, action.tileId, emptyFxOrderState);
      }
      return state;

    case 'ORDER_PROPERTY_CHANGED':
      return updateKey(state, action.quoteId, (order) => ({
        inputs: {
          ...order.inputs,
          ...action.patch,
          ...prepareCommonResetFieldsAfterTypeChange(action.patch.type),
          ...prepareSpecificResetFieldsAfterTypeChangePatch(action.patch.type),
          ...prepareResetExpiryPatch(action.patch.startDate),
          ...prepareFixingPatch(action, order),
        },
      }));

    case 'ORDER_PROPERTY_CHANGED_FROM_TEMPLATE':
      return updateKey(state, action.quoteId, (order) => ({
        inputs: {
          ...order.inputs,
          ...action.patch,
        },
      }));

    case 'ORDER_TEMPLATE_CHANGED':
      return updateKey(state, action.quoteId, (_order) => ({
        currentTemplateId: action.templateId,
      }));

    case 'CLIENTWORKSPACE_TILE_DELETED':
      return removeKey(state, action.tileId);

    case 'ORDER_AMOUNT_CURRENCY_CHANGED':
      return updateKey(state, action.quoteId, (order) => ({
        values: {
          ...order.values,
          amountCurrency: action.currencyChoice,
        },
      }));

    case 'ORDER_VALIDATION_REQUESTED':
      return updateKey(state, action.quoteId, (order) => ({
        ...order,
        orderStatus: 'PropertiesRequested',
      }));

    case 'ORDER_VALIDATION_RECEIVED':
      return updateKey(state, action.quoteId, (order) => {
        let { currency1, currency2 } = order.values;

        const { currencyPair } = order.inputs;

        if (isDefined(currencyPair)) {
          [currency1, currency2] = currencyPair.split('/');
        }

        const isFixingOrder = order.inputs.type === 'Fixing' || order.values.type === 'Fixing';
        const isTakeProfitOrder = order.inputs.type === 'TakeProfit' || order.values.type === 'TakeProfit';

        const initialPatch: IFxOrderProperties & IFxOrderMetadata = {
          values: {
            ...order.values,
            ...mapOrderInputsToValues(order.inputs),
            currency1,
            currency2,
            ...mapValuesFromActionIfValidated(action, isTakeProfitOrder),
            ...(isFixingOrder ? getFixingOrderValues(action, order) : {}),
          },
          inputs: {},
          errors: {},
          warnings: order.warnings,
          isReadyToSubmit: action.isReadyToSubmit,
          editingEmails: false,
          isStartDateNow: order.isStartDateNow,
          orderStatus: null,
          propertiesRequestError: null,
          currentExecutionId: null,
          currentEspStreamId: order.currentEspStreamId,
          clockOffset: action.clockOffset,
        };

        const patch = action.validationDetails.reduce((acc, { code, description, field }) => {
          const errors: PropertyErrors<IFxOrderValues> = {
            ...acc.errors,
            [field]: {
              code,
              description,
              userNotified: false,
              faultyValue: null,
            },
          };
          return { ...acc, errors };
        }, initialPatch);

        return patch;
      });
    case 'ORDER_VALIDATION_FAILED':
      return updateKey(state, action.quoteId, (order) => ({
        ...order,
        propertiesRequested: false,
        propertiesRequestError: action.error,
        orderStatus: 'PropertiesError',
      }));

    case 'ORDER_EDITING_EMAILS':
      return updateKey(state, action.quoteId, () => ({
        editingEmails: action.editingEmails,
      }));

    case 'ORDER_START_DATE_NOW_CHANGED':
      return updateKey(state, action.quoteId, () => ({
        isStartDateNow: action.isStartDateNow,
      }));

    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'Order'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));

    case 'ORDER_SUBMISSION_SENT':
      return updateKey(state, action.quoteId, () => ({
        orderStatus: 'Pending',
        warnings: {},
      }));

    case 'ORDER_SUBMISSION_SUCCESS':
      if (action.orderId === null) {
        return updateKey(state, action.quoteId, () => ({
          orderStatus: 'Success',
        }));
      }

      return removeKey(state, action.quoteId);

    case 'ORDER_SUBMISSION_FAILED':
      return updateKey(state, action.quoteId, () => ({
        orderStatus: 'Failed',
        propertiesRequestError: action.errorCode,
      }));

    case 'ORDER_ERROR_DISMISSED':
      return updateKey(state, action.quoteId, () => ({
        orderStatus: null,
        propertiesRequestError: null,
      }));

    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
        orderStatus: null,
      }));

    case 'ESP_TILE_STREAM_ID_AND_REFCOUNT_UPDATED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamId,
      }));

    case 'ESP_STREAM_RECONNECTED':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: action.streamKey,
      }));

    case 'ESP_STREAM_TILE_UNSUBSCRIBE':
    case 'ESP_STREAM_KEY_REQUEST_PENDING':
      return updateKey(state, action.tileId, () => ({
        currentEspStreamId: null,
      }));

    default:
      return orderValidationReducer(state, action);
  }
};

const adaptPriceForTakeProfitIfNeeded = (isTakeProfitOrder: boolean, price: number | null | undefined) => {
  if (isTakeProfitOrder) {
    // we need null values to passed only for takeProfit as this needs to override input values for this specific order
    // whereas we do not expect any override for other type of order when oms sends back null value
    return isDefined(price) ? `${price}` : null;
  }

  return isDefined(price) ? `${price}` : undefined;
};

export const mapValuesFromActionIfValidated = (
  action: OrderValidationReceived | BlotterEditedOrderValidationReceived,
  isTakeProfitOrder: boolean,
) => {
  const isPayloadValid = action.validationDetails.length === 0;

  return isPayloadValid
    ? {
        margin: isDefined(action.margin) ? action.margin : null,
        ...clearUndefined({
          limitPrice: adaptPriceForTakeProfitIfNeeded(isTakeProfitOrder, action.limitPrice),
          customerPrice: adaptPriceForTakeProfitIfNeeded(isTakeProfitOrder, action.customerPrice),
          maturityDate: action.maturityDay,
          ndfFixingSource: action.ndfFixingSource,
          ndfFixingDate: action.ndfFixingDate,
          expiryDay: action.expiryDay,
          expiryTime: action.expiryTime,
        }),
      }
    : {};
};

const commonResetFields: Partial<IFxOrderInputs> = {
  alphaSeeker: undefined,
  clippingMode: undefined,
  clipSize: null,
  randomize: undefined,
  speed: undefined,
  spreadCapture: undefined,
  trigger: undefined,
};

const prepareCommonResetFieldsAfterTypeChange = (value?: OrderType): Partial<IFxOrderInputs> =>
  value === undefined ? {} : { ...commonResetFields, liquidityPool: getOrderLiquidityPoolOptions(value) };

const specificResetFieldsAfterChangePatches: Record<OrderType, Partial<IFxOrderInputs>> = {
  TakeProfit: {},
  StopLoss: { customerPrice: '', trigger: 'Spot' },
  Nightjar: { alphaSeeker: false, speed: 'Normal' },
  Falcon: { speed: 'Normal' },
  Twap: { clippingMode: 'Automatic', spreadCapture: true, randomize: true, isGtc: false },
  Call: {},
  Fixing: {},
};

const prepareSpecificResetFieldsAfterTypeChangePatch = (type?: OrderType) =>
  type === undefined ? {} : specificResetFieldsAfterChangePatches[type];

/**
 * expiry date needs to be recomputed when start date change
 */
const prepareResetExpiryPatch = (startDate?: string | null): Partial<IFxOrderInputs> =>
  isDefined(startDate)
    ? {
        expiryDay: null,
        expiryTime: null,
      }
    : {};

const prepareFixingPatch = (
  action: OrderPropertyChanged,
  order: Readonly<IFxOrderProperties & IFxOrderMetadata>,
): Partial<IFxOrderInputs> => {
  const isFixingOrder = order.inputs.type === 'Fixing' || order.values.type === 'Fixing';
  if (!isFixingOrder || isNotDefined(action.patch.fixingPriceType)) {
    return {};
  }

  return {
    fixingMarginType:
      action.patch.fixingPriceType === 'BIDASK'
        ? 'MarginInBps'
        : action.patch.fixingMarginType ||
          order.inputs.fixingMarginType ||
          order.values.fixingMarginType ||
          'MarginInBps',
  };
};

export function getFixingOrderValues(
  action: OrderValidationReceived | BlotterEditedOrderValidationReceived,
  order: Readonly<IFxOrderProperties & IFxOrderMetadata> | OrderBlotterLimitEntry,
): Omit<BlotterOrderLimitValues, 'product' | 'limitPrice' | 'customerPrice'> {
  const fixingPriceType = action.isCcyForcedBidAsk
    ? 'BIDASK'
    : order.inputs.fixingPriceType || order.values.fixingPriceType || 'MID';

  return {
    fixingTypes: action.fixingTypes,
    isCcyForcedBidAsk: action.isCcyForcedBidAsk,
    isStandardGroup: action.isStandardGroup,
    fixingTimesOfSelectedType: action.fixingTimesOfSelectedType ?? null,
    sourceNameOfSelectedType: action.sourceNameOfSelectedType ?? null,
    fixingBenchmark:
      order.inputs.fixingBenchmark ||
      order.values.fixingBenchmark ||
      (action.fixingTypes ? action.fixingTypes[0] : null),
    fixingPriceType,
    fixingTime:
      !action.isStandardGroup || action.isStandardGroup === null
        ? null
        : ((order.inputs.fixingTime || order.values.fixingTime) ??
          (action.fixingTimesOfSelectedType ? action.fixingTimesOfSelectedType[0] : null)),
    fixingPlace:
      action.isStandardGroup || action.isStandardGroup === null
        ? null
        : ((order.inputs.fixingPlace || order.values.fixingPlace) ??
          (action.sourceNameOfSelectedType ? action.sourceNameOfSelectedType[0] : null)),
    marginInBps: action.marginInBps,
    canModifyMarginInBps: action.canModifyMarginInBps,
    fixingMarginType:
      fixingPriceType === 'BIDASK'
        ? 'MarginInBps'
        : ((order.inputs.fixingMarginType || order.values.fixingMarginType) ?? 'MarginInBps'),
  };
}

const convertAmountToNumber = <K extends keyof IFxOrderInputs>(key: K, value: IFxOrderInputs[K]): IFxOrderValues[K] =>
  (key === 'amount' ? Number(value) : value) as IFxOrderValues[K];

const mapOrderInputsToValues = (inputs: Partial<IFxOrderInputs>): Partial<IFxOrderValues> =>
  strictEntries(inputs).reduce(
    (acc, [key, value]) => {
      if (isDefined(value)) {
        return { ...acc, [key]: convertAmountToNumber(key, value) };
      }

      return acc;
    },
    {} as Partial<IFxOrderValues>,
  );
