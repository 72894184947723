import { extract } from 'utils/object';

export interface SavedBlotter {
  panelHeight: number;
  activeTab: BlotterTab;
  cashTab: SavedBlotterTab;
  optionTab: SavedBlotterTab;
  orderTab: SavedBlotterTab;
  accumulatorTab: SavedBlotterTab;
}

export type BlotterTab = 'cash' | 'option' | 'order' | 'accumulator';

export interface SavedBlotterTab {
  autoColumState: { width: number };
  columnsState: readonly FxColumnState[];
  sortModel: BlotterSortModel;
}

interface FxColumnState {
  colId: string;
  width?: number;
  hide?: boolean;
}

type BlotterSortModel = readonly BlotterColSort[];

interface BlotterColSort {
  colId: string;
  sort: string;
}

export const defaultBlotterSortModel = [{ colId: 'updateTime', sort: 'desc' }];

const defaultAutoColumnState = {
  width: 100,
};

export const columnsDefinition: Record<string, FxColumnState> = {
  id: { colId: 'id' },
  xone: { colId: 'xone' },
  account: { colId: 'account', width: 200 },
  action: { colId: 'action' },
  amountDone: { colId: 'amountDone' },
  currencyPair: { colId: 'currencyPair', width: 90 },
  date: { colId: 'date' },
  expiry: { colId: 'expiry', width: 100 },
  farDate: { colId: 'farDate', width: 100 },
  farRate: { colId: 'farRate', width: 100 },
  gtc: { colId: 'gtc' },
  inputDate: { colId: 'inputDate' },
  nearDate: { colId: 'nearDate', width: 100 },
  nearRate: { colId: 'nearRate', width: 100 },
  notional: { colId: 'notional', width: 150 },
  notionalCurrency: {
    colId: 'notionalCurrency',
    width: 120,
  },
  dealtAmount: { colId: 'dealtAmount', width: 150 },
  farDealtAmount: { colId: 'farDealtAmount', width: 150 },
  dealtCurrency: {
    colId: 'dealtCurrency',
    width: 120,
  },
  contraAmount: { colId: 'contraAmount', width: 150 },
  farContraAmount: { colId: 'farContraAmount', width: 150 },
  contraCurrency: {
    colId: 'contraCurrency',
    width: 120,
  },
  limitPrice: { colId: 'limitPrice' },
  customerPrice: { colId: 'customerPrice' },
  points: { colId: 'points', width: 80 },
  portfolio: { colId: 'portfolio' },
  premium: { colId: 'premium' },
  product: { colId: 'product', width: 80 },
  salesFullName: {
    colId: 'salesFullName',
    width: 140,
  },
  spot: { colId: 'spot', width: 80 },
  spotDate: { colId: 'spotDate', width: 100 },
  status: { colId: 'status', width: 120 },
  strike: { colId: 'strike', width: 120 },
  time: { colId: 'time', width: 100 },
  updateTime: { colId: 'updateTime', hide: true },
  venue: { colId: 'venue', width: 120 },
  way: { colId: 'way', width: 100 },
  executionPrice: { colId: 'executionPrice', width: 100 },
  premiumCurrency: { colId: 'premiumCurrency', width: 120 },
  optionType: { colId: 'optionType', width: 120 },
  fixingFrequency: { colId: 'fixingFrequency', width: 100 },
  settlementFrequency: { colId: 'settlementFrequency', width: 100 },
  koConventionType: { colId: 'koConventionType', width: 100 },
  koTime: { colId: 'koTime', width: 100 },
  electronicReference: { colId: 'electronicReference' },
  electronicAccount: { colId: 'electronicAccount' },
  strategyReference: { colId: 'strategyReference' },
  blotterType: { colId: 'blotterType' },
};

const filterColumn = (columns: readonly string[]): readonly FxColumnState[] =>
  Object.entries(columnsDefinition)
    .filter((entry) => columns.includes(entry[0]))
    .map(extract(1));

export const defaultCashTab: SavedBlotterTab = {
  autoColumState: defaultAutoColumnState,
  columnsState: filterColumn([
    'xone',
    'status',
    'date',
    'time',
    'currencyPair',
    'product',
    'dealtAmount',
    'farDealtAmount',
    'dealtCurrency',
    'contraAmount',
    'farContraAmount',
    'contraCurrency',
    'way',
    'spot',
    'spotDate',
    'nearRate',
    'nearDate',
    'farRate',
    'farDate',
    'points',
    'venue',
    'account',
    'portfolio',
    'salesFullName',
    'updateTime',
    'electronicAccount',
  ]),
  sortModel: defaultBlotterSortModel,
};

export const defaultOptionTab: SavedBlotterTab = {
  autoColumState: defaultAutoColumnState,
  columnsState: filterColumn([
    'status',
    'product',
    'optionType',
    'currencyPair',
    'way',
    'dealtAmount',
    'dealtCurrency',
    'contraAmount',
    'contraCurrency',
    'strike',
    'spot',
    'expiry',
    'premium',
    'date',
    'time',
    'venue',
    'account',
    'portfolio',
    'salesFullName',
    'updateTime',
    'premiumCurrency',
    'electronicAccount',
  ]),
  sortModel: defaultBlotterSortModel,
};

export const defaultOrderTab: SavedBlotterTab = {
  autoColumState: defaultAutoColumnState,
  columnsState: filterColumn([
    'status',
    'action',
    'inputDate',
    'gtc',
    'product',
    'currencyPair',
    'way',
    'notional',
    'notionalCurrency',
    'amountDone',
    'limitPrice',
    'customerPrice',
    'account',
    'updateTime',
    'executionPrice',
  ]),
  sortModel: defaultBlotterSortModel,
};

export const defaultAccumulatorTab: SavedBlotterTab = {
  autoColumState: defaultAutoColumnState,
  columnsState: filterColumn([
    'status',
    'product',
    'currencyPair',
    'way',
    // 'expiry',
    'premium',
    'premiumCurrency',
    'date',
    'time',
    'venue',
    'account',
    'salesFullName',
    'portfolio',
  ]),
  sortModel: defaultBlotterSortModel,
};

export const defaultSavedBlotter: SavedBlotter = {
  panelHeight: 300,
  activeTab: 'cash',
  cashTab: defaultCashTab,
  optionTab: defaultOptionTab,
  orderTab: defaultOrderTab,
  accumulatorTab: defaultAccumulatorTab,
};
