import { FormattedDate, FormattedMessage, FormattedNumber } from 'react-intl';
import { useSelector } from 'react-redux';
import { bulkOrder } from '../../state/fxOrderBulks/fxOrderBulksSelectors';
import type { Trigger } from '../../state/fxOrders/fxOrdersModel';
import type { Way } from '../../state/share/productModel/litterals';
import { Cell } from './Cell';
import { OrderStatus } from './OrderStatus';

type OrderRowProps = {
  orderId: string;
  isCompact?: boolean;
};

export const OrderRow = (props: OrderRowProps) => {
  const { orderId, isCompact = false } = props;

  const order = useSelector((state) => bulkOrder(state, { orderId }));

  return (
    <tr data-e2e="row">
      <td width="120" align="center" data-e2e="status-cell">
        <OrderStatus status={order.status} errors={order.errors} />
      </td>

      <td width="140" align="center" className="text-success" data-e2e="status-oms-id-cell">
        <strong>{order.omsId}</strong>
      </td>

      {!isCompact && (
        <>
          <Cell width="120" field="bdrId" errors={order.errors} align="center">
            {order.bdrId}
          </Cell>

          <Cell width="120" field="currencyPair" errors={order.errors} align="center">
            {order.currencyPair}
          </Cell>
        </>
      )}

      <Cell width="120" field="way" errors={order.errors} align="center">
        <span className={`text-${getWayColor(order.way)}`}>
          <FormattedMessage id={order.way === 'Ask' ? 'way.buy' : 'way.sell'} />
        </span>
      </Cell>

      <Cell width="200" field="notional" errors={order.errors} align="right">
        <FormattedNumber value={order.notional} />
      </Cell>

      <Cell width="40" field="currency" errors={order.errors} align="left">
        <span className={`text-${getCurrencyColor(order.currencyIndex)}`}>{order.currency}</span>
      </Cell>

      <Cell width="120" field="limitPrice" errors={order.errors} align="right">
        <FormattedNumber value={order.limitPrice} />
      </Cell>

      <Cell width="250" field="expiryDate" errors={order.errors} align="right">
        {order.expiryDate !== undefined ? (
          <FormattedDate
            value={order.expiryDate}
            year="numeric"
            month="long"
            day="2-digit"
            hour="2-digit"
            minute="2-digit"
          />
        ) : (
          <FormattedMessage id="expirationDate.GTC" />
        )}
      </Cell>

      <Cell width="220" field="trigger" errors={order.errors} align="center">
        <span className={`text-${getTriggerColor(order.trigger)}`}>
          <FormattedMessage id={`trigger.${order.trigger}`} />
        </span>
      </Cell>

      <Cell field="notificationEmails" errors={order.errors} align="left">
        {order.notificationEmails.join(', ')}
      </Cell>
    </tr>
  );
};

const getWayColor = (way: Way) => (way === 'Bid' ? 'azure' : 'buttercup');

const getCurrencyColor = (currencyIndex: 1 | 2) => (currencyIndex === 1 ? 'azure' : 'buttercup');

const getTriggerColor = (trigger: Trigger) =>
  trigger === 'Spot' ? 'azure' : trigger === 'BidOffer' ? 'primary' : 'secondary';
