import type { MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { ActionCreators } from 'state/actions';

export type MinimizeButtonConnectOwnProps = {}

export interface MinimizeButtonConnectDispatchProps {
  onMinimize(): void;
}

// you can whitelist action creators after implementation
export type MinimizeButtonConnectActionCreators = ActionCreators;
/*
export type MinimizeButtonConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type MinimizeButtonConnectActionCreators = Pick<ActionCreators, MinimizeButtonConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsMinimizeButton: MapDispatchToPropsHOF<
  MinimizeButtonConnectDispatchProps,
  MinimizeButtonConnectOwnProps,
  MinimizeButtonConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onMinimize() {
    dispatch(ac.blotterToggle(false));
  },
});
