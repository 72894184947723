import { getApiUrl } from '../../../bootstrap/sgmeConfiguration';

export type PostValidateOrderBody = {
  isGtc: boolean;
  expiryDay: string | undefined;
  expiryTime: string | undefined;
  bdrId: string;
  ccyPair: string;
  way: 'buy' | 'sell';
  amountInCcy1: number | undefined;
  amountInCcy2: number | undefined;
  limitPrice: string;
  base64Emails: string;
  triggerMode: string;
  localTimeWithOffSet: number;
};

export type PostValidateOrderResponse = PostValidateOrderResponseSuccess | PostValidateOrderResponseError;

export type PostValidateOrderResponseSuccess = {
  message: string; // "Validation succeeded",
  validationResponseDetailContent: ValidationResponseDetailContent[];
  isReadyToSubmit: boolean;
  expiryDay: null;
  defaultPrice: null;
  maturityDay: string; // "2024-10-28T08:19:15.0090021Z",
  fixingTypes: string[]; // ["BFIX", "WMR", "OTHER", "KFTC"],
  isCcyForcedBidAsk: null;
  isStandardGroup: null;
  fixingTimesOfSelectedType: string[]; // ["16:00 London Time", "10:00 NewYork time"],
  sourceNameOfSelectedType: string[]; // ["KFTC18", "KFTC19"],
  canModifyMarginInBps: null;
  marginInBps: null;
  ndfFixingDate: null;
  ndfFixingSource: null;
  limitPrice: null;
  customerPrice: null;
  margin: null;
  backendTime: number;
};

export type PostValidateOrderResponseError = {
  Status: number;
  Title: string;
};

export type ValidationResponseDetailContent = {
  field: string;
  errorDescription: string;
  errorCode: number;
};

const apiUrl = getApiUrl();

export const postValidateOrder = (body: PostValidateOrderBody, bearer: string): Promise<PostValidateOrderResponse> => {
  return fetch(`${apiUrl}api/oms/validate/StopLoss`, {
    method: 'POST',
    headers: {
      Authorization: bearer,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  }).then((response) => response.json());
};
