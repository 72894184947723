import clsx from 'clsx';
import { EnhancedInput } from 'components/share/EnhancedInput';
import { InputToggle } from 'components/share/InputToggle';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state';
import { actionCreators } from '../../../state/actions';
import { selectors } from '../../../state/selectors';

export interface TabNameProps {
  tabId: string;
  isEditable: boolean;
}

export const TabName = (props: TabNameProps) => {
  const { tabId, isEditable } = props;

  const name = useAppSelector((state) => selectors.getClientWorkspaceTab(state, tabId).name);
  const isShared = useAppSelector((state) => selectors.isTabShared(state, tabId));

  const dispatch = useDispatch();

  const rename = (newName: string) => {
    dispatch(actionCreators.clientWorkspaceTabRenamed(tabId, newName));
  };

  return (
    <InputToggle onChange={rename} isEditable={isEditable}>
      {({ isOpen, toggle, onChange }) =>
        isOpen ? (
          <EnhancedInput
            autofocus
            value={name}
            className="form-control w-100"
            onSubmit={onChange}
            onBlurPristine={toggle}
          />
        ) : (
          <strong
            className={clsx('form-control form-control-plaintext text-truncate p-2', {
              'pe-none': !isEditable,
              'text-white': isShared,
            })}
            onClick={isEditable ? toggle : undefined}
          >
            {name}
          </strong>
        )
      }
    </InputToggle>
  );
};
