import type { CurrencyChoice } from 'api/workspaceService/model';
import type { IFxExecutionData, IFxExecutionState } from 'state/executions/executionsStateModel';
import type { IFormData } from 'state/share/form';
import type { Possible, Side, Way } from 'state/share/productModel/litterals';
import type { IFxTileMetadata } from 'state/tile/fxTileModel';
import type { Collection } from 'typings/utils';
import type { Patch } from '../../share/patchModels';
import type { IQuote } from './fxAmericanForwardStreamModel';

export const POSSIBLE_HEDGE_TYPE = ['Live', 'Spot', 'Forward'] as const;
export type HedgeType = (typeof POSSIBLE_HEDGE_TYPE)[number];

export const POSSIBLE_PRICE_TYPE = ['AMOUNT', 'PERCENT', 'PPS'] as const;
export type PriceType = (typeof POSSIBLE_PRICE_TYPE)[number];
export type DisplayPriceType = [ccyChoice: CurrencyChoice, priceType: PriceType];

export const POSSIBLE_MARGIN_TYPE = ['AMOUNT', 'RATE'] as const;
export type MarginType = (typeof POSSIBLE_MARGIN_TYPE)[number];

export const DEFAULT_SOLVING_TYPE = 'zeroCost' as const;
export const POSSIBLE_SOLVING_TYPE = [DEFAULT_SOLVING_TYPE, 'solveRate', 'solveUpfront'] as const;
export type PossibleSolvingPremium = (typeof POSSIBLE_SOLVING_TYPE)[number];

export type Solvable = 'ForwardRate' | 'PremiumPaymentAmount';

export const emptyFxAmericanForwardState: FxAmericanForwardState = {
  errors: {},
  inputs: {},
  values: {
    notionalAmount: null,
    notionalCurrency: 1,
    callabilityStart: null,
    callabilityStartTenor: null,
    callabilityType: 'Windows',
    currency1: null,
    currency2: null,
    currencyPair: null,
    deliveryDate: null,
    deliveryDateTenor: null,
    expiryDate: null,
    expiryDateTenor: null,
    forwardRate: null,
    hedgeAmount: null,
    hedgeCurrency: 1,
    hedgePaymentDate: null,
    hedgeRate: null,
    hedgeType: 'Live',
    marketPlace: null,
    markupCurrency: 'ccy1',
    possibleHedgeTypes: POSSIBLE_HEDGE_TYPE,
    possibleMarketPlaces: null,
    premiumDate: null,
    premiumDateTenor: null,
    premiumPaymentAmount: null,
    premiumTypeString: 'AMOUNT',
    priceCurrency: 1,
    productName: 'FxAmericanForward',
    quantity: null,
    side: 'Buy',
    solvable: 'ForwardRate',
  },
  warnings: {},
  currentEspStreamId: null,
  currentSessionId: null,
  currentStreamId: null,
  displayPriceType: [1, 'AMOUNT'],
  solvingType: DEFAULT_SOLVING_TYPE,
  isPriceable: false,
  propertiesRequested: false,
  propertiesRequestError: null,
  tradeCaptureIdVersion: null,
  lastStreamId: null,
  lastStreamError: null,
  lastExecutedQuoteId: null,
  currentExecutionId: null,
  rfsStartedAt: null,
  priceRecords: [],
};

export type FxAmericanForwardStateMap = Readonly<Collection<FxAmericanForwardProperties>>;

export type FxAmericanForwardState = Readonly<IFxAmericanForwardMetadata & FxAmericanForwardProperties>;

export type FxAmericanForwardProperties = Readonly<
  IFormData<FxAmericanForwardValues, FxAmericanForwardInputs> & IFxAmericanForwardMetadata
>;

export type AmericanForwardProductName = 'FxAmericanForward';

export type FxAmericanForwardPatch = Patch<FxAmericanForwardValues>;

export interface FxAmericanForwardValues {
  productName: AmericanForwardProductName;
  currencyPair: string | null;
  currency1: string | null;
  currency2: string | null;
  side: Side;
  notionalAmount: number | null;
  notionalCurrency: CurrencyChoice;
  quantity: number | null;
  expiryDate: string | null;
  expiryDateTenor: string | null;
  callabilityStart: string | null;
  callabilityStartTenor: string | null;
  callabilityType: string | null;
  deliveryDate: string | null;
  deliveryDateTenor: string | null;
  premiumTypeString: PriceType;
  priceCurrency: CurrencyChoice;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  premiumPaymentAmount: string | null;
  possibleHedgeTypes: readonly HedgeType[];
  hedgeType: HedgeType;
  hedgeRate: string | null;
  hedgeCurrency: CurrencyChoice;
  hedgePaymentDate: string | null;
  hedgeAmount: string | null;
  markupCurrency: string;
  possibleMarketPlaces: Possible<string> | null;
  marketPlace: string | null;
  forwardRate: string | null;
  solvable: Solvable;
}

export interface FxAmericanForwardInputs {
  currencyPair: string | null;
  side: Side | null;
  notionalAmount: string | null;
  notionalCurrency: CurrencyChoice;
  callabilityStart: string | null;
  callabilityStartTenor: string | null;
  expiryDate: string | null;
  expiryDateTenor: string | null;
  deliveryDate: string | null;
  deliveryDateTenor: string | null;
  marketPlace: string | null;

  hedgeType: HedgeType;
  hedgeRate: string | null;
  hedgeAmount: string | null;
  hedgeCurrency: CurrencyChoice;

  priceType: PriceType;
  premiumTypeString: PriceType;
  premiumCurrency: CurrencyChoice;
  premiumBid: string | null;
  premiumAsk: string | null;
  priceCurrency: CurrencyChoice;
  premiumPaymentDate: string | null;
  premiumPaymentAmount: string | null;
  premiumDate: string | null;
  premiumDateTenor: string | null;
  markupCurrency: string;
  forwardRate: string | null;
}

export interface IFxAmericanForwardMetadata extends IFxTileMetadata {
  displayPriceType: DisplayPriceType;
  lastStreamId: string | null;
  solvingType: PossibleSolvingPremium;
}

export interface IFxAmericanForwardExecutionData extends IFxExecutionData {
  instrument: 'AmericanForward';
  way: Way;
  quote: Readonly<IQuote>;
  displayPriceType: DisplayPriceType;
  legs: Record<string, FxAmericanForwardValues>;
}

export type FxAmericanForwardExecutionState = IFxExecutionState & IFxAmericanForwardExecutionData;
