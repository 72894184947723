import { createSelector } from 'reselect';
import type { ActionCreators } from 'state/actions';
import type { BlotterEntry } from 'state/blotter/blotterEntryModel';
import type {
  BlotterHistoricalError,
  BlotterTab,
  BlotterTabMetadata,
  FxColumnState,
} from 'state/blotter/blotterModel';
import type { AppState } from 'state/model';
import type { ClientMap } from 'state/referenceData/referenceDataModel';
import type { Selectors } from 'state/selectors';
import type { AccountLengthOption } from 'state/userPreferences/userPreferencesModel';
import type { MapDispatchToPropsHOF, MapStateToPropsFactoryHOF } from 'typings/redux-utils';
import { assertUnreachable } from '@sgme/fp';
import type { EnhancedBlotterEntry } from '../LiveBlotter/liveBlotterType';

export type LiveBlotterConnectOwnProps = {}

export interface LiveBlotterConnectStateProps {
  isOpen: boolean;
  error: BlotterHistoricalError;
  tableHasError?: true;
  blotterData: readonly EnhancedBlotterEntry[];
  activeTab: BlotterTab;
  activeTabMetadata: BlotterTabMetadata;
  selectedId: string | null;
}

export interface LiveBlotterConnectDispatchProps {
  onBlotterTabMetadataChanged(tab: BlotterTab, tabMetadata: Partial<BlotterTabMetadata>): void;
  onBlotterClickAction(selectedId: string, childrenIds: readonly string[] | null): void;
  onBlotterTableCrash(): void;
}

export type LiveBlotterConnectSelectors = Selectors;

const emptyArray: readonly EnhancedBlotterEntry[] = [];

const makeGetTrades = (sl: Selectors) => (state: AppState) => {
  const mode = sl.getBlotterMode(state);
  const activeTab = sl.getBlotterActiveTab(state);
  switch (activeTab) {
    case 'cash':
      return mode === 'intraday'
        ? sl.getBlotterIntradayCashTrades(state)
        : sl.getBlotterHistoricalCashTrades(state);
    case 'option':
      return mode === 'intraday'
        ? sl.getBlotterIntradayOptionTrades(state)
        : sl.getBlotterHistoricalOptionTrades(state);
    case 'order':
      return sl.getBlotterOrders(state);
    case 'accumulator':
      return mode === 'intraday'
        ? sl.getBlotterIntradayAccumulatorTrades(state)
        : sl.getBlotterHistoricalAccumulatorTrades(state);
    default:
      assertUnreachable(activeTab, 'Unhandled blotter active tab type');
  }
};

const getAccountDisplayNameHoF =
  (userClients: Readonly<ClientMap>, accountNameLength: AccountLengthOption) =>
  (entry: BlotterEntry): string | null => {
    if (entry.instrument === 'Order') {
      const client =
        entry.values.account !== null ? userClients[entry.values.account] : userClients[0];
      if (client) {
        return accountNameLength === 'Short' && client.externalCompanyName
          ? client.externalCompanyName
          : client.companyName;
      } else {
        return entry.values.account;
      }
    } else {
      return accountNameLength === 'Long' && entry.values.accountLongName
        ? entry.values.accountLongName
        : entry.values.account;
    }
  };

const blotterEntryEnhancer = (
  userClients: Readonly<ClientMap>,
  accountNameLength: AccountLengthOption,
) => {
  const getDisplayAccountName = getAccountDisplayNameHoF(userClients, accountNameLength);
  return (entry: BlotterEntry): EnhancedBlotterEntry => ({
    ...entry,
    values: {
      ...(entry.values as any), // Sorry but Typescript doesn't handle well the discriminated union spread here
      accountDisplayName: getDisplayAccountName(entry),
    },
  });
};

export const mapStateToPropsLiveBlotter: MapStateToPropsFactoryHOF<
  LiveBlotterConnectStateProps,
  LiveBlotterConnectOwnProps,
  AppState,
  LiveBlotterConnectSelectors
> = sl => () => {
  const getTrades = makeGetTrades(sl);
  const getEnhancedTrades = createSelector(
    [getTrades, sl.getAllUserClients, sl.getAccountNameLength],
    (trades, clients, accountNameLength) =>
      trades.map(blotterEntryEnhancer(clients, accountNameLength)),
  );
  return state => {
    const activeTab = sl.getBlotterActiveTab(state);
    const activeTabMetadata = sl.getBlotterTabMetadata(state, activeTab);
    const selectedId = sl.getBlotterDetailsPanelSelectedId(state);
    if (!sl.isInternalUser(state)) {
      activeTabMetadata.columnsState = getColumnsForExternalUser(activeTabMetadata.columnsState);
    }
    const error = sl.getBlotterHistoricalError(state);
    const isOpen = sl.getBlotterIsOpen(state);

    const blotterData = isOpen ? getEnhancedTrades(state) : emptyArray;

    return {
      isOpen,
      activeTab,
      error,
      blotterData,
      activeTabMetadata,
      selectedId,
      tableHasError: sl.getBlotterErrors(state).includes('tableCrash') || undefined,
      userType: sl.getUserInfoUserType(state),
    };
  };
};

// you can whitelist action creators after implementation
export type LiveBlotterConnectActionCreators = ActionCreators;
/*
export type LiveBlotterConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type LiveBlotterConnectActionCreators = Pick<ActionCreators, LiveBlotterConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsLiveBlotter: MapDispatchToPropsHOF<
  LiveBlotterConnectDispatchProps,
  LiveBlotterConnectOwnProps,
  LiveBlotterConnectActionCreators
> = ac => dispatch => ({
  onBlotterTabMetadataChanged(tab, tabMetadata) {
    dispatch(ac.blotterTabMetadataChanged(tab, tabMetadata));
  },
  onBlotterClickAction(selectedId, childrenIds) {
    dispatch(ac.blotterClickActionThunk(selectedId, 'Display', childrenIds));
  },
  onBlotterTableCrash() {
    dispatch(ac.blotterTableCrashed());
  },
});

function getColumnsForExternalUser(columnsStates: readonly FxColumnState[]) {
  return columnsStates.filter(
    columnsState => columnsState.colId !== 'portfolio' && columnsState.colId !== 'salesFullName',
  );
}
