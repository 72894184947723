import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { NotificationZoneProps, Notification } from './typings';

export type NotificationZoneConnectOwnProps = {}

export type NotificationZoneConnectDispatchProps = {}

// you can whitelist selectors after implementation
export type NotificationZoneConnectSelectors = Selectors;
/*
export type NotificationZoneConnectSelectorsKeys = 'aSelector'; // example
export type NotificationZoneConnectSelectors = Pick<Selectors, NotificationZoneConnectSelectorsKeys>;
*/

const emptyArray: readonly Notification[] = [];
const streamingInitializingNotification: readonly Notification[] = [
  {
    messageId: 'header.notification.streamingInitialising',
    level: 'info',
  },
];
const streamingUnavailableNotification: readonly Notification[] = [
  {
    messageId: 'header.notification.streamingUnavailable',
    level: 'danger',
  },
];
const streamingDegradedNotification: readonly Notification[] = [
  {
    messageId: 'header.notification.streamingDegraded',
    level: 'warning',
    helpCenterLinkId: 'StreamingDegraded',
  },
];
const userOfflineNotification: readonly Notification[] = [
  {
    messageId: 'header.notification.userOffline',
    level: 'warning',
  },
];
const streamingNotOptimalNotification: readonly Notification[] = [
  {
    messageId: 'header.notification.streamingNotOptimal',
    level: 'warning',
    helpCenterLinkId: 'StreamingNotOptimal',
  },
];

export const mapStateToPropsNotificationZone: MapStateToPropsHOF<
  NotificationZoneProps,
  NotificationZoneConnectOwnProps,
  AppState,
  NotificationZoneConnectSelectors
> = sl => (state, _ownProps) => ({
  messages: sl.isUserOffline(state)
    ? userOfflineNotification
    : sl.isStreamingInitialising(state)
    ? streamingInitializingNotification
    : !sl.isStreamingConnected(state)
    ? streamingUnavailableNotification
    : sl.isStreamingDegraded(state)
    ? streamingDegradedNotification
    : sl.isStreamingNotOptimal(state)
    ? streamingNotOptimalNotification
    : emptyArray,
});

// you can whitelist action creators after implementation
export type NotificationZoneConnectActionCreators = ActionCreators;
/*
export type NotificationZoneConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type NotificationZoneConnectActionCreators = Pick<ActionCreators, NotificationZoneConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsNotificationZone: MapDispatchToPropsHOF<
  NotificationZoneConnectDispatchProps,
  NotificationZoneConnectOwnProps,
  NotificationZoneConnectActionCreators
> = _ac => (_dispatch, _ownProps) => ({});
