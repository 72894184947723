import type {
  AccumulatorBarrier,
  AccumulatorScheduleMetadata,
  IFxForwardAccumulatorValues,
  IFxTargetAccumulatorValues,
} from 'state/fxAccumulators/fxAccumulatorsModel';
import {
  type AccumulatorSchedule,
  type AccumulatorScheduleValues,
  type FxAccumulatorPatch,
  type IFxAccumulatorValues,
  scheduleCustomFieldValues,
  type ScheduleCustomFieldValues,
} from 'state/fxAccumulators/fxAccumulatorsModel';
import type { MapStateToMetadataHOF } from 'typings/redux-utils';
import type {
  ITradeCaptureMetaData,
  TcError,
  TcErrors,
  TcWarning,
  TcWarnings,
} from 'api/tradeCapture/tradeCaptureModel';
import type {
  CalendarPreviousValue,
  ITradeCaptureAccumulatorMetaData,
  ITradeCaptureAccumulatorSchedule,
  ResponseBarrier,
  TcAccumulatorHedgeType,
  TradeCaptureAccumulatorErrors,
  TradeCaptureAccumulatorLegPreviousValues,
  TradeCaptureAccumulatorResponseWrapper,
  TradeCaptureAccumulatorScheduleCustomFieldResponse,
  TradeCaptureAccumulatorScheduleItemRequest,
  TradeCaptureAccumulatorScheduleItemResponse,
  TradeCaptureAccumulatorScheduleResponse,
  TradeCaptureAccumulatorWarnings,
} from 'api/tradeCapture/accumulator/tradeCaptureModel';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import { clearUndefined } from 'utils/clearUndefined';
import {
  fromTcError,
  fromTcErrorAsString,
  fromTcWarning,
  fromTcWarningAsString,
  previousValue,
} from 'api/tradeCapture/tradeCaptureMappingHelper';
import type { CurrencyChoice, HedgeType, PropertyErrors, PropertyWarnings } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';
import { camelize } from 'utils/camelize';
import { mapOptional } from 'utils/optional';
import { isDefined, isEmptyOrNotDefined } from '@sgme/fp';

type ActionPatch = TradeCaptureAccumulatorResponseWrapper & WithAccumulatorId;

export type TradeCaptureAccumulatorResponsePatch = TradeCaptureAccumulatorResponseWrapper & WithAccumulatorId;

export type AccumulatorTradeCapturePatch = ITradeCaptureMetaData &
  ITradeCaptureAccumulatorMetaData &
  FxAccumulatorPatch &
  ITradeCaptureAccumulatorSchedule;

interface WithAccumulatorId {
  quoteId: string;
}

type TradeCaptureFromBackendMetaSelectorsKeys =
  | 'getAccumulatorCurrencyPairInput'
  | 'getAccumulatorScheduleItemIds'
  | 'getAccumulatorScheduleCustomFields'
  | 'getAccumulatorBarrier';

type TradeCaptureFromBackendMetaSelectorSelectors = Pick<Selectors, TradeCaptureFromBackendMetaSelectorsKeys>;

export const metaSelectorTradeCaptureFromBackendWith: MapStateToMetadataHOF<
  AccumulatorTradeCapturePatch,
  TradeCaptureAccumulatorResponsePatch,
  AppState,
  TradeCaptureFromBackendMetaSelectorSelectors
> = sl => (state, patch) => {
  const getValues = makeGetValues(state, sl);
  const tcErrors = getTradeCaptureErrors(patch.errors);
  const tcWarnings = getTradeCaptureWarnings(patch.warnings);

  const { scheduleCustomFields: tcFixingCustomFields, schedule: tcSchedule } = patch.changedFields.legs[0];

  const stateFixingCustomFields = sl.getAccumulatorScheduleItemIds(state, patch.quoteId).reduce((acc, legId) => {
    acc[legId] = {
      custom: sl.getAccumulatorScheduleCustomFields(state, patch.quoteId, legId),
    };
    return acc;
  }, {} as Record<string, AccumulatorScheduleMetadata | undefined>);

  return {
    ...getMetadata(patch),
    values: getValues(patch, tcErrors),
    errors: getErrors(tcErrors),
    warnings: getWarnings(tcWarnings),
    schedule: mapToSchedule(
      stateFixingCustomFields,
      tcSchedule,
      tcFixingCustomFields,
      tcErrors.schedule,
      tcWarnings.schedule,
    ),
  };
};

const getMetadata = (patch: ActionPatch): ITradeCaptureMetaData & ITradeCaptureAccumulatorMetaData => ({
  idVersion: patch.idVersion,
  isReadyToPrice: patch.changedFields.isReadyToPrice || false,
  isPriceObsolete: patch.changedFields.isPriceObsolete || false,
  isProductObsolete: patch.changedFields.isProductObsolete || false,
  summary: patch.changedFields.legs[0].summary || '',
  numberOfSettlements: patch.changedFields.legs[0].numberOfSettlements,
});

const makeGetValues =
  (state: AppState, sl: TradeCaptureFromBackendMetaSelectorSelectors) =>
    (
      { quoteId, changedFields }: TradeCaptureAccumulatorResponsePatch,
      errors: TcErrors<TradeCaptureAccumulatorLegPreviousValues>,
    ): Partial<IFxAccumulatorValues> => {
      const { currencyPair, hedgeType, hedgePaymentDate, hedgePrice, markupCurrency } = changedFields;

      const currencyPairRef = currencyPair || fieldData(sl.getAccumulatorCurrencyPairInput(state, quoteId)).data;

      const previousBarrier = sl.getAccumulatorBarrier(state, quoteId) ?? undefined;

      const {
        accuType,
        strikeComparator,
        premiumPaymentCurrency,
        premiumPaymentDate,
        premiumPaymentDateTenor,
        side,
        amount,
        amountSplitType,
        amountCurrency,
        leverage,
        leverageAmount,
        strike,
        ekiDown,
        strikeDown,
        pivot,
        strikeUp,
        ekiUp,
        possibleAccuTypeList,
        step,
        step2,
        stepDown,
        stepUp,
        barrier,
        possibleKoPaymentConventions,
        koPaymentConvention,
        target,
        firstFixingDate,
        firstFixingDateTenor,
        possibleFixingFrequencies,
        possibleSettlementFrequencies,
        fixingFrequency,
        numberOfFixings,
        possibleFixingReferences1,
        fixingReference1,
        possibleFixingReferences2,
        fixingReference2,
        settlementFrequency,
        deliveryType,
        possibleDeliveryTypes,
        cashSettlementCurrency,
        possibleCashSettlementCurrencies,
        expiryDateTenor,
        expiryDate,
        deliveryDate,
        lastFixingDate,
        calendar,
        cutOffMarketPlace,
        totalLeverageAmount,
        totalNotionalAmount,
        customFields,
        isCrossed,
        crossCurrency,
        possibleCrossCurrencies,
        eki,
        ekiString,
        hedgeAmountInCcy1,
        hedgeAmountInCcy2,
      } = changedFields.legs[0];

      const akoTrigger =
        barrier === null
          ? null
          : mapToString(barrier?.level, previousValue((errors.barrier as TcErrors<AccumulatorBarrier>)?.level));

      return clearUndefined<Partial<IFxForwardAccumulatorValues | IFxTargetAccumulatorValues>>({
        currencyPair,
        hedgeType: mapToHedgeType(hedgeType),
        hedgePaymentDate,
        hedgeAmount: mapToString(mapHedgeAmount(hedgeAmountInCcy1, hedgeAmountInCcy2)),
        hedgePrice: mapToString(hedgePrice),
        premiumDate: premiumPaymentDate || previousValue(errors.premiumPaymentDate),
        premiumDateTenor: premiumPaymentDateTenor,
        way: side,
        amount: mapToString(amount, previousValue(errors.amount)),
        amountSplitType,
        amountCurrency: mapToCurrencyChoice(amountCurrency, currencyPairRef),
        leverage: mapToString(leverage, previousValue(errors.leverage)),
        leverageAmount: mapToString(leverageAmount, previousValue(errors.leverageAmount)),
        strike: mapToString(strike, previousValue(errors.strike)),
        ekiDown: mapToString(ekiDown, previousValue(errors.ekiDown)),
        strikeDown: mapToString(strikeDown, previousValue(errors.strikeDown)),
        pivot: mapToString(pivot, previousValue(errors.pivot)),
        strikeUp: mapToString(strikeUp, previousValue(errors.strikeUp)),
        ekiUp: mapToString(ekiUp, previousValue(errors.ekiUp)),
        possibleAccuTypeList: possibleAccuTypeList?.possible,
        step: mapToString(step, previousValue(errors.step)),
        akoTrigger,
        barrier: mapAkoTriggerFromBackend(barrier, previousBarrier),
        ekiTrigger: mapToString(step2, previousValue(errors.step2)),
        stepDown,
        stepUp,
        target: mapToString(target, previousValue(errors.target)),
        firstFixingDate: firstFixingDate || previousValue(errors.firstFixingDate),
        firstFixingDateTenor,
        priceCurrency: mapToPriceCurrency(premiumPaymentCurrency, currencyPairRef),
        targetProfitType: koPaymentConvention,
        targetProfitTypeList: possibleKoPaymentConventions?.possible,
        fixingFrequency,
        numberOfFixings,
        defaultFixingFrequency: possibleFixingFrequencies?.default,
        fixingFrequenciesList: possibleFixingFrequencies?.possible,
        fixingReference1,
        defaultFixingReference1: possibleFixingReferences1?.default,
        fixingReferencesList1: possibleFixingReferences1?.possible,
        fixingReference2,
        defaultFixingReference2: possibleFixingReferences2?.default ?? undefined,
        fixingReferencesList2: possibleFixingReferences2?.possible === null ? [] : possibleFixingReferences2?.possible,
        settlementFrequency,
        defaultSettlementFrequency: possibleSettlementFrequencies?.default,
        settlementFrequenciesList: possibleSettlementFrequencies?.possible,
        settlementMode: deliveryType,
        settlementModeList: possibleDeliveryTypes?.possible,
        cashSettlementCurrency,
        cashSettlementCurrencyList: possibleCashSettlementCurrencies?.possible,
        expiryTenor: expiryDateTenor,
        expiryDate,
        deliveryDate,
        cutOffMarketPlace,
        lastFixingDate,
        calendar,
        totalLeverage: totalLeverageAmount,
        totalNotional: totalNotionalAmount,
        markupCurrency,
        customFields,
        isCrossed,
        crossCurrency,
        possibleCrossCurrencies: possibleCrossCurrencies?.possible,
        accuType,
        strikeComparator,
        eki,
        ekiString,
      });
    };

function mapHedgeAmount(hedgeAmountInCcy1: number | null | undefined, hedgeAmountInCcy2: number | null | undefined) {
  // if one amount is null and the other is null or undefined
  // CAUTION : isDefined uses double equal not triple equals, meaning that undefined is also considered truthy here
  return (hedgeAmountInCcy1 === null && !isDefined(hedgeAmountInCcy2)) ||
    (hedgeAmountInCcy2 === null && !isDefined(hedgeAmountInCcy1))
    ? null
    : hedgeAmountInCcy1 ?? hedgeAmountInCcy2;
}

const mapAkoTriggerFromBackend = (
  barrier: ResponseBarrier | null | undefined,
  previousBarrier: AccumulatorBarrier | undefined,
): AccumulatorBarrier | null | undefined => {
  const NO_CHANGE = undefined;
  const hasNewValue = isDefined(barrier);
  const hasPreviousValue = isDefined(previousBarrier);
  const isValueRemoved = barrier === null;

  if (isValueRemoved) {
    return null;
  }

  if (!hasNewValue) {
    return NO_CHANGE;
  }

  if (barrier.nature === null && !hasPreviousValue) {
    return NO_CHANGE; // TODO: what is the rule ?
  }

  return {
    level: (barrier.level ?? previousBarrier?.level) as number,
    nature: (barrier.nature ?? previousBarrier?.nature) as string,
    type: (barrier.type ?? previousBarrier?.type) as string,
  };
};

const getErrors = (errors: TcErrors<TradeCaptureAccumulatorLegPreviousValues>): PropertyErrors<IFxAccumulatorValues> =>
  clearUndefined<PropertyErrors<IFxAccumulatorValues>>({
    premiumDate: fromTcError(errors.premiumPaymentDate),
    amount: fromTcErrorAsString(errors.amount),
    leverage: fromTcErrorAsString(errors.leverage),
    strike: fromTcErrorAsString(errors.strike),
    akoTrigger: fromTcErrorAsString((errors.barrier as TcErrors<AccumulatorBarrier>)?.level), // TODO: handle the nested type for akotrigger error
    ekiTrigger: fromTcErrorAsString(errors.step2),
    target: fromTcErrorAsString(errors.target),
    firstFixingDate: fromTcError(errors.firstFixingDate),
    expiryDate: fromTcError(errors.expiryDate),
  });

const getWarnings = (
  warnings: TcWarnings<TradeCaptureAccumulatorLegPreviousValues>,
): PropertyWarnings<IFxAccumulatorValues> =>
  clearUndefined<PropertyWarnings<IFxAccumulatorValues>>({
    premiumDate: fromTcWarning(warnings.premiumPaymentDate),
    amount: fromTcWarningAsString(warnings.amount),
    leverage: fromTcWarningAsString(warnings.leverage),
    strike: fromTcWarningAsString(warnings.strike),
    akoTrigger: fromTcWarningAsString((warnings.barrier as TcWarnings<AccumulatorBarrier>)?.level), // TODO: handle the nested type for akotrigger warning
    ekiTrigger: fromTcWarningAsString(warnings.step2),
    target: fromTcWarningAsString(warnings.target),
    firstFixingDate: fromTcWarning(warnings.firstFixingDate),
    expiryDate: fromTcWarning(warnings.expiryDate),
  });

const getTradeCaptureErrors = (responseError: TradeCaptureAccumulatorErrors | null) => responseError?.legs?.[0] ?? {};

const getTradeCaptureWarnings = (responseWarning: TradeCaptureAccumulatorWarnings | null) =>
  responseWarning?.legs?.[0] ?? {};

const mapToPriceCurrency = (
  premiumPaymentCurrency: string | undefined,
  currencyPair: string | null,
): CurrencyChoice | undefined => {
  if (currencyPair === null) {
    if (isEmptyOrNotDefined(premiumPaymentCurrency)) {
      return undefined;
    }
    throw new Error('Should not have no currency pair with a premiumPaymentCurrency');
  }
  const [currency1, currency2] = currencyPair.split('/');
  switch (premiumPaymentCurrency) {
    case undefined:
      return undefined;
    case currency1:
      return 1;
    case currency2:
      return 2;
    default:
      throw new Error(`premiumPaymentCurrency:${premiumPaymentCurrency} does not match currencies`);
  }
};

const mapToCurrencyChoice = (
  amountCurrency: string | undefined,
  currencyPair: string | null,
): CurrencyChoice | undefined => {
  if (isEmptyOrNotDefined(amountCurrency)) {
    return undefined;
  }
  if (isEmptyOrNotDefined(currencyPair)) {
    throw new Error('Should not have no currency pair with a amountCurrency');
  }
  const currencies = currencyPair.split('/');
  return amountCurrency === currencies[0] ? 1 : 2;
};

const toStringMapper = (value: number) => value.toString();
const mapToString = (value?: number | null, prevValue?: number | null): string | null | undefined =>
  value === null ? null : mapOptional(toStringMapper)(value ?? prevValue);

function mapToHedgeType(value: TcAccumulatorHedgeType | null | undefined): HedgeType | undefined {
  if (value === 'None' || value === null) {
    return 'Live';
  }
  return value;
}

const mapToSchedule = (
  stateFixingCustomFields: Record<string, AccumulatorScheduleMetadata | undefined>,
  tcSchedule: TradeCaptureAccumulatorScheduleResponse | undefined,
  tcScheduleCustomFields: TradeCaptureAccumulatorScheduleCustomFieldResponse | undefined,
  tcErrors: ReadonlyArray<TcError<CalendarPreviousValue | null>> | undefined,
  _tcWarnings: ReadonlyArray<TcWarning<CalendarPreviousValue | null>> | undefined,
): AccumulatorSchedule | undefined => {
  const scheduleFixings = mapScheduleValues(stateFixingCustomFields, tcSchedule, tcScheduleCustomFields);
  const errors = extractErrorsFromTC(tcErrors);
  // const warnings: PropertyWarnings<AccumulatorScheduleValues> = {};

  return Object.entries(errors).reduce((previous, [fixingId, fieldErrors]) => {
    previous[fixingId] = {
      values: {
        ...scheduleFixings[fixingId]?.values,
        ...extractPreviousValues(fieldErrors),
      },
      inputs: {},
      errors: Object.entries(fieldErrors).reduce(
        (acc, [field, error]) => ({
          ...acc,
          [field]: fromTcError(error as any),
        }),
        {} as PropertyErrors<AccumulatorScheduleValues>,
      ),
      warnings: {},
      custom: stateFixingCustomFields[fixingId]?.custom ?? [],
    };
    return previous;
  }, scheduleFixings);
};

const extractPreviousValues = (
  errors: TcErrors<TradeCaptureAccumulatorScheduleItemResponse>,
): Partial<TradeCaptureAccumulatorScheduleResponse> =>
  Object.entries(errors).reduce(
    (previous, [fieldName, error]) => ({ ...previous, [fieldName]: previousValue(error as any) }),
    {},
  );

const fieldMapper: Record<
  keyof TradeCaptureAccumulatorScheduleItemRequest,
  keyof TradeCaptureAccumulatorScheduleItemResponse
> = {
  paymentDateString: 'paymentDate',
  amountString: 'amount',
  step2String: 'step2',
  fixingDateString: 'fixingDate',
  leverageAmountString: 'leverageAmount',
  strikeString: 'strike',
  stepUpString: 'stepUp',
  stepDownString: 'stepDown',
  strikeDownString: 'strikeDown',
  strikeUpString: 'strikeUp',
};

const extractErrorsFromTC = (tcErrors: ReadonlyArray<TcError<CalendarPreviousValue | null>> | undefined) =>
  (tcErrors ?? []).reduce((acc, error) => {
    const faultyValue = Object.entries(error.faultyValue).filter(([_id, object]) => isDefined(object)).reduce((acc, [id, object]) => {
      acc[id] = object
      return acc
    }, {} as Record<string, unknown>)

    const affectedLeg = Object.keys(faultyValue)[0];
    const affectedField = Object.keys(
      error.faultyValue[affectedLeg],
    )[0] as keyof TradeCaptureAccumulatorScheduleItemRequest;
    const previousFieldName = fieldMapper[affectedField];
    const previousTcValue = error.previousValue === null ? null : error.previousValue![affectedLeg][previousFieldName];
    const currLeg = acc[affectedLeg] || {};
    acc[affectedLeg] = {
      ...currLeg,
      [previousFieldName]: [
        ...(currLeg[previousFieldName] || []),
        {
          id: error.id,
          description: error.description,
          faultyValue: error.faultyValue[affectedLeg][affectedField],
          previousValue: previousTcValue,
        },
      ],
    };
    return acc;
  }, {} as Record<string, TcErrors<TradeCaptureAccumulatorScheduleItemResponse>>);

const mapScheduleValues = (
  stateFixingCustomFields: Record<string, AccumulatorScheduleMetadata | undefined>,
  tcSchedule: TradeCaptureAccumulatorScheduleResponse | undefined,
  tcScheduleCustomFields: TradeCaptureAccumulatorScheduleCustomFieldResponse | undefined,
): AccumulatorSchedule =>
  tcSchedule === undefined
    ? Object.entries(stateFixingCustomFields).reduce((previous, [key, custom]) => {
      if (custom === undefined) {
        return previous;
      }
      previous[key] = {
        values: {} as AccumulatorScheduleValues,
        inputs: {},
        errors: {},
        warnings: {},
        ...custom,
      };
      return previous;
    }, {} as AccumulatorSchedule)
    : Object.entries(tcSchedule).reduce((previous, [key, values]) => {
      previous[key] = {
        values: { ...values, ekiTrigger: values.step2 },
        custom:
          tcScheduleCustomFields?.[key]
            ?.map(field => camelize<ScheduleCustomFieldValues>(field))
            ?.filter(field => scheduleCustomFieldValues.includes(field)) ?? [],
        inputs: {},
        warnings: {},
        errors: {},
      };
      return previous;
    }, {} as AccumulatorSchedule);
