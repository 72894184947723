import { useCallback } from 'react';
import styled from 'styled-components';

export interface ITenorsProps {
  currentTenor: string;
  tenors: readonly string[];
  onSelect(tenor: string): void;
  maxTenor: string | undefined;
}

const baseSize = 26;

const TenorContainer = styled.div.attrs({ className: 'd-flex flex-wrap me-2' })`
  width: ${6 * baseSize}px;
`;
interface TenorProps {
  selected: boolean;
  enabled: boolean;
}

const Tenor = styled.span.attrs<TenorProps>(({ selected, enabled }) => ({
  className: `d-inline-block fw-bold text-center ${
    enabled ? 'cursor-pointer' : 'cursor-default'
  }  ${selected ? 'bg-info text-white' : 'text-primary'}`,
}))`
  width: ${2 * baseSize}px;
  height: ${baseSize}px;
  line-height: ${baseSize}px;
  ${(props: TenorProps) =>
    props.enabled
      ? `:hover {
        background-color: var(--bg-lvl4) !important;
        color: var(--bs-primary) !important;
      }
      `
      : `
        color: var(--bs-light) !important;
      `}
`;

interface ITenorItemProps {
  isCurrent: boolean;
  tenor: string;
  onSelect(tenor: string): void;
  enabled: boolean;
}
const TenorItem = ({ isCurrent, tenor, onSelect, enabled }: ITenorItemProps) => {
  const onSelectTenor = useCallback(() => onSelect(tenor), [tenor, onSelect]);
  return (
    <Tenor selected={isCurrent} enabled={enabled} onClick={enabled ? onSelectTenor : undefined}>
      {tenor}
    </Tenor>
  );
};

export const Tenors = ({ tenors, onSelect, currentTenor, maxTenor }: ITenorsProps) => {
  const max = tenors.findIndex(t => t === maxTenor || (maxTenor === '1Y' && t === '12M'));
  const alwaysEnableds = ['IMM1', 'IMM2', 'BMF'];
  return (
    <TenorContainer>
      {tenors.map((tenor, index) => (
        <TenorItem
          key={tenor}
          tenor={tenor}
          onSelect={onSelect}
          isCurrent={currentTenor === tenor}
          enabled={max === -1 || index <= max || alwaysEnableds.includes(tenor)}
        />
      ))}
    </TenorContainer>
  );
};
