import { createSelector } from '@reduxjs/toolkit';
import type { AppState } from '../model';
import { getProductsAccess, getUserInfo } from '../referenceData/referenceDataSelectors';
import { type FxOrderBulksState, StopLossOrderStatus } from './fxOrderBulkModel';

const selectSelf = (state: AppState): FxOrderBulksState => state.fxOrderBulks;

export const bulkOrderIds = createSelector([selectSelf], (state) => state.orderIds);
export const bulkOrderBdrIs = createSelector([selectSelf], (state) => Object.keys(state.orderByBdrId).sort());

export const bulkOrderBdrCurrencyPairs = createSelector([selectSelf, (_, bdrId: string) => bdrId], (state, bdrId) =>
  Object.keys(state.orderByBdrId[bdrId]).sort(),
);

export const bulkOrdersForBdrAndCurrencyPair = createSelector(
  [selectSelf, (_, bdrId: string) => bdrId, (_, __, currencyPair: string) => currencyPair],
  (state, bdrId, currencyPair) => (state.orderByBdrId[bdrId]?.[currencyPair] ?? []).map((order) => order.id),
);

export const bulkOrder = createSelector(
  [selectSelf, (_state, params: { orderId: string }) => params.orderId],
  (state, orderId) => state.orderById[orderId],
);

export const bulkExecutionStatus = createSelector([selectSelf], (state) =>
  Object.values(state.orderById).reduce((status, order) => {
    if (
      [
        StopLossOrderStatus.VALIDATING,
        StopLossOrderStatus.INVALID,
        StopLossOrderStatus.PENDING,
        StopLossOrderStatus.FAILED,
      ].includes(status)
    ) {
      return status;
    }

    if (
      [
        StopLossOrderStatus.VALIDATING,
        StopLossOrderStatus.INVALID,
        StopLossOrderStatus.PENDING,
        StopLossOrderStatus.FAILED,
      ].includes(order.status)
    ) {
      return order.status;
    }

    if (
      (status === StopLossOrderStatus.VALID || status === StopLossOrderStatus.SUCCESS) &&
      order.status === StopLossOrderStatus.SUCCESS
    ) {
      return StopLossOrderStatus.SUCCESS;
    }

    return status;
  }, StopLossOrderStatus.VALID),
);

// OK, there are selectors in referenceDataSelectors.ts
// but without reselect => triggered after any action
export const canImportStopLossBulk = createSelector([getProductsAccess], (productAccess) => productAccess.stopLoss);
export const isReadOnlyStopLoss = createSelector([getUserInfo], (userInfo) => !userInfo.canTrade);
