import type { SavedWorkspace } from 'api/workspaceService/model';
import { logger } from 'logging/logger';
import { type Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { extract } from 'utils/object';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';
import { updater } from './updater';

export const workspaceServiceEndPoint = 'api/userdata/workspace/';

function endPoint(workspaceId: string | undefined) {
  return workspaceId !== undefined && !workspaceId.includes('@')
    ? `${workspaceServiceEndPoint}${workspaceId}`
    : workspaceServiceEndPoint;
}

export interface IWorkspaceServiceApi {
  timeout: number;
  save(workspace: SavedWorkspace, workspaceId?: string): Observable<HttpCall<void>>;
  get(workspaceId?: string): Observable<SavedWorkspace | null>;
}

export function workspaceServiceApi(http: ISgmeHttp): IWorkspaceServiceApi {
  return {
    save(workspace: SavedWorkspace, workspaceId?: string) {
      return http.post<void>(endPoint(workspaceId), workspace);
    },
    get(workspaceId?: string) {
      return http.getJSON<SavedWorkspace>(endPoint(workspaceId)).pipe(
        map(extract('payload')),
        map(updater),
        catchError((err) => {
          logger.logError('Loading saved workspace failed {message_s}', err.message);
          return of(null);
        }),
      );
    },
    timeout: 10_000,
  };
}
