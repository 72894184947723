import { configureStore } from '@reduxjs/toolkit';
import { createFxApi } from 'api';
import { crashStatus } from 'circuitBreaker';
import { makeEpicMiddleware } from 'epics/makeMiddleware';
import { metaSelectors } from 'epics/metaSelectors';
import type { Store } from 'redux';
import { circuitBreakerMiddleware } from 'redux-middlewares/circuitBreakerMiddleware';
import thunk from 'redux-thunk';
import type { DispatchExt, ExtraArgument } from 'state';
import { type Action, actionCreators } from 'state/actions';
import { appBootstrapped } from 'state/globalActions';
import type { AppState } from 'state/model';
import { rootReducer } from 'state/rootReducer';
import { selectors } from 'state/selectors';
import { circuitBreakerTimer } from 'utils/Timer/Timers';
import { v4 as getNewGuid } from 'uuid';
import { isDev } from '../../configuration';
import { sgmeHttp } from './http';
import {
  autosendHelpRequestMiddleware,
  catchMiddleware,
  crashOnActionMiddleware,
  logActionMiddleware,
  rememberLastActionsMiddleware,
} from './reduxMiddlewares';
import { matomoHandlerInitializer } from './reduxMiddlewares/matomoHandler';
import { sharedWorkspaceMiddleware } from './reduxMiddlewares/sharedWorkspaceMiddleware';
import { getStreams } from './streams';

let _store: Store<AppState> & DispatchExt;

const thunkExtraArgument: ExtraArgument = {
  selectors,
  metaSelectors,
  actionCreators,
  getNewGuid,
};

const lastActions: Action[] = [];
export function getLastActions(): readonly Action[] {
  return [...lastActions].reverse();
}

export function getStore() {
  if (_store === undefined) {
    // think twice before changing order
    const fxApi = createFxApi(sgmeHttp);
    const epicMiddleware = makeEpicMiddleware(fxApi);

    const middlewares = [
      circuitBreakerMiddleware(circuitBreakerTimer(crashStatus)),
      catchMiddleware,
      thunk.withExtraArgument(thunkExtraArgument),
      epicMiddleware,
      logActionMiddleware,
      rememberLastActionsMiddleware(lastActions),
      autosendHelpRequestMiddleware(isDev),
      crashOnActionMiddleware,
      matomoHandlerInitializer,
      sharedWorkspaceMiddleware,

      // impossible to register a RTK query middleware like this example
      // fxOrderBulkApi.middleware,
    ];

    _store = configureStore({
      middleware: middlewares,
      reducer: rootReducer,
    });

    // impossible to register a RTK query middleware
    // setupListeners(_store.dispatch);

    import('epics').then(({ makeRootEpic }) => {
      epicMiddleware.run(makeRootEpic(getStreams()));
      _store?.dispatch(appBootstrapped());
    });
  }

  return _store;
}
