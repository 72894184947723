import { type ReactNode, useCallback, useState } from 'react';

export interface CacheProofLinkProps {
  href: string;
  fileName?: string;
  className?: string;
  children: ReactNode;
  onClick?: () => void;
}

export const CacheProofLink: React.FunctionComponent<CacheProofLinkProps> = ({
  href,
  fileName,
  className,
  children,
  onClick,
}) => {
  const [timeStamp, setTimeStamp] = useState(Date.now());
  const onClickCb = useCallback(() => {
    setTimeStamp(Date.now());

    if (onClick && typeof onClick === 'function') {
      onClick();
    }
  }, [onClick]);

  return (
    <a
      href={`${href}?${timeStamp}`}
      download={fileName}
      className={className}
      target="_blank"
      rel="noreferrer noopener"
      onClick={onClickCb}
    >
      {children}
    </a>
  );
};
