import type { Reducer } from 'redux';
import type { Action } from 'state/actions';
import { addPriceRecorded } from 'state/share/priceRecordReducerHelper';
import { strictEntries } from 'utils/object';
import { addKey, removeKey, updateKey } from 'utils/stateMap';
import { productPatcher } from '../../share/patchHelper';
import {
  type FxAmericanForwardState,
  type FxAmericanForwardStateMap,
  emptyFxAmericanForwardState,
} from '../model/fxAmericanForwardProductModel';

const defaultFxAmericanForwardState: FxAmericanForwardStateMap = {};

export const fxAmericanForwardProductReducer: Reducer<FxAmericanForwardStateMap, Action> = (
  state = defaultFxAmericanForwardState,
  action,
): FxAmericanForwardStateMap => {
  switch (action.type) {
    case 'CLIENTWORKSPACE_TILE_RESTORED': {
      return action.savedTile.instrument === 'AmericanForward'
        ? addKey(state, action.tileId, {
            ...emptyFxAmericanForwardState,
            values: {
              ...emptyFxAmericanForwardState.values,
              productName: action.savedTile.productName,
            },
          })
        : state;
    }

    case 'CLIENTWORKSPACE_NEW_TILE_ADDED': {
      return action.instrument === 'AmericanForward'
        ? addKey(state, action.tileId, emptyFxAmericanForwardState)
        : state;
    }

    case 'AMERICAN_FORWARD_PROPERTIES_REMOVE_ERROR':
      return updateKey(state, action.quoteId, (americanForward) => {
        const errors = strictEntries(americanForward.errors)
          .filter(([errorKey]) => !action.keys.includes(errorKey))
          .reduce(
            (newErrors, [key, error]) => ({
              ...newErrors,
              [key]: error,
            }),
            {},
          );
        return {
          ...americanForward,
          errors,
        };
      });
    case 'FIELD_TOOLTIP_SEEN':
      return action.instrument !== 'AmericanForward'
        ? state
        : updateKey(state, action.quoteId, ({ errors, warnings }) => ({
            errors: updateKey(errors, action.field, () => ({ userNotified: true })),
            warnings: updateKey(warnings, action.field, () => ({ userNotified: true })),
          }));
    case 'TILE_EXECUTION_OVERLAY_HIDDEN':
      return updateKey(state, action.quoteId, () => ({
        currentExecutionId: null,
      }));

    case 'CLIENTWORKSPACE_TILE_DUPLICATED':
    case 'CLIENTWORKSPACE_TILE_REOPENED': {
      return action.instrument === 'AmericanForward'
        ? addKey(state, action.tileId, {
            ...emptyFxAmericanForwardState,
            values: {
              ...emptyFxAmericanForwardState.values,
              productName: 'FxAmericanForward',
            },
          })
        : state;
    }

    case 'CLIENTWORKSPACE_TILE_INSTRUMENT_CHANGED': {
      if (action.instrument === 'AmericanForward') {
        const newState: FxAmericanForwardState = {
          ...emptyFxAmericanForwardState,
          values: {
            ...emptyFxAmericanForwardState.values,
            productName: 'FxAmericanForward',
          },
        };
        if (state[action.tileId] !== undefined) {
          return addKey(removeKey(state, action.tileId), action.tileId, newState);
        }

        return addKey(state, action.tileId, newState);
      }
      return removeKey(state, action.tileId);
    }
    case 'CLIENTWORKSPACE_TILE_DELETED': {
      return removeKey(state, action.tileId);
    }
    case 'AMERICAN_FORWARD_TILE_RESET':
      return updateKey(state, action.quoteId, ({ displayPriceType }) => ({
        ...emptyFxAmericanForwardState,
        displayPriceType,
      }));

    case 'AMERICAN_FORWARD_RFS_STARTED': {
      return updateKey(state, action.cashId, () => ({
        currentStreamId: action.streamId,
        lastStreamError: null,
        rfsStartedAt: action.rfsStartedAt,
        priceRecords: [],
      }));
    }

    case 'AMERICAN_FORWARD_EXECUTION_SENT': {
      return updateKey(state, action.americanForwardId, ({ currentStreamId }) => ({
        lastExecutedQuoteId: action.quoteId,
        currentExecutionId: action.executionId,
        currentStreamId: null,
        lastStreamId: currentStreamId,
        lastStreamError: null,
        warnings: {},
      }));
    }

    case 'AMERICAN_FORWARD_SOLVING_TYPE_CHANGED': {
      return updateKey(state, action.quoteId, (americanForward) => ({
        ...americanForward,
        solvingType: action.solvingType,
      }));
    }

    case 'AMERICAN_FORWARD_PROPERTIES_CHANGED': {
      return updateKey(state, action.quoteId, (americanForward) => ({
        inputs: {
          ...americanForward.inputs,
          ...action.patch,
        },
        lastStreamError: null,
      }));
    }
    case 'AMERICAN_FORWARD_STREAM_CANCELED':
    case 'AMERICAN_FORWARD_STREAM_TERMINATED':
      return updateKey(state, action.americanForwardId, ({ currentStreamId }) => {
        if (currentStreamId !== action.streamId) {
          return null;
        }
        return {
          currentStreamId: null,
          lastStreamId: action.shouldKeepAsExpired ? currentStreamId : null,
          lastStreamError: null,
        };
      });
    case 'AMERICAN_FORWARD_PROPERTIES_REQUESTED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: true,
        currentSessionId: action.sessionId,
      }));

    case 'AMERICAN_FORWARD_PROPERTIES_RECEIVED': {
      return productPatcher(state, action, (americanForward) => ({
        propertiesRequestError: null,
        values: {
          ...americanForward.values,
        },
      }));
    }
    case 'AMERICAN_FORWARD_RFS_CLEAR_ERROR':
      return updateKey(state, action.quoteId, () => ({
        currentStreamId: null,
        lastStreamError: null,
      }));
    case 'AMERICAN_FORWARD_STREAM_LAST_REMOVED':
      return updateKey(state, action.americanForwardId, () => ({
        lastStreamId: null,
      }));
    case 'AMERICAN_FORWARD_STREAM_STARTED':
      return updateKey(state, action.americanForwardId, () => ({
        priceRecords: [],
        rfsStartedAt: action.rfsStartedAt,
        currentStreamId: action.streamId,
        lastStreamError: null,
      }));
    case 'AMERICAN_FORWARD_STREAM_FAILED':
      return updateKey(state, action.americanForwardId, () => ({
        currentStreamId: null,
        lastStreamId: null,
        lastStreamError: action.error,
      }));
    case 'AMERICAN_FORWARD_PROPERTIES_REQUEST_FAILED':
      return updateKey(state, action.quoteId, () => ({
        propertiesRequested: false,
        propertiesRequestError: action.error,
      }));

    case 'AMERICAN_FORWARD_NOTIONAL_CURRENCY_CHANGED': {
      return updateKey(state, action.quoteId, (americanForward) => ({
        values: {
          ...americanForward.values,
          amountCurrency: action.currencyChoice,
        },
      }));
    }

    case 'AMERICAN_FORWARD_DISPLAY_PRICE_TYPE_CHANGED':
      return updateKey(state, action.quoteId, (_) => ({
        displayPriceType: action.displayPriceType,
      }));

    case 'AMERICAN_FORWARD_HEDGE_CURRENCY_CHANGED': {
      return updateKey(state, action.quoteId, (americanForward) => ({
        values: {
          ...americanForward.values,
          hedgeCurrency: action.currencyChoice,
        },
      }));
    }
    case 'RECORD_PRICE_SUCCEEDED':
    case 'RECORD_PRICE_FAILED':
      if (action.instrument !== 'AmericanForward') {
        return state;
      }
      return updateKey(
        state,
        action.tileId,
        addPriceRecorded({
          priceRecord: {
            error: action.error,
            prices: action.error === true ? undefined : action.prices,
            timestamp: action.timestamp,
          },
        }),
      );

    default:
      return state;
  }
};
