import type { Thunk } from 'state';
import type { AmericanForwardTradeCapturePatch } from '../../../epics/metaSelectors/americanForward/tradeCaptureFromBackend';

export function americanForwardPropertiesReceivedThunk(
  quoteId: string,
  patch: AmericanForwardTradeCapturePatch,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    if (
      !sl.isTilePresent(state, quoteId) ||
      sl.getQuoteInstrument(state, quoteId) !== 'AmericanForward' // todo refacto
    ) {
      return;
    }

    const { idVersion, isReadyToPrice, isPriceObsolete, isProductObsolete, ...receivedPatch } = patch;

    // If we have a stream and product is obsolete, we clear it so that the user doesn't see the expired price while waiting for new price
    const currentStreamId = sl.getAmericanForwardCurrentRfsStreamId(state, quoteId);
    if (currentStreamId !== null && isProductObsolete) {
      dispatch(ac.americanForwardStreamCanceled(quoteId, currentStreamId, false));
    }

    // If we have an expired stream and the price is obsolete, we clear it because the price needs to be updated after the properties change
    const lastStreamId = sl.getAmericanForwardLastStreamId(state, quoteId);
    if (lastStreamId !== null && isPriceObsolete) {
      dispatch(ac.americanForwardStreamLastRemoved(quoteId, lastStreamId));
    }

    dispatch(
      ac.americanForwardPropertiesReceived(
        quoteId,
        receivedPatch,
        idVersion,
        isReadyToPrice,
        isPriceObsolete,
        isProductObsolete,
      ),
    );

    const currencyPair = receivedPatch.values.currencyPair;
    if (currencyPair !== undefined && currencyPair !== sl.getAmericanForwardCurrencyPairInput(state, quoteId).value) {
      dispatch(ac.espStreamRestartThunk(quoteId, currencyPair, undefined, undefined));
    }
  };
}
