import { Tooltip } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import { OrderRow } from './OrderRow';

type OrderTableProps = {
  orderIds: string[];
  bdrId?: string;
  currencyPair?: string;
  isCompact?: boolean;
};

export const OrderTable = (props: OrderTableProps) => {
  const { orderIds, bdrId, currencyPair, isCompact = false } = props;

  return (
    <table
      className="table table-sm table-bordered table-thead-bordered table-body-bordered table-striped table-hover bg-lvl1"
      data-e2e={
        bdrId === undefined || currencyPair === undefined
          ? 'order-table'
          : `order-table-${bdrId}-${currencyPair.replaceAll('/', '-')}`
      }
    >
      <thead>
        <tr className="align-middle">
          <th>
            <FormattedMessage id="table.header.status" />
          </th>

          <th>
            <FormattedMessage id="table.header.orderId" />
          </th>

          {!isCompact && (
            <>
              <th>
                <FormattedMessage id="table.header.bdr" />
                <Tooltip contents={'Account ID'}>
                  <i className="icon icon-sm ps-2">info</i>
                </Tooltip>
              </th>
              <th>
                <FormattedMessage id="table.header.currencyPair" />
              </th>
            </>
          )}

          <th>
            <FormattedMessage id="table.header.way" />
          </th>

          <th colSpan={2}>
            <FormattedMessage id="table.header.notional" /> (<span className="text-azure">ccy1</span> /{' '}
            <span className="text-buttercup">ccy2</span>)
          </th>

          <th>
            <FormattedMessage id="table.header.limitPrice" />
          </th>

          <th>
            <FormattedMessage id="table.header.expiry" />
          </th>

          <th>
            <FormattedMessage id="table.header.trigger" />
          </th>

          <th>
            <FormattedMessage id="table.header.emails" />
          </th>
        </tr>
      </thead>

      <tbody className="bg-lvl1">
        {orderIds.map((bulkOrderId) => (
          <OrderRow key={bulkOrderId} orderId={bulkOrderId} isCompact={isCompact} />
        ))}
      </tbody>
    </table>
  );
};
