import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import type { Dispatch } from 'redux';
import { toUtc } from '../../../utils/dateFormats';
import type { AppState } from '../../model';
import { postSubmitOrder } from '../api/submit';
import type { PostValidateOrderBody } from '../api/validate';
import { type StopLossOrder, StopLossOrderStatus } from '../fxOrderBulkModel';
import { stopLossOrderInvalid, stopLossOrderStatusChanged, stopLossOrderSuccess } from '../fxOrderBulkSlice';
import { extractErrorsFromValidation } from '../fxOrderBulksUtilities';

export const submitStopLossOrdersThunk = createAsyncThunk(
  'fxOrderBulks/submitStopLossOrders',
  async (_, { getState, dispatch }) => {
    const state = getState() as AppState; // TODO: how to avoid the cast ?

    const allStopLossOrders = Object.values(state.fxOrderBulks.orderById);

    const bearer = window.sgwtConnect.getAuthorizationHeader();

    if (bearer === null) {
      console.error('error');
      return;
    }

    await Promise.all(allStopLossOrders.map((order) => submitOrder(order, bearer, dispatch)));
  },
);

const submitOrder = async (order: StopLossOrder, bearer: string, dispatch: Dispatch) => {
  const body = {
    isGtc: order.expiryDate === undefined,
    expiryDay: order.expiryDate !== undefined ? format(order.expiryDate, 'yyyy-MM-dd') : undefined,
    expiryTime: order.expiryDate !== undefined ? format(toUtc(order.expiryDate), 'HH:mm:ss') : undefined,
    bdrId: order.bdrId,
    ccyPair: order.currencyPair,
    way: order.way === 'Ask' ? 'buy' : 'sell',
    amountInCcy1: order.currencyIndex === 1 ? order.notional : undefined,
    amountInCcy2: order.currencyIndex === 2 ? order.notional : undefined,
    limitPrice: String(order.limitPrice),
    base64Emails: btoa(order.notificationEmails.join(';')),
    triggerMode: 'Spot',
    localTimeWithOffSet: 0, // TODO: do same thing from orderToBackendPayload ? -> orderClockOffset !== undefined && dateNow !== undefined ? dateNow.getTime() + orderClockOffset : 0,
  } satisfies PostValidateOrderBody;

  dispatch(stopLossOrderStatusChanged({ orderId: order.id, status: StopLossOrderStatus.PENDING }));

  const validation = await postSubmitOrder(body, bearer).catch((error) => {
    console.error('error during the order submit', error);
    dispatch(stopLossOrderStatusChanged({ orderId: order.id, status: StopLossOrderStatus.FAILED }));
    return undefined;
  });

  if (validation === undefined) {
    // error already dispatched
    return;
  }

  validation.type === 'success'
    ? dispatch(stopLossOrderSuccess({ orderId: order.id, omsId: validation.orderId }))
    : dispatch(
        stopLossOrderInvalid({
          orderId: order.id,
          errors: extractErrorsFromValidation(validation.errors),
        }),
      );

  return validation;
};
