import { isDefined } from '@sgme/fp';
import type { Thunk } from 'state';
import type { IFxCashInputs, IFxCashValues } from 'state/fxCashs/fxCashsModel';
import type { CurrencyChoice } from 'state/share/productModel/litterals';
import { fieldData } from 'utils/fieldSelectors';
import { isMaturityDate } from '../../../components/UserPreferences/DefaultMaturityDate';
import { SPOT_TENOR } from '../../../components/share/tenors';
import type { AppState } from '../../model';
import type { Selectors } from '../../selectors';

/**
 * @deprecated delegate to meta selector
 */
export function cashAmountChanged(cashId: string, amount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const { data: amountCurrency } = fieldData(sl.getCashAmountCurrency(getState(), cashId));
    dispatch(ac.cashPropertiesChanged(cashId, { amount, amountCurrency }));
  };
}

export function cashCurrencyChanged(cashId: string, amount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();

    const { data: oldCurrency } = fieldData(sl.getCashAmountCurrency(state, cashId));
    const { data: oldAmount } = fieldData(sl.getCashAmount(state, cashId));
    const amountCurrency = ((oldCurrency % 2) + 1) as CurrencyChoice;

    // TODO: oldAmount type is string | number | null
    // -> how did it worked ?
    if (isDefined(oldAmount) && (typeof oldAmount !== 'string' || oldAmount.length > 0)) {
      dispatch(ac.cashPropertiesChanged(cashId, { amount, amountCurrency }));
    } else {
      dispatch(ac.cashAmountCurrencyMaskChanged(cashId, amountCurrency));
    }
  };
}

export function cashCurrencyPairChangeThunk(cashId: string, currencyPair: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    dispatch(ac.espTileStreamUnsubscribeThunk(cashId));

    const state = getState();

    const defaultValues = sl.isTileFirstCurrencyPairSelection(state, cashId)
      ? initCashTile(state, sl)
      : {
          amount: null,
          amountCurrency: fieldData(sl.getCashAmountCurrency(state, cashId)).data,
        };

    dispatch(ac.cashPropertiesChanged(cashId, { currencyPair, ...defaultValues }));
  };
}

const initCashTile = (state: AppState, sl: Selectors) => {
  const defaultMaturity = sl.getUserDefaultMaturityDate(state);

  const maturityDate = defaultMaturity !== null ? (isMaturityDate(defaultMaturity) ? defaultMaturity : null) : null;

  const maturityDateTenor =
    defaultMaturity !== null && defaultMaturity.length > 0
      ? !isMaturityDate(defaultMaturity)
        ? defaultMaturity
        : null
      : SPOT_TENOR;

  return {
    amount: sl.getUserDefaultNotional(state),
    amountCurrency: 1 as CurrencyChoice,
    maturityDate,
    maturityDateTenor,
  } satisfies Partial<IFxCashInputs>;
};

export function cashPropertiesDirtyStateChangedThunk(
  cashId: string,
  fieldName: keyof IFxCashValues,
  isDirty: boolean,
): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    /**
     * @todo change selector
     */
    const { dirtyFields } = sl.getCashState(getState(), cashId);

    if (isDirty && !dirtyFields.includes(fieldName)) {
      dispatch(ac.cashPropertiesDirty(cashId, fieldName));
    } else if (!isDirty && dirtyFields.includes(fieldName)) {
      dispatch(ac.cashPropertiesPristine(cashId, fieldName));
    }
  };
}
