import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { AmericanForwardTradeCapturePatch } from '../../../epics/metaSelectors/americanForward/tradeCaptureFromBackend';
import type { AmericanForwardProductName } from '../../../state/fxAmericanForward/model/fxAmericanForwardProductModel';
import { extract } from '../../../utils/object';
import type { ISgmeHttp } from '../../../utils/sgmeHttp';
import type {
  TradeCaptureAmericanForwardRequestWrapper,
  TradeCaptureAmericanForwardResponseWrapper,
} from './tradeCaptureModel';

export interface TradeCaptureApiRequest {
  productType: AmericanForwardProductName;
  sessionId: string;
  tileId: string;
  request: TradeCaptureAmericanForwardRequestWrapper;
}

export interface TradeCaptureBatchedResponses {
  [americanForwardId: string]: TradeCaptureAmericanForwardResponseWrapper;
}

export interface TradeCaptureBatchedPatch {
  americanForwardId: string;
  patch: AmericanForwardTradeCapturePatch | null;
}

export interface TradeCaptureAmericanForwardApi {
  timeout: number;
  createNewBatchVersion(queries: readonly TradeCaptureApiRequest[]): Observable<TradeCaptureBatchedResponses>;
}

export function tradeCaptureApi(http: ISgmeHttp): TradeCaptureAmericanForwardApi {
  return {
    createNewBatchVersion(queries) {
      return http
        .post<TradeCaptureBatchedResponses>('api/tradeCaptureBatch/FxAmericanForward', queries)
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
