import clsx from 'clsx';
import * as e2e from 'components/Form/Inputs/e2e';
import { TabIdContext, TabIdProvider } from 'components/NavMenu';
import { RefToggle } from 'components/share/RefToggle';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { Popover } from 'reactstrap';
import styled from 'styled-components';
import { useAppSelector } from '../../../state';
import { actionCreators } from '../../../state/actions';
import { selectors } from '../../../state/selectors';
import { getClientName } from '../../../state/share/clientSelectors';
import { PrimeBrokerBadge } from '../PrimeBrokerBadge';
import { TabPopover } from '../TabPopover';
import { CloseTabButton } from './CloseTabButton';
import { TabName } from './TabName';

const TabItemContainer = styled.div`
  max-width: 285px;
`;

export interface TabItemProps {
  showClose: boolean;
}

export const TabItem = (props: TabItemProps) => {
  const { showClose } = props;

  const tabId = useContext(TabIdContext);

  const clientId = useAppSelector((state) => selectors.getClientWorkspaceTab(state, tabId).clientId);
  const client = useAppSelector((state) => selectors.getClient(state, clientId));
  const clientName = useAppSelector((state) => getClientName(state, client));
  const isActive = useAppSelector((state) => selectors.getClientWorkspaceActiveTab(state) === tabId);
  const isShared = useAppSelector((state) => selectors.isTabShared(state, tabId));

  const primeBroker = client.primeBroker;

  const dispatch = useDispatch();

  const selectTab = () => dispatch(actionCreators.clientWorkspaceTabSwitched(tabId));

  return (
    <RefToggle<HTMLElement>>
      {(popover) => (
        <>
          <li
            className={clsx('nav-item nav-hover cursor-default', { 'bg-info': isShared })}
            role="tab"
            data-e2e={e2e.tab('workspace')}
            onClick={isActive ? undefined : selectTab}
          >
            <TabItemContainer className={clsx('nav-link p-1', { active: isActive, 'text-secondary': !isActive })}>
              <div className="d-flex justify-content-between">
                <TabName tabId={tabId} isEditable={isActive} />
                {showClose && <CloseTabButton tabId={tabId} />}
              </div>

              <div
                data-e2e={e2e.selector('client')}
                className={clsx('d-flex justify-content-between', { 'text-white': isShared })}
                ref={isActive ? popover.ref : undefined}
                onClick={isActive ? popover.open : undefined}
              >
                <div className="flex-grow-1 text-truncate ps-2" data-e2e={e2e.label('clientName')}>
                  <span data-e2e={e2e.label('clientName')}>{clientName}</span>

                  <PrimeBrokerBadge
                    primeBroker={primeBroker}
                    emphasis={isActive}
                    showTooltip={isActive && !popover.isOpen}
                  />
                </div>

                {!isActive ? null : (
                  <button type="button" className="btn btn-flat-secondary btn-icon btn-sm p-0 ms-1">
                    <i className="icon px-2">more_vert</i>
                  </button>
                )}
              </div>
            </TabItemContainer>
          </li>

          {popover.target !== null && (
            <Popover
              placement="bottom"
              trigger="legacy"
              isOpen={popover.isOpen}
              target={popover.target}
              toggle={popover.close}
            >
              {/* popover uses a portal which breaks context propagation */}
              <TabIdProvider value={tabId}>
                <TabPopover close={popover.close} />
              </TabIdProvider>
            </Popover>
          )}
        </>
      )}
    </RefToggle>
  );
};
