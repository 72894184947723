import { useCallback, useContext, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { IntlComponentBoundary } from "utils/i18n/IntlComponentBoundary";

import en from "../locales/en.json";
import fr from "../locales/fr.json";

import { useSgwtWidgets } from "@sgwt/sgwt-widgets-react";
import * as e2e from "components/Form/Inputs/e2e";
import { CacheProofLink } from "components/share/CacheProofLink";
import {
  INSTRUMENT_TO_EXAMPLE,
  type InstrumentExample,
} from "components/share/import/typings";
import { FileImportInput } from "./FileImportInput";
import { useSelector } from "react-redux";
import type { AppState } from "state/model";
import { getAccuTypeAccumulator } from "state/fxAccumulators/selectors";
import { QuoteIdContext } from "components/contexts";
import type { AccumulatorType } from "epics/metaSelectors/accumulator/tradeCaptureToBackend";
import type { TargetAccuType } from "state/fxAccumulators/fxAccumulatorsModel";

export interface FileImportProps {
  isOpen: boolean;
  close: () => void;
  onImport: (file: File) => Promise<ImportResult>;
  instrument: InstrumentExample;
}

export type ImportResult = "SUCCESS" | "FAILURE";

export interface FileImportContent {
  title: JSX.Element;
  instruction: React.ReactNode;
}

interface InstructionsProps {
  instrument: InstrumentExample;
}

type InstructionsType = {
  instrument: InstrumentExample;
};

const Instructions: React.FunctionComponent<InstructionsProps> = ({
  instrument,
}: InstructionsType) => {
  const { sgwtHelpCenter } = useSgwtWidgets();
  const quoteId = useContext(QuoteIdContext);

  const accuType = useSelector((state: AppState) =>
    getAccuTypeAccumulator(state, quoteId, instrument)
  );

  const instrumentExamples = getInstrumentExamples(instrument, accuType);

  const helpCenterTopic = `${instrument.toLowerCase()}.import`;
  const openHelp = useCallback(
    () => sgwtHelpCenter?.topic(helpCenterTopic),
    [sgwtHelpCenter, helpCenterTopic]
  );

  const prefix = INSTRUMENT_TO_EXAMPLE[instrumentExamples];

  const values = useMemo(
    () => ({
      help: (
        <a href="#" onClick={openHelp}>
          <FormattedMessage id="import.help" tagName="u" />
        </a>
      ),

      link: (
        <CacheProofLink
          href={`/samples/${prefix}Example.csv`}
          fileName={`SGME-FX-${prefix}.csv`}
        >
          <FormattedMessage id="import.link" tagName="u" />
        </CacheProofLink>
      ),
    }),
    [prefix, openHelp]
  );
  return (
    <div className="mb-4">
      <FormattedMessage id="import.message" values={values} />
    </div>
  );
};

const messagesMap = { en, fr };

type FileImportModalTypes = {
  isOpen: boolean;
  instrument: InstrumentExample;
  close: () => void;
  onImport: (file: File) => void;
};

export const FileImportModal: React.FunctionComponent<FileImportProps> = ({
  isOpen,
  instrument,
  close,
  onImport,
}: FileImportModalTypes) => {
  const [file, setFile] = useState<File | undefined>(undefined);

  const doImport = useCallback(async () => {
    if (file === undefined) {
      return;
    }

    await onImport(file);
    setFile(undefined);
  }, [file, onImport]);

  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <Modal isOpen={isOpen}>
        <ModalHeader toggle={close} tag="h4">
          <FormattedMessage id="import.title" />
        </ModalHeader>

        <ModalBody>
          <div className="mb-4">
            <Instructions instrument={instrument} />
          </div>
          <FileImportInput file={file} onFileChange={setFile} />
        </ModalBody>

        <ModalFooter>
          <button
            type="button"
            className="btn btn-link"
            onClick={close}
            data-e2e={e2e.button("cancel")}
          >
            <FormattedMessage id="modal.import.cancel" />
          </button>

          <button
            type="button"
            className="btn btn-primary"
            onClick={doImport}
            disabled={file === null}
            data-e2e={e2e.button("import")}
          >
            <FormattedMessage id="modal.import.import" />
          </button>
        </ModalFooter>
      </Modal>
    </IntlComponentBoundary>
  );
};

const getInstrumentExamples = (
  instrument: InstrumentExample,
  accuType: AccumulatorType | undefined
) => {
  if (instrument !== "TargetAccumulator") {
    return instrument;
  }
  return accuType as TargetAccuType;
};
