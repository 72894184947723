import type { Collection } from 'typings/utils';
import type { IFormData } from 'state/share/form';
import type { IFxTileMetadata } from 'state/tile/fxTileModel';
import type { CurrencyChoice, Side, BidAskPair } from 'state/share/productModel/litterals';
import type { CashProductName } from 'state/fxCashs/fxCashsModel';
import type { SwapProductName } from 'state/fxSwaps/fxSwapsModel';
import type { Patch } from 'state/share/patchModels';
import type { IFxExecutionState, IFxExecutionData } from 'state/executions/executionsStateModel';
import type { PredealCheck } from 'state/share/predealChecksModel';

export type BulkLineProduct = CashProductName | SwapProductName;

export const bulkLineTypes = ['Spot', 'Forward', 'Swap'] as const;
export type BulkLineType = typeof bulkLineTypes[number];
export type QuoteType = 'Tradeable' | 'Withdraw';
export const bulkLineTypeMap: Record<BulkLineProduct, BulkLineType> = {
  FxSpot: 'Spot',
  FxFwd: 'Forward',
  FxNdf: 'Forward',
  FxSwap: 'Swap',
  FxNdSwap: 'Swap',
  FxPredeliver: 'Swap',
  FxRollover: 'Swap',
};

/* eslint-disable no-shadow */
export enum Step {
  UserInput = 1,
  TradeNegotiation = 2,
  TradeConfirmation = 3,
}

export interface IFxBulksState {
  bulks: FxBulkStateMap;
  legs: FxBulkLegStateMap;
  streams: FxBulkStreamStateMap;
  splitLegs: FxBulkSplitLegStateMap;
  nettedLegs: FxBulkNettedLegStateMap;
}

export type IFxBulkStateMap = Collection<FxBulkState>;
export type FxBulkStateMap = Readonly<IFxBulkStateMap>;

export type FxBulksState = Readonly<IFxBulksState>;

export interface IFxBulkValues {
  currencyPair: string | null;
  amountCurrency: CurrencyChoice;
  currency1: string | null;
  currency2: string | null;
  valueDate: Date | null;
}

export interface IFxBulkInputs {
  currencyPair: string | null;
  amountCurrency: CurrencyChoice;
}

export const emptyFxBulkState: FxBulkState = {
  values: {
    currencyPair: null,
    amountCurrency: 1,
    currency1: null,
    currency2: null,
    valueDate: null,
  },
  errors: {},
  inputs: {},
  warnings: {},
  step: Step.UserInput,
  legs: [],
  selectedLegs: [],
  excludedLegs: [],
  lastLegIdRequested: 0,
  propertiesRequested: false,
  currentSessionId: null,
  propertiesRequestError: null,
  tradeCaptureIdVersion: null,
  currentStreamId: null,
  currentEspStreamId: null,
  isPriceable: false,
  lastStreamError: null,
  lastExecutedQuoteId: null,
  currentExecutionId: null,
  priceRecords: [],
  /*
   * Never used, but common meta data
   */
  rfsStartedAt: null,
  executionId: null,
};

export interface IFxBulkInputs {
  currencyPair: string | null;
  amountCurrency: CurrencyChoice;
}
export type IFxBulkProperties = IFormData<IFxBulkValues, IFxBulkInputs>;
export interface IFxBulkMetadata extends IFxTileMetadata {
  step: Step;
  legs: readonly string[];
  selectedLegs: readonly string[];
  excludedLegs: readonly string[];
  lastLegIdRequested: number;
  executionId: string | null;
}
export type FxBulkState = Readonly<IFxBulkMetadata & IFxBulkProperties>;

export interface ISimpleLeg {
  id: string;
  product: CashProductName;
  way: Side;
  amount: number;
  paymentDate: string;
  paymentDateTenor: string;
}

export interface ILegMargin {
  spotMargin: number;
  forwardMargin: number;
}

export interface IMargedLeg extends ISimpleLeg {
  margin: ILegMargin | null;
}

interface IFxBulkStreamAwaitingState {
  status: 'AWAITING';
}
interface IFxBulkStreamStreamingState {
  status: 'PRICING';
  quote: Readonly<IQuote>;
  initialRfsWindow: number;
  tiering: string | null;
}

export type IFxBulkStreamState = IFxBulkStreamAwaitingState | IFxBulkStreamStreamingState;

export type FxBulkStreamState = Readonly<IFxBulkStreamState>;

type IFxBulkStreamStateMap = Collection<FxBulkStreamState>;

export type FxBulkStreamStateMap = Readonly<IFxBulkStreamStateMap>;

export type FxBulkLegStateMap = Collection<FxBulkLegState>;
export type FxBulkLegState = IFormData<IFxBulkLegValues, FxBulkLegInputs>;

export type FxBulkSplitLegStateMap = Collection<ISimpleLeg>;
export type FxBulkNettedLegStateMap = Collection<IMargedLeg>;

export interface FxBulkLegInputs {
  product: BulkLineProduct;
  isUneven: boolean;
  way: Side;
  amount: string | null;
  amountCurrency: CurrencyChoice | null;
  farAmount: string | null;
  paymentDate: string | null;
  paymentDateTenor: string | null;
  farPaymentDate: string | null;
  farPaymentDateTenor: string | null;
}

export interface IFxBulkLegValues {
  product: BulkLineProduct | null;
  isUneven: boolean | null;
  way: Side | null;
  currency1: string | null;
  currency2: string | null;
  amount: number | null;
  farAmount: number | null;
  paymentDate: string | null;
  paymentDateTenor: string | null;
  farPaymentDate: string | null;
  farPaymentDateTenor: string | null;
}

export type FxBulkPatch = Patch<IFxBulkValues, IFxBulkLegValues>;

export const emptyFxBulkLegState: FxBulkLegState = {
  values: {
    product: null,
    isUneven: false,
    way: 'Buy',
    currency1: null,
    currency2: null,
    amount: null,
    farAmount: null,
    paymentDate: null,
    paymentDateTenor: null,
    farPaymentDate: null,
    farPaymentDateTenor: null,
  },
  errors: {},
  warnings: {},
  inputs: {},
};

export interface IQuoteLeg {
  spotWithoutMargin: BidAskPair;
  spotWithMargin: BidAskPair;
  forwardPoints: BidAskPair;
  forwardMarginPoints: BidAskPair;
  defaultMargin: BidAskPair;
  midAllInRate: number | null;
  date: string;
  dateTenor: string;
  amount: number;
  customerWay: Side;
  isForward: boolean;
}

export interface IQuote {
  quoteId: string;
  rfsWindow: number;
  frontTimestamp: Date;
  backTimestamp: number;
  negotiatedCurrency: string;
  legs: readonly IQuoteLeg[];
  nettedLegs: readonly IQuoteLeg[];

  /* not used at the moment  */
  quoteType: QuoteType;
  predealChecks: readonly PredealCheck[];
}

export interface IFxBulkExecutionData extends IFxExecutionData {
  instrument: 'Bulk';
  quote: IQuote;
}
export type FxBulkExecutionState = IFxExecutionState & IFxBulkExecutionData;
