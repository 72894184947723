import { isDefined, isEmpty } from '@sgme/fp';
import { createSelector } from 'reselect';
import { getClientIdByQuoteId } from 'state/clientWorkspace/selectors/clientWorkspaceTabsSelectors';
import type { AppState } from 'state/model';
import type { ProductName } from 'state/share/productModel';
import { getTileCurrencyPair } from 'state/tile/selectors';
import { mapOrDefault } from 'utils/optional';
import type { Predicate } from 'utils/predicates';
import type { Client, CurrencyPair } from './referenceDataModel';

export function getAvailableInstruments(state: AppState) {
  return state.referenceData.instruments;
}

export function getUserHasAccess(state: AppState) {
  return !isEmpty(getAvailableInstruments(state)) && Object.keys(getAllUserClients(state)).length !== 0;
}

export function getAllCcyPairs(state: AppState) {
  return state.referenceData.ccyPairs;
}

export function getUserClientById(state: AppState, clientId: string) {
  return state.referenceData.userClients[clientId];
}

export function getUserClientByName(state: AppState, accountName: string): Readonly<Client> | undefined {
  const clients = Object.values(getAllUserClients(state));
  return clients.find(({ companyName }) => companyName.trim() === accountName.trim());
}

export function getClient(state: AppState, clientId: string | null) {
  const getDefaultClient = createSelector(getAllUserClients, (clients) => clients[Object.keys(clients)[0]]);
  return clientId === null ? getDefaultClient(state) : getUserClientById(state, clientId);
}

export function getCompanyIdFromClientId(state: AppState, clientId: string) {
  const { companyId } = getUserClientById(state, clientId);
  return companyId;
}

export function getCompanyNameFromClientId(state: AppState, clientId: string) {
  const { companyName } = getUserClientById(state, clientId);
  return companyName;
}

export function getAllUserClients(state: AppState) {
  return state.referenceData.userClients;
}

export const getAllUserClientIds = createSelector(getAllUserClients, Object.keys);

export function getUserInfo(state: AppState) {
  return state.referenceData.userInfo;
}

export function getUserInfoUserType(state: AppState) {
  return getUserInfo(state).userType;
}

export function isUserInternalSales(state: AppState) {
  return getUserInfo(state).userType === 'Internal Sales';
}

export function isUserInternalTrader(state: AppState) {
  return getUserInfo(state).userType === 'Internal Trader';
}

export function getUserInfoCanTrade(state: AppState) {
  return getUserInfo(state).canTrade;
}

export function getUserInfoBdrId(state: AppState) {
  return getUserInfo(state).bdrId;
}

export function hasFxTcaAccess(state: AppState) {
  return getUserInfo(state).hasFxTcaAccess;
}

export function isInternalUser(state: AppState) {
  return isUserInternalSales(state) || isUserInternalTrader(state);
}

export function isDefaultTieringEnabled(state: AppState): boolean {
  return isUserInternalSales(state);
}

export function getFeatureToggles(state: AppState) {
  return state.referenceData.featureToggles;
}

export function getProductsAccess(state: AppState) {
  return state.referenceData.productsAccess;
}

export function getLocalMarketCcy(state: AppState) {
  return state.referenceData.localMarketsCcy;
}

export function isProductAuthorized(state: AppState, productName: ProductName) {
  const productsAccess = getProductsAccess(state);

  const authorization: Record<ProductName, boolean> = {
    FxSpot: productsAccess.spot,
    FxFwd: productsAccess.forward,
    FxNdf: productsAccess.forward && productsAccess.nonDeliverable,
    FxSwap: productsAccess.swap,
    FxNdSwap: productsAccess.swap && productsAccess.nonDeliverable,
    FxPredeliver: productsAccess.swap,
    FxRollover: productsAccess.swap,
    FxOption: productsAccess.option,
    FxOrder: productsAccess.takeProfit || productsAccess.stopLoss || productsAccess.callOrder,
    FxBulk: false,
    FxTargetAccumulator: productsAccess.targetAccu,
    FxForwardAccumulator: productsAccess.forwardAccu,
    FxAmericanForward: productsAccess.americanForward,
    // for now smartRfs is always authorized
    // as the underlying created product has specific access (option for now)
    FxSmartRfs: true,
  };

  return authorization[productName];
}

export function hasCashAndOptionProductAccess(state: AppState) {
  return (
    state.referenceData.productsAccess.spot === true ||
    state.referenceData.productsAccess.forward === true ||
    state.referenceData.productsAccess.swap === true ||
    state.referenceData.productsAccess.targetAccu === true ||
    state.referenceData.productsAccess.nonDeliverable === true ||
    state.referenceData.productsAccess.option === true
  );
}

export function getCurrencyPairDetails(state: AppState, pair: string): CurrencyPair | null {
  return state.referenceData.ccyPairs[pair] ?? null;
}

export function isCurrencyPairEspCompatible(state: AppState, currencyPair: string | null) {
  if (currencyPair === null) {
    return false;
  }
  const pairDetails = getCurrencyPairDetails(state, currencyPair);
  return isDefined(pairDetails) && !pairDetails.isInverted;
}

const doesCurrencyPairPass = (predicate: Predicate<CurrencyPair>) => {
  const optionalPredicate = mapOrDefault(predicate, false);
  return (state: AppState, currencyPair: string | null) => {
    if (currencyPair === null) {
      return false;
    }
    return optionalPredicate(getCurrencyPairDetails(state, currencyPair));
  };
};

export function isCurrencyPairInverted(state: AppState, currencyPair: string | null) {
  return doesCurrencyPairPass((pair) => pair.isInverted)(state, currencyPair);
}

export function isCurrencyPairNeverDeliverable(state: AppState, currencyPair: string | null) {
  return doesCurrencyPairPass((pair) => !pair.canBeDelivered && !pair.isCashDeliverable)(state, currencyPair);
}

export function getCurrencyPrecision(state: AppState, pair: string) {
  return getCurrencyPairDetails(state, pair)!.precision;
}

export function getCurrencyPrecisionForPps(state: AppState, pair: string) {
  const extendedPrecision = getCurrencyPrecision(state, pair);
  return extendedPrecision - 1;
}

const isCurrencyPairCompatible = (compatiblePredicate: Predicate<CurrencyPair>) => {
  const optionalPredicate = mapOrDefault(compatiblePredicate, false);
  return (state: Readonly<AppState>, ccyPair: string) => optionalPredicate(getAllCcyPairs(state)[ccyPair]);
};

export const isCurrencyPairOptionCompatible = isCurrencyPairCompatible(({ isOptionCurrency }) => isOptionCurrency);
export const isCurrencyPairAccumulatorCompatible = isCurrencyPairOptionCompatible;

const emptyArray: readonly string[] = [];

export function getCurrencyClosedDates(state: AppState, pair: string): readonly string[] {
  const pairDetails = getCurrencyPairDetails(state, pair);
  return pairDetails?.closedDates ?? emptyArray;
}

export function getEspLimit(state: AppState, currency: string): number | undefined {
  const { espLimits } = state.referenceData;
  return espLimits[currency];
}

export function getMissingEspLimits(state: AppState): readonly string[] {
  const { espLimits } = state.referenceData;
  return Object.entries(espLimits)
    .filter(([, limit]) => limit === undefined)
    .map(([ccy]) => ccy);
}

export function getTileMaxTenor(state: AppState, quoteId: string) {
  return getClient(state, getClientIdByQuoteId(state, quoteId)).maxTenor;
}

export function getTileMaxDate(state: AppState, quoteId: string): Date | undefined {
  const maxTenor = getTileMaxTenor(state, quoteId);
  const ccyPair = getTileCurrencyPair(state, quoteId).value;

  if (maxTenor === null || ccyPair === null) {
    return undefined;
  }
  return getCurrencyPairDetails(state, ccyPair)?.tenorDatesCache[maxTenor];
}
