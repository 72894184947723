import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import type { ISgmeHttp } from 'utils/sgmeHttp';

const url = 'api/PreTrade/Registrations';

export interface IPreTradeRegistrationsApi {
  startNewNegotiation(timestamp: string): Observable<string>;
  continueExistingNegotiation(negociationId: string, timestamp: string, dealDone?: boolean): Observable<void>;
}

export const preTradeRegistrationApi = (http: ISgmeHttp): IPreTradeRegistrationsApi => ({
  startNewNegotiation: (timestamp) =>
    http
      .post<number>(url, {
        timestamp,
      })
      .pipe(map(extract('payload')), map(String)),
  continueExistingNegotiation: (negotiationId, timestamp, dealDone) =>
    http
      .put<void>(`${url}/${negotiationId}`, {
        timestamp,
        dealDone,
      })
      .pipe(map(extract('payload'))),
});
