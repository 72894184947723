export const mapWithKey = <TValue, TOutput>(
  record: Record<string, TValue>,
  mapFn: (value: TValue) => TOutput,
): Record<string, TOutput> => {
  return Object.entries(record).reduce(
    (acc, [key, value]) => {
      acc[key] = mapFn(value);

      return acc;
    },
    {} as Record<string, TOutput>,
  );
};
