import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { TradeCaptureOptionResponseWrapper } from './tradeCapture/option/tradeCaptureOptionModel';

export interface ITradeCaptureSmartRfsApi {
  // todo-5153 do I need timeout ?
  timeout: number;
  create(sessionId: string, productStringRepresentation: string): Observable<TradeCaptureOptionResponseWrapper>;
}

export function smartRfsServiceApi(http: ISgmeHttp): ITradeCaptureSmartRfsApi {
  return {
    create(sessionId: string, productStringRepresentation: string) {
      const endpoint = `api/tradeCapture/${sessionId}/chatMessage`;
      return http
        .post<TradeCaptureOptionResponseWrapper>(endpoint, { productStringRepresentation })
        .pipe(map(extract('payload')));
    },
    // todo-5153 need this timeout ?
    timeout: 10_000,
  };
}
