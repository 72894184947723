import type { DisplayPriceType } from '../model/fxAmericanForwardProductModel';
import type {
  FxAmericanForwardInputs,
  FxAmericanForwardValues,
  PossibleSolvingPremium,
} from '../model/fxAmericanForwardProductModel';
import type { CurrencyChoice } from 'api/workspaceService/model';

export interface AmericanForwardFillReportReceived {
  type: 'AMERICAN_FORWARD_FILL_REPORT_RECEIVED';
  executionId: string;
  tradeIds: readonly string[];
}

export function americanForwardFillReportReceived(
  executionId: string,
  tradeIds: readonly string[],
): AmericanForwardFillReportReceived {
  return {
    type: 'AMERICAN_FORWARD_FILL_REPORT_RECEIVED',
    executionId,
    tradeIds,
  };
}

export interface AmericanForwardPropertiesChanged {
  type: 'AMERICAN_FORWARD_PROPERTIES_CHANGED';
  quoteId: string;
  patch: Partial<FxAmericanForwardInputs>;
  isInternalSales?: boolean;
}

export function americanForwardPropertiesChanged(
  quoteId: string,
  patch: Partial<FxAmericanForwardInputs>,
): AmericanForwardPropertiesChanged {
  return {
    type: 'AMERICAN_FORWARD_PROPERTIES_CHANGED',
    quoteId,
    patch,
  };
}

export interface AmericanOrderAmountCurrencyChanged {
  type: 'AMERICAN_FORWARD_NOTIONAL_CURRENCY_CHANGED';
  quoteId: string;
  currencyChoice: CurrencyChoice;
}

export function americanForwardNotionalCurrencyChanged(
  quoteId: string,
  currencyChoice: CurrencyChoice,
): AmericanOrderAmountCurrencyChanged {
  return {
    type: 'AMERICAN_FORWARD_NOTIONAL_CURRENCY_CHANGED',
    quoteId,
    currencyChoice,
  };
}

export interface AmericanOrderHedgeCurrencyChanged {
  type: 'AMERICAN_FORWARD_HEDGE_CURRENCY_CHANGED';
  quoteId: string;
  currencyChoice: CurrencyChoice;
}

export function americanForwardHedgeCurrencyChanged(
  quoteId: string,
  currencyChoice: CurrencyChoice,
): AmericanOrderHedgeCurrencyChanged {
  return {
    type: 'AMERICAN_FORWARD_HEDGE_CURRENCY_CHANGED',
    quoteId,
    currencyChoice,
  };
}

export interface AmericanForwardPropertiesRemoveError {
  type: 'AMERICAN_FORWARD_PROPERTIES_REMOVE_ERROR';
  quoteId: string;
  keys: ReadonlyArray<keyof FxAmericanForwardValues>;
}

export function americanForwardPropertiesRemoveError(
  quoteId: string,
  keys: ReadonlyArray<keyof FxAmericanForwardValues>,
): AmericanForwardPropertiesRemoveError {
  return {
    type: 'AMERICAN_FORWARD_PROPERTIES_REMOVE_ERROR',
    quoteId,
    keys,
  };
}

export interface AmericanForwardTileReset {
  type: 'AMERICAN_FORWARD_TILE_RESET';
  quoteId: string;
}

export function americanForwardTileReset(quoteId: string): AmericanForwardTileReset {
  return {
    type: 'AMERICAN_FORWARD_TILE_RESET',
    quoteId,
  };
}

export interface AmericanForwardSolvingTypeChanged {
  type: 'AMERICAN_FORWARD_SOLVING_TYPE_CHANGED';
  quoteId: string;
  solvingType: PossibleSolvingPremium;
}

export function americanForwardSolvingTypeChanged(
  quoteId: string,
  solvingType: PossibleSolvingPremium,
): AmericanForwardSolvingTypeChanged {
  return {
    type: 'AMERICAN_FORWARD_SOLVING_TYPE_CHANGED',
    quoteId,
    solvingType,
  };
}

export interface AmericanForwardDisplayPriceTypeChanged {
  type: 'AMERICAN_FORWARD_DISPLAY_PRICE_TYPE_CHANGED';
  quoteId: string;
  displayPriceType: DisplayPriceType;
}

export function americanForwardDisplayPriceTypeChanged(
  quoteId: string,
  displayPriceType: DisplayPriceType,
): AmericanForwardDisplayPriceTypeChanged {
  return {
    type: 'AMERICAN_FORWARD_DISPLAY_PRICE_TYPE_CHANGED',
    quoteId,
    displayPriceType,
  };
}
