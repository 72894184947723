import { button } from 'components/Form/Inputs/e2e';
import { TabIdContext, withTabId } from 'components/NavMenu';
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../../state';
import { type TabType, clientWorkspaceTabTypeChangedThunk } from '../../../state/clientWorkspace';
import { canImportStopLossBulk } from '../../../state/fxOrderBulks/fxOrderBulksSelectors';
import { connectChangeTabTypeButton } from './connect';

export interface ChangeTabTypeButtonProps {
  tabType: TabType;
  toggleTabType(): void;
  close(): void;
}

export const ChangeTabTypeButtonRaw: React.FunctionComponent<ChangeTabTypeButtonProps> = ({ tabType, close }) => {
  const dispatch = useDispatch();

  const canImportStopLossCVSFile = useAppSelector(canImportStopLossBulk);

  const tabId = useContext(TabIdContext);

  const onClick = (tapType: TabType) => () => {
    dispatch(clientWorkspaceTabTypeChangedThunk(tabId, tapType));
    close();
  };

  const allButtonTypes = canImportStopLossCVSFile
    ? BUTTON_TYPES_FOR_ACTIVE_TYPE[tabType]
    : BUTTON_TYPES_FOR_ACTIVE_TYPE[tabType].filter((tab) => tab !== 'bulkOrder');

  return (
    <>
      {allButtonTypes.map((buttonTabType) => (
        <button
          key={buttonTabType}
          type="button"
          className="btn ms-3 btn-default py-1"
          onClick={onClick(buttonTabType)}
          data-e2e={button(`switch-${buttonTabType}`)}
        >
          <FormattedMessage id={`workspace.types.${buttonTabType}`} /> <i className="icon icon-sm">arrow_forward</i>
        </button>
      ))}
    </>
  );
};

const BUTTON_TYPES_FOR_ACTIVE_TYPE: Record<TabType, TabType[]> = {
  tiles: ['bulkTrade', 'bulkOrder'],
  bulkTrade: ['tiles', 'bulkOrder'],
  bulkOrder: ['tiles', 'bulkTrade'],
};

export const ChangeTabTypeButton = withTabId(connectChangeTabTypeButton(ChangeTabTypeButtonRaw));
