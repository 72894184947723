import type { MapStateToPropsHOF, MapDispatchToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import type { BlotterHistoricalError } from 'state/blotter/blotterModel';

export type BlotterErrorConnectOwnProps = {}

export interface BlotterErrorConnectStateProps {
  error: BlotterHistoricalError;
}

export type BlotterErrorConnectDispatchProps = {}

// you can whitelist selectors after implementation
export type BlotterErrorConnectSelectors = Selectors;
/*
export type BlotterErrorConnectSelectorsKeys = 'aSelector'; // example
export type BlotterErrorConnectSelectors = Pick<Selectors, BlotterErrorConnectSelectorsKeys>;
*/

export const mapStateToPropsBlotterError: MapStateToPropsHOF<
  BlotterErrorConnectStateProps,
  BlotterErrorConnectOwnProps,
  AppState,
  BlotterErrorConnectSelectors
> = sl => (state, _ownProps) => ({
  error: sl.getBlotterActiveTab(state) === 'order' ? null : sl.getBlotterHistoricalError(state),
});

// you can whitelist action creators after implementation
export type BlotterErrorConnectActionCreators = ActionCreators;
/*
export type BlotterErrorConnectActionCreatorsKeys = 'optionLegPropertyChanged';
export type BlotterErrorConnectActionCreators = Pick<ActionCreators, BlotterErrorConnectActionCreatorsKeys>;
*/

export const mapDispatchToPropsBlotterError: MapDispatchToPropsHOF<
  BlotterErrorConnectDispatchProps,
  BlotterErrorConnectOwnProps,
  BlotterErrorConnectActionCreators
> = _ac => (_dispatch, _ownProps) => ({});
