import type { FxApi } from 'api/typings';
import type { MetaSelectors } from 'epics/metaSelectors';
import { useSelector } from 'react-redux';
import type { ActionsObservable, StateObservable } from 'redux-observable';
import type { ThunkAction, ThunkDispatch } from 'redux-thunk';
import type { Observable } from 'rxjs';
import type { Action, ActionCreators } from './actions';
import type { AppState } from './model';
import type { Selectors } from './selectors';

export type GetNewGuid = () => string;
export type GetNewRfsId = () => string;

export interface EffectsExtraArgument {
  getNewRfsId: GetNewRfsId;
  getDateNow: () => Date;
  checkDomValue(quoteId: string, inputE2eHandle: string, value: number): void;
}

export interface ExtraArgument {
  selectors: Selectors;
  actionCreators: ActionCreators;
  metaSelectors: MetaSelectors;
  getNewGuid: GetNewGuid;
}

export interface EpicExtraArgument extends ExtraArgument {
  api: FxApi;
  effects: EffectsExtraArgument;
}

export type Thunk<R> = ThunkAction<R, AppState, ExtraArgument, Action>;

export type Dispatchable = Action | Thunk<void>;
export type DispatchWithThunkExt = ThunkDispatch<AppState, ExtraArgument, Action>;
export interface DispatchExt {
  dispatch: DispatchWithThunkExt;
}

export const useAppSelector = useSelector.withTypes<AppState>();

export type Epic<Input extends Action = any, Output = Input, State = any, Dependencies = any> = (
  action$: ActionsObservable<Input>,
  state$: StateObservable<State>,
  dependencies: Dependencies,
) => Observable<Output>;

export type EpicWithThunk = Epic<Action, Action | Thunk<void>, AppState, EpicExtraArgument>;
