import { format, isMatch, parse } from 'date-fns';
import { type ChangeEvent, useEffect, useRef, useState } from 'react';
import type DatePicker from 'react-datepicker';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '../../state';
import { userPreferenceDataChanged } from '../../state/userPreferences';
import { getUserPreferenceData } from '../../state/userPreferences/selectors';
import { DatePickerWithTenors } from '../share/DateElements';

export const DefaultMaturityDate = () => {
  const datePickerRef = useRef<DatePicker | null>(null);

  const dispatch = useDispatch();

  const savedValue = useAppSelector(getUserPreferenceData).defaultCashMaturityDate ?? '';

  const isSavedValueDate = isMaturityDate(savedValue ?? '');

  const isoDate = isSavedValueDate ? savedValue : '';
  const tenor = !isSavedValueDate ? savedValue : '--';

  const onSelect = (newDate: string) => {
    dispatch(userPreferenceDataChanged({ defaultCashMaturityDate: newDate }));
  };

  // why not set directly the datePickerRef ?
  // because the type of "onDatePickerRef" is not compatible !
  const onDatePickerRef = (datePicker: DatePicker | null) => {
    datePickerRef.current = datePicker;
  };

  const onOpen = () => {
    datePickerRef.current?.setOpen(true);
  };

  return (
    <div className="card-body d-flex align-items-center justify-content-between p-3 px-md-4 border-bottom">
      <label>Default expiry date</label>

      <div
        className="d-flex flex-row flex-nowrap justify-content-between align-items-center flex-grow-0 flex-shrink-0"
        style={{ overflow: 'auto !important' }}
      >
        <DateInput value={isoDate} onChange={onSelect} />

        <div className="position-relative">
          <button
            type="button"
            className="form-control btn btn-discreet-primary btn-sm d-flex align-items-center justify-content-center"
            onClick={onOpen}
            style={{ width: 35 }}
            data-e2e="defaultMaturityDatePickerButton"
          >
            {tenor}
          </button>

          <div className="position-absolute top-0 start-0">
            <DatePickerWithTenors
              currentTenor={''}
              tenors={ALL_TENORS}
              maxTenor={undefined}
              date={isoDate}
              onSelect={onSelect}
              onDatePickerRef={onDatePickerRef}
              maxDate={undefined}
              placement="top"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

// ██████╗  █████╗ ████████╗███████╗    ██╗███╗   ██╗██████╗ ██╗   ██╗████████╗
// ██╔══██╗██╔══██╗╚══██╔══╝██╔════╝    ██║████╗  ██║██╔══██╗██║   ██║╚══██╔══╝
// ██║  ██║███████║   ██║   █████╗      ██║██╔██╗ ██║██████╔╝██║   ██║   ██║
// ██║  ██║██╔══██║   ██║   ██╔══╝      ██║██║╚██╗██║██╔═══╝ ██║   ██║   ██║
// ██████╔╝██║  ██║   ██║   ███████╗    ██║██║ ╚████║██║     ╚██████╔╝   ██║
// ╚═════╝ ╚═╝  ╚═╝   ╚═╝   ╚══════╝    ╚═╝╚═╝  ╚═══╝╚═╝      ╚═════╝    ╚═╝

type DateInputProps = {
  value: string; // ISO format yyyy-MM-dd
  onChange: (value: string) => void; // ISO format yyyy-MM-dd
};

export const DateInput = (props: DateInputProps) => {
  const { value, onChange } = props;

  const { locale } = useIntl();

  const [date, setDate] = useState(formatDateFromIso(value, locale));

  useEffect(() => {
    setDate(formatDateFromIso(value, locale));
  }, [value, locale]);

  const onInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const updatedDate = event.target.value;

    setDate(updatedDate);
  };

  const onBlur = () => {
    if (date === '') {
      onChange('');
      return;
    }

    if (ALL_TENORS.includes(date.toUpperCase())) {
      onChange(date.toUpperCase());
      return;
    }

    if (isMatch(date, getDateFormat(locale))) {
      onChange(formatDateToIso(date, locale));
      return;
    }

    // invalid date => restore the saved value
    setDate(formatDateFromIso(value, locale));
  };

  const placeholder = getDateFormat(locale);

  return (
    <input
      type="text"
      value={date}
      placeholder={placeholder}
      onChange={onInputChange}
      onBlur={onBlur}
      className="px-4px form-control"
      style={{ width: 174 }}
      data-e2e="defaultMaturityDateInput"
    />
  );
};

// ██╗   ██╗████████╗██╗██╗     ██╗████████╗██╗███████╗███████╗
// ██║   ██║╚══██╔══╝██║██║     ██║╚══██╔══╝██║██╔════╝██╔════╝
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║█████╗  ███████╗
// ██║   ██║   ██║   ██║██║     ██║   ██║   ██║██╔══╝  ╚════██║
// ╚██████╔╝   ██║   ██║███████╗██║   ██║   ██║███████╗███████║
//  ╚═════╝    ╚═╝   ╚═╝╚══════╝╚═╝   ╚═╝   ╚═╝╚══════╝╚══════╝

const ALL_TENORS = [
  'TOD',
  'TOM',
  'ON',
  'SP',
  'TN',
  'SN',
  '1W',
  '2W',
  '3W',
  '1M',
  '2M',
  '3M',
  '6M',
  '9M',
  '12M',
  '18M',
  '2Y',
  '3Y',
  '4Y',
  '5Y',
  '10Y',
  'IMM1',
  'IMM2',
  'BMF',
];

export const isMaturityDate = (value: string) => /\d\d\d\d-\d\d-\d\d/.test(value);

const formatDateFromIso = (isoDate: string, locale: string) => {
  try {
    const date = parse(isoDate, 'yyyy-MM-dd', new Date());
    const dateFormat = getDateFormat(locale);

    return format(date, dateFormat);
  } catch (error) {
    return '';
  }
};

const formatDateToIso = (localizedDate: string, locale: string) => {
  try {
    const dateFormat = getDateFormat(locale);
    const date = parse(localizedDate, dateFormat, new Date());

    return format(date, 'yyyy-MM-dd');
  } catch (error) {
    return '';
  }
};

const getDateFormat = (locale: string) => {
  return locale === 'fr' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
};
