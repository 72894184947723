import { isDefined, isNonEmpty } from '@sgme/fp';
import type { CurrencyChoice } from 'api/workspaceService/model';
import type { Thunk } from 'state';
import { fieldData } from 'utils/fieldSelectors';

export function americanForwardNotionalCurrencyChangedThunk(americanForwardId: string, notionalAmount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldCurrency } = fieldData(sl.getAmericanForwardNotionalCurrency(state, americanForwardId));
    const { data: oldAmount } = fieldData(sl.getAmericanForwardNotionalAmount(state, americanForwardId));

    const notionalCurrency = ((oldCurrency % 2) + 1) as CurrencyChoice;

    if (oldAmount === null && notionalAmount === '') {
      dispatch(ac.americanForwardNotionalCurrencyChanged(americanForwardId, notionalCurrency));
    } else {
      dispatch(
        ac.americanForwardPropertiesChanged(americanForwardId, {
          notionalAmount,
          notionalCurrency,
        }),
      );
    }
  };
}
export function americanForwardHedgeCurrencyChangedThunk(americanForwardId: string, hedgeAmount: string): Thunk<void> {
  return (dispatch, getState, { selectors: sl, actionCreators: ac }) => {
    const state = getState();
    const { data: oldHedgeCurrency } = fieldData(sl.getAmericanForwardHedgeCurrency(state, americanForwardId));
    const { data: oldHedgeAmount } = fieldData(sl.getAmericanForwardHedgeAmount(state, americanForwardId));

    const usedHedgeAmount = isDefined(hedgeAmount) && isNonEmpty(hedgeAmount) ? hedgeAmount : oldHedgeAmount;

    const hedgeCurrency = ((oldHedgeCurrency % 2) + 1) as CurrencyChoice;

    if (usedHedgeAmount === null) {
      dispatch(ac.americanForwardHedgeCurrencyChanged(americanForwardId, hedgeCurrency));

      // If there is an active RFS, restart it or it will send hedge in wrong currency
      if (sl.getTileCurrentStreamId(state, americanForwardId) !== null) {
        dispatch(ac.americanForwardStreamRefreshThunk(americanForwardId, true, true, true));
      }
    } else {
      dispatch(
        ac.americanForwardPropertiesChanged(americanForwardId, {
          hedgeAmount: usedHedgeAmount,
          hedgeCurrency,
        }),
      );
    }
  };
}
