import { Tooltip } from '@sgme/ui';
import type { PropsWithChildren } from 'react';
import type { StopLossOrderError } from '../../state/fxOrderBulks/fxOrderBulkModel';

type CellProps = {
  field: string;
  errors: StopLossOrderError[];
  align?: 'center' | 'left' | 'right';
  width?: string | number;
};

export const Cell = (props: PropsWithChildren<CellProps>) => {
  const { field, errors, align, width, children } = props;

  const fieldError = errors.find((error) => error.field === field);

  return (
    <td align={align} width={width} className={fieldError !== undefined ? 'p-0' : undefined} data-e2e={`${field}-cell`}>
      {fieldError !== undefined ? (
        <Tooltip color="danger" contents={<span>{fieldError.message}</span>}>
          <div className="border-bottom border-lg border-danger p-1 text-danger">{children}</div>
        </Tooltip>
      ) : (
        children
      )}
    </td>
  );
};
