import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { RenderWhenProps } from '../RenderWhen';

export type UserHasAccessConnectOwnProps = {}

type UserHasAccessConnectStateProps = RenderWhenProps;

export type UserHasAccessConnectSelectorsKeys = 'getUserHasAccess';
export type UserHasAccessConnectSelectors = Pick<Selectors, UserHasAccessConnectSelectorsKeys>;

export const mapStateToPropsUserHasAccess: MapStateToPropsHOF<
  UserHasAccessConnectStateProps,
  UserHasAccessConnectOwnProps,
  AppState,
  UserHasAccessConnectSelectors
> = sl => (state, _ownProps) => ({
  condition: sl.getUserHasAccess(state),
});
