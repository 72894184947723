import type { Thunk } from 'state';
import type { ImportedScheduledRow, ImportedTargetEkiPivotScheduleRow, ImportedTargetEkiScheduleRow, ImportedTargetPivotScheduleRow, ImportedTargetVanillaScheduleRow } from 'utils/csvParsing/accuSchedule';
import type { AccumulatorScheduleInputs } from '../fxAccumulatorsModel';
import type { AccumulatorType } from 'epics/metaSelectors/accumulator/tradeCaptureToBackend';

export function scheduleImportedThunk(
  quoteId: string,
  importedScheduleRows: readonly ImportedScheduledRow[],
  accuType: AccumulatorType | undefined
): Thunk<void> {
  return (dispatch, _getState, { actionCreators: ac, getNewGuid }) => {
    const legsPatch = Object.fromEntries(
      importedScheduleRows.map(row => [getNewGuid(), mapScheduleRowToFixing(row, accuType)]),
    );

    console.log(legsPatch);

    dispatch(ac.accumulatorScheduleImported(quoteId, legsPatch));
  };
}

const mapScheduleRowToFixing = (row: ImportedScheduledRow, accuType: AccumulatorType | undefined)
  : Partial<AccumulatorScheduleInputs> => {

  if (isTargetVanillaAccu(row, accuType)) {

    return ({
      fixingDate: row.fixingDate,
      paymentDate: row.settlementDate,
      strike: row.strike,
      amount: row.notional,
      leverageAmount: row.leverageAmount,
    });
  }

  if (isTargetPivotAccu(row, accuType)) {

    return ({
      fixingDate: row.fixingDate,
      paymentDate: row.settlementDate,
      strikeUp: row?.strikeUp,
      step: row?.step,
      strikeDown: row?.strikeDown,
      amount: row.notional,
      leverageAmount: row.leverageAmount,
    });
  }

  if (isTargetEkiPivotAccu(row, accuType)) {

    return ({
      fixingDate: row.fixingDate,
      paymentDate: row.settlementDate,
      strikeDown: row?.strikeDown,
      strikeUp: row?.strikeUp,
      step: row?.step,
      stepDown: row?.ekiDown,
      stepUp: row?.ekiUp,
      amount: row.notional,
      leverageAmount: row.leverageAmount,

    });
  }

  if (isTargetEkiAccu(row, accuType)) {
    return ({
      fixingDate: row.fixingDate,
      paymentDate: row.settlementDate,
      strike: row.strike,
      amount: row.notional,
      leverageAmount: row.leverageAmount,
      step: row?.step,
    });
  }

  return ({
    fixingDate: row.fixingDate,
    paymentDate: row.settlementDate
  })
};

function isTargetVanillaAccu(row: ImportedScheduledRow, accuType: AccumulatorType | undefined): row is ImportedTargetVanillaScheduleRow {
  return row && accuType === 'TargetAccu';
}

function isTargetPivotAccu(row: ImportedScheduledRow, accuType: AccumulatorType | undefined): row is ImportedTargetPivotScheduleRow {
  return row && accuType === 'PIVOT';
}

function isTargetEkiPivotAccu(row: ImportedScheduledRow, accuType: AccumulatorType | undefined): row is ImportedTargetEkiPivotScheduleRow {
  return row && accuType === 'PIVOTEKI';
}


function isTargetEkiAccu(row: ImportedScheduledRow, accuType: AccumulatorType | undefined): row is ImportedTargetEkiScheduleRow {
  return row && accuType === 'EKI';
}

