import type { MapStateToPropsHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { AppState } from 'state/model';
import type { BlotterMode } from 'state/blotter/blotterModel';

export type BlotterLoadingConnectOwnProps = {}

export interface BlotterLoadingConnectStateProps {
  fetching: BlotterMode | null;
  canRefetch: boolean;
  mode: BlotterMode;
}

// you can whitelist selectors after implementation
export type BlotterLoadingConnectSelectors = Selectors;
/*
export type BlotterLoadingConnectSelectorsKeys = 'aSelector'; // example
export type BlotterLoadingConnectSelectors = Pick<Selectors, BlotterLoadingConnectSelectorsKeys>;
*/

export const mapStateToPropsBlotterLoading: MapStateToPropsHOF<
  BlotterLoadingConnectStateProps,
  BlotterLoadingConnectOwnProps,
  AppState,
  BlotterLoadingConnectSelectors
> = sl => (state, _ownProps) => ({
  fetching: sl.getBlotterFetching(state),
  canRefetch: sl.getBlotterCanRefetch(state),
  mode: sl.getBlotterMode(state),
});
