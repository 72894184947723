import { Tooltip } from '@sgme/ui';
import { FormattedMessage } from 'react-intl';
import { type StopLossOrderError, StopLossOrderStatus } from '../../state/fxOrderBulks/fxOrderBulkModel';

type OrderStatusProps = {
  status: StopLossOrderStatus;
  errors: StopLossOrderError[];
};

export const OrderStatus = (props: OrderStatusProps) => {
  const { status, errors } = props;

  switch (status) {
    case StopLossOrderStatus.IDLE:
      return (
        <span className="badge badge-md rounded-pill badge-secondary">
          <FormattedMessage id="status.idle" />
        </span>
      );

    case StopLossOrderStatus.VALIDATING:
      return (
        <span className="badge badge-md rounded-pill badge-info">
          <FormattedMessage id="status.validating" />
        </span>
      );

    case StopLossOrderStatus.VALID:
      return (
        <span className="badge badge-md rounded-pill badge-success">
          <FormattedMessage id="status.valid" />
        </span>
      );

    case StopLossOrderStatus.INVALID:
      if (errors.length === 0) {
        return (
          <span className="badge badge-md rounded-pill badge-danger">
            <FormattedMessage id="status.invalid" />
          </span>
        );
      }

      return (
        <Tooltip
          color="danger"
          contents={
            <div>
              {errors.map((error, errorIndex) => (
                <>
                  <div key={error.field}>{error.message}</div>
                  {errorIndex < errors.length - 1 && <br key={error.field} />}
                </>
              ))}
            </div>
          }
        >
          <span className="badge badge-md rounded-pill badge-danger">
            <FormattedMessage id="status.invalid" />
          </span>
        </Tooltip>
      );

    case StopLossOrderStatus.PENDING:
      return (
        <span className="badge badge-md rounded-pill badge-warning">
          <FormattedMessage id="status.pending" />
        </span>
      );

    case StopLossOrderStatus.SUCCESS:
      return (
        <span className="badge badge-md rounded-pill badge-success">
          <FormattedMessage id="status.success" />
        </span>
      );

    case StopLossOrderStatus.FAILED:
      return (
        <span className="badge badge-md rounded-pill badge-danger">
          <FormattedMessage id="status.failed" />
        </span>
      );
  }

  return <div>OrderStatus</div>;
};
