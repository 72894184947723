import { getAmericanForwardState } from './selectors';
import type { AppState } from '../../model';
import { throwIfUndefined } from '../../../utils/maps';
import type { IHedge, IQuote } from '../model/fxAmericanForwardStreamModel';
import type {
  FxAmericanForwardStreamState,
  IFxAmericanForwardStreamState,
  IFxAmericanForwardStreamStreamingState,
} from '../model/fxAmericanForwardStreamModel';
import type { CurrencyChoice } from '../../share/productModel/litterals';
import type { IGreeks } from '../../fxOptions/fxOptionsModel';
import { getAmericanForwardCurrency } from './americanForwardInput';

const allowedStreamStatus: ReadonlyArray<FxAmericanForwardStreamState['status']> = ['PRICING', 'EXPIRED'];

export function getAmericanForwardAllStreamState(state: AppState) {
  return state.fxAmericanForwards.streams
}
export function getAmericanForwardStreamState(state: AppState, streamId: string) {

  return throwIfUndefined(
    state.fxAmericanForwards.streams[streamId],
    `American Forward stream state for ${streamId} does not exist`,
  );
}

export function getAmericanForwardStreamStatus(
  state: AppState,
  streamId: string | null,
): IFxAmericanForwardStreamState['status'] | null {
  if (streamId === null) {
    return null;
  }

  const { status } = getAmericanForwardStreamState(state, streamId);

  return status;
}

export function getAmericanForwardActiveRfsStreamId(state: AppState, americanForwardId: string) {
  const { currentStreamId, lastStreamId } = getAmericanForwardState(state, americanForwardId);

  return lastStreamId ?? currentStreamId;
}

export function isAmericanForwardStreamPresent(state: AppState, streamId: string) {
  return !!state.fxAmericanForwards.streams[streamId];
}

export function hasAmericanForwardBypassedLimitCheck(state: AppState, quoteId: string) {
  const quoteState = getAmericanForwardState(state, quoteId);

  if (quoteState === null) {
    return false;
  }

  if (!isAmericanForwardStreamPresent(state, quoteState.currentStreamId!)) {
    return false;
  }

  const streamState = getAmericanForwardStreamState(state, quoteState.currentStreamId!);

  return streamState.status === 'PRICING' && streamState.skippedLimitCheck;
}

export function getAmericanForwardStreamVersion(state: AppState, streamId: string | null): number | null {
  if (streamId === null) {
    return null;
  }

  const { version } = getAmericanForwardStreamState(state, streamId);

  return version;
}

export function getAmericanForwardGreeksFromStream(
  state: AppState,
  streamId: string | null,
  notionalCurrency: CurrencyChoice,
  legId?: string,
): Readonly<IGreeks> | null {
  if (streamId === null) {
    return null;
  }

  const quote = getQuoteFromStream(state, streamId);

  if (quote === null) {
    return null;
  }

  if (legId !== undefined) {
    const legData = getLegData(state, streamId, legId);

    if (legData === null) {
      return null;
    }

    const {
      greeks: { deltaCcy1, deltaCcy2, vegaCcy1, vegaCcy2, gammaCcy1, gammaCcy2, thetaCcy1, thetaCcy2 },
    } = legData;

    return {
      deltaCcy: Number.parseFloat(notionalCurrency === 1 ? deltaCcy1 : deltaCcy2),
      vegaCcy: Number.parseFloat(notionalCurrency === 1 ? vegaCcy1 : vegaCcy2),
      gammaCcy: Number.parseFloat(notionalCurrency === 1 ? gammaCcy1 : gammaCcy2),
      thetaCcy: Number.parseFloat(notionalCurrency === 1 ? thetaCcy1 : thetaCcy2),
    };
  }

  return {
    deltaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.deltaCcy : quote.ccy2.deltaCcy),
    vegaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.vegaCcy : quote.ccy2.vegaCcy),
    gammaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.gammaCcy : quote.ccy2.gammaCcy),
    thetaCcy: Number.parseFloat(notionalCurrency === 1 ? quote.ccy1.thetaCcy : quote.ccy2.thetaCcy),
  };
}

function getLegData(state: AppState, streamId: string, legId: string) {
  const quote = getQuoteFromStream(state, streamId);

  return quote?.legsData?.[legId] ?? null;
}

export function getAmericanForwardMarkupFromStreamId(state: AppState, quoteId: string) {
  const currentStream = getAmericanForwardActiveRfsStreamId(state, quoteId);

  if (currentStream === null) {
    return null
  }
  const quote = getQuoteFromStream(state, currentStream);

  return { askMarkupCcy1: quote?.legsData[quoteId].marginData.askMarkupCcy1, askMarkupCcy2: quote?.legsData[quoteId].marginData.askMarkupCcy2 }
}

export function getAmericanForwardHedgeFromStream(state: AppState, streamId: string | null) {
  if (streamId !== null) {
    const quote = getQuoteFromStream(state, streamId);
    if (quote !== null) {
      return quote.hedge;
    }
  }
  return null;
}

export function getQuoteFromStream(state: AppState, streamId: string): Readonly<IQuote<readonly IHedge[]>> | null {
  const stream = getAmericanForwardStreamState(state, streamId);

  return isPrice(stream) ? stream.quote : null;
}

export function isPrice(state: FxAmericanForwardStreamState): state is IFxAmericanForwardStreamStreamingState {
  return allowedStreamStatus.includes(state.status);
}

export function getAmericanForwardRfsPremiumByQuoteId(state: AppState, streamId: string | null, legId: string) {
  if (streamId === null) {
    throw new Error('Awaiting for missing stream');

  }

  const legData = getLegData(state, streamId, legId);
  if (legData === null) {
    throw `American forward ${legData} has no valid RFS or RFS pricing quote : premium cannot be issued.`;
  }
  return [{ ask: legData.premiumData.askAmountCcy1, bid: legData.premiumData.bidAmountCcy1 }, { ask: legData.premiumData.askAmountCcy2, bid: legData.premiumData.bidAmountCcy2 }] as const;


}

export function getAmericanForwardRfsRateByQuoteId(state: AppState, streamId: string | null, legId: string) {
  if (streamId === null) {
    throw new Error('Awaiting for missing stream');

  }

  const legData = getLegData(state, streamId, legId);
  if (legData === null) {
    throw `American forward ${legData} has no valid RFS or RFS pricing quote : premium cannot be issued.`;
  }
  return legData.solving
}

export function getAmericanForwardRfsPremiumPercentByQuoteId(state: AppState, streamId: string | null, legId: string) {
  if (streamId === null) {
    throw new Error('Awaiting for missing stream');

  }

  const legData = getLegData(state, streamId, legId);
  if (legData === null) {
    throw `American forward ${legData} has no valid RFS or RFS pricing quote : premium cannot be issued.`;
  }
  return [{ ask: legData.premiumData.askPercentCcy1, bid: legData.premiumData.bidPercentCcy1 }, { ask: legData.premiumData.askPercentCcy2, bid: legData.premiumData.bidPercentCcy2 }] as const;


}

export const getAmericanForwardPremiumSign = (state: AppState, streamId: string | null, quoteId: string) => {
  const premium = getAmericanForwardRfsPremiumByQuoteId(state, streamId, quoteId);
  const priceCurrency = getAmericanForwardCurrency(state, quoteId);

  const priceCurrencyPremium = premium[priceCurrency.value - 1];
  return priceCurrencyPremium.ask > 0;
};