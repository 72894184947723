import type { ISgmeHttp } from 'utils/sgmeHttp';
import { ignoreElements } from 'rxjs/operators';
import type { IAmericanForwardQuoteRequestApi } from '../../../epics/americanForward/americanForwardQuoteRequest';

export function americanForwardQuoteRequestApi(http: ISgmeHttp): IAmericanForwardQuoteRequestApi {
  return {
    createQuoteRequest(request) {
      return http
        .post<void>('api/fxoAmericanForward/rfs/request', request, { 'X-Correlation-ID': request.rfsId })
        .pipe(ignoreElements());
    },
  };
}
