import type { AccumulatorTradeCapturePatch } from 'epics/metaSelectors/accumulator/tradeCaptureFromBackend';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { AccumulatorProductName } from 'state/fxAccumulators/fxAccumulatorsModel';
import { extract } from 'utils/object';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import type {
  TradeCaptureAccumulatorRequestWrapper,
  TradeCaptureAccumulatorResponseWrapper,
} from './tradeCaptureModel';

export interface TradeCaptureApiRequest {
  productType: AccumulatorProductName;
  sessionId: string;
  tileId: string;
  request: TradeCaptureAccumulatorRequestWrapper;
}

export interface TradeCaptureBatchedResponses {
  [accuId: string]: TradeCaptureAccumulatorResponseWrapper;
}

export interface TradeCaptureBatchedPatch {
  accuId: string;
  patch: AccumulatorTradeCapturePatch | null;
}

export interface ITradeCaptureAccumulatorApi {
  timeout: number;
  createNewBatchVersion(queries: readonly TradeCaptureApiRequest[]): Observable<TradeCaptureBatchedResponses>;
}

export function tradeCaptureApi(http: ISgmeHttp): ITradeCaptureAccumulatorApi {
  return {
    createNewBatchVersion(queries) {
      return http
        .post<TradeCaptureBatchedResponses>('api/tradeCaptureBatch/Accumulator', queries)
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
