import type { IntlFormatters } from 'react-intl';
import type {
  CopyToClipBoardAccumulatorRawData,
  CopyToClipBoardOptionRawData,
  CopyToClipBoardSwapRawData,
} from './types';
import type { IQuote } from 'state/fxOptions/model/optionsStreams';
import type { IFxVanillaLegValues } from 'state/fxOptions/model/optionsLegs';
import type { FxAccumulatorState, FxTargetAccumulatorState } from 'state/fxAccumulators/fxAccumulatorsModel';
import { isArray } from 'utils/immutableReferenceUpdate';

/* ################## OPTIONS #################### */

interface PriceType {
  firstTypeOfSell: string;
  firstTypeOfBuy: string;
  secondTypeOfSell: string;
  secondTypeOfBuy: string;
}

const countPercentage = (n: number | undefined, currency: string | null): string =>
  `${n !== undefined ? `${(n * 100).toFixed(4)}% ${currency}` : ''}`;

const computePriceType = (
  quote: Readonly<IQuote> | null,
  legValues: IFxVanillaLegValues,
  displayPriceCurrency: number,
  numberFormatter: (value: number) => string,
): PriceType => {
  let price;

  if (quote) {
    const isPriceCurrency1 = displayPriceCurrency === 1;
    const currentCurrency = isPriceCurrency1 ? quote.ccy1 : quote.ccy2;
    const displayedCurrency = isPriceCurrency1 ? legValues?.currency1 : legValues?.currency2;

    switch (legValues?.premiumTypeString) {
      case 'AMOUNT':
        price = {
          firstTypeOfBuy: `${numberFormatter(currentCurrency.askAmount)} ${
            currentCurrency && displayedCurrency ? displayedCurrency : ''
          }`,
          firstTypeOfSell: `${numberFormatter(currentCurrency.bidAmount)} ${
            currentCurrency && displayedCurrency ? displayedCurrency : ''
          }`,
          secondTypeOfBuy: countPercentage(currentCurrency.askPercent, displayedCurrency),
          secondTypeOfSell: countPercentage(currentCurrency.bidPercent, displayedCurrency),
        };
        break;
      case 'VOLATILITY':
        price = {
          firstTypeOfBuy: quote.volatility?.ask ? (quote.volatility?.ask * 100).toFixed(3) : '',
          firstTypeOfSell: quote.volatility?.bid ? (quote.volatility?.bid * 100).toFixed(3) : '',
          secondTypeOfBuy: countPercentage(currentCurrency.askPercent, displayedCurrency),
          secondTypeOfSell: countPercentage(currentCurrency.bidPercent, displayedCurrency),
        };
        break;
      case 'PERCENT':
        price = {
          firstTypeOfBuy: countPercentage(currentCurrency.askPercent, displayedCurrency),
          firstTypeOfSell: countPercentage(currentCurrency.bidPercent, displayedCurrency),
          secondTypeOfBuy: `${numberFormatter(currentCurrency.askAmount) ?? ''} ${
            currentCurrency.askAmount && displayedCurrency ? displayedCurrency : ''
          }`,
          secondTypeOfSell: `${numberFormatter(currentCurrency.bidAmount) ?? ''} ${
            currentCurrency.bidAmount && displayedCurrency ? displayedCurrency : ''
          }`,
        };
        break;
      case 'PPS':
        price = {
          firstTypeOfBuy: currentCurrency.askPps ? (currentCurrency.askPps * 10000).toFixed(4) : '',
          firstTypeOfSell: currentCurrency.bidPps ? (currentCurrency.bidPps * 10000).toFixed(4) : '',
          secondTypeOfBuy: `${numberFormatter(currentCurrency.askAmount) ?? ''} ${
            currentCurrency.askAmount && displayedCurrency ? displayedCurrency : ''
          }`,
          secondTypeOfSell: `${numberFormatter(currentCurrency.bidAmount) ?? ''} ${
            currentCurrency.bidAmount && displayedCurrency ? displayedCurrency : ''
          }`,
        };
        break;
      default:
        const quoteAskAmount = currentCurrency.askAmount;
        const quoteBidAmount = currentCurrency.bidAmount;
        price = {
          firstTypeOfBuy: quoteAskAmount
            ? `${numberFormatter(quoteAskAmount) ?? ''} ${quoteAskAmount && displayedCurrency ? displayedCurrency : ''}`
            : '',
          firstTypeOfSell: quoteBidAmount
            ? `${numberFormatter(quoteBidAmount) ?? ''} ${quoteBidAmount && displayedCurrency ? displayedCurrency : ''}`
            : '',
          secondTypeOfBuy: countPercentage(currentCurrency.askPercent, displayedCurrency),
          secondTypeOfSell: countPercentage(currentCurrency.bidPercent, displayedCurrency),
        };
    }
  } else {
    price = {
      firstTypeOfBuy: '',
      firstTypeOfSell: '',
      secondTypeOfBuy: '',
      secondTypeOfSell: '',
    };
  }

  return price;
};

const computeTypedStrategyStrikeInfo = (
  legValues: IFxVanillaLegValues[],
  quote: Readonly<IQuote> | null,
  vanillaLegsId: readonly string[],
) => {
  let putStrike = 'Put Strike: ';
  let callStrike = 'Call Strike: ';
  const callLegValue = legValues.find(leg => leg.optionType === 'Call');
  const putLegValue = legValues.find(leg => leg.optionType === 'Put');

  // we know that in case of typeStrategy we have call and put legs
  const callLegId = vanillaLegsId.find(legId => legId.includes('call'))!;
  const putLegId = vanillaLegsId.find(legId => legId.includes('put'))!;

  if (quote === null) {
    callStrike += callLegValue?.strike;
    putStrike += putLegValue?.strike;
  } else {
    const callStrikeData = quote.legsData[callLegId].strikeData;
    const putStrikeData = quote.legsData[putLegId].strikeData;

    callStrike += `${callStrikeData.absoluteStrikePrice} (${callStrikeData.deltaStrikePrice})`;
    putStrike += `${putStrikeData.absoluteStrikePrice} (${putStrikeData.deltaStrikePrice})`;
  }

  // indentation is specific for the content to be aligned in copyclipboard
  return `${putStrike}
${callStrike}`;
};

export const formatOptionRawData = (
  optionRawData: CopyToClipBoardOptionRawData,
  formatNumber: IntlFormatters['formatNumber'],
): string => {
  const { legValues, quote, vanillaLegsId, greeks, displayPriceCurrency } = optionRawData;
  const { optionType = '', currency1 = '', currency2 = '' } = optionRawData.legValues[0];
  const notionalCurrency = optionRawData.legValues[0]?.notionalCurrency === 1 ? currency1 : currency2;
  const formatNumberWithDecimal = (value: number) =>
    formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const isTypedStrategy = legValues.length > 1;
  const firstVanillaLegValues = legValues[0];
  const firstVanillaLegId = isTypedStrategy ? vanillaLegsId[1] : vanillaLegsId[0];

  const priceType = computePriceType(quote, firstVanillaLegValues, displayPriceCurrency, formatNumberWithDecimal);
  const strike =
    quote === null
      ? firstVanillaLegValues?.strike
      : `${quote?.legsData[firstVanillaLegId].strikeData.absoluteStrikePrice} (${quote?.legsData[firstVanillaLegId].strikeData.deltaStrikePrice})`;

  const firstTypeOfSell = priceType.firstTypeOfSell;
  const isFirstTypeOfSellExist = firstTypeOfSell ?? false;
  const firstTypeOfBuy = priceType.firstTypeOfBuy;
  const isFirstTypeOfBuyExist = firstTypeOfBuy ?? false;

  const currencyPair = currency1 ? currency1 + '/' + currency2 : '';
  const marketData = quote?.legsData[vanillaLegsId[0]].marketData;

  const expiryTenorDateFormatted = `${
    firstVanillaLegValues?.expiryDateTenor ? `(${firstVanillaLegValues?.expiryDateTenor})` : ''
  }`;

  const optionLabel = isTypedStrategy ? optionRawData.productName : `European ${optionType ?? ''}`;
  const strikeInfo =
    optionRawData.productName === 'Strangle' || optionRawData.productName === 'RiskReversal'
      ? computeTypedStrategyStrikeInfo(legValues, quote, vanillaLegsId)
      : `Strike: ${strike ?? ''}`;

  // the formatting/indentation below is done on purpose
  const stringToCopy = `${optionLabel} ${currencyPair}
Spot: ${quote?.spot.bid ?? ''}${quote?.spot.ask || quote?.spot.bid ? '/' : ''}${quote?.spot.ask ?? ''}
ForwardRef: ${marketData?.forward ? `${marketData?.forward.bid} / ${marketData?.forward.ask}` : ''}
${strikeInfo}
Notional: ${
    firstVanillaLegValues?.notionalAmount ? formatNumberWithDecimal(firstVanillaLegValues?.notionalAmount) : ''
  } ${firstVanillaLegValues?.notionalAmount && notionalCurrency ? notionalCurrency : ''}
Expiry Date: ${
    firstVanillaLegValues?.expiryDate ? `${firstVanillaLegValues?.expiryDate} ${expiryTenorDateFormatted}` : ''
  }
Settlement: ${firstVanillaLegValues?.settlementType}
Cut-Off: ${firstVanillaLegValues?.marketPlace}
Sell: ${isFirstTypeOfSellExist ? `${firstTypeOfSell} (${priceType.secondTypeOfSell})` : ''}
Buy: ${isFirstTypeOfBuyExist ? `${firstTypeOfBuy} (${priceType.secondTypeOfBuy})` : ''}
Volatility: ${marketData?.volatility.bid} / ${marketData?.volatility.ask}
Vega: ${greeks?.vegaCcy ? formatNumberWithDecimal(greeks?.vegaCcy) : ''} ${notionalCurrency}
Gamma: ${greeks?.gammaCcy ? formatNumberWithDecimal(greeks?.gammaCcy) : ''} ${notionalCurrency}
Theta: ${greeks?.vegaCcy ? formatNumberWithDecimal(greeks?.thetaCcy) : ''} ${notionalCurrency}`;

  return stringToCopy;
};

/* ################## SWAP #################### */

function formatPointsValue(value: number | string): string {
  if (value === '') {
    return '';
  }
  const sign = +value > 0 ? '+' : '';
  return ` (${sign}${value})`;
}

export function formatSwapRawData(swapDataToCopy: CopyToClipBoardSwapRawData): string {
  const swapStateData = swapDataToCopy.swapStateData;
  const isAmountCurrency1 = swapStateData.amountCurrency === 1;
  const way = isAmountCurrency1 ? ['Buy', 'Sell'] : ['Sell', 'Buy'];

  const swapQuoteData = swapDataToCopy.swapQuoteData;
  const dealtCurrency = isAmountCurrency1 ? swapStateData.currency1 : swapStateData.currency2;

  const { bid: bidSpot, ask: askSpot } = swapQuoteData.spot;
  const spot = bidSpot === '' && askSpot === '' ? '' : `${bidSpot}/${askSpot}`;

  const { bid: bidNearPriceValue, ask: askNearPriceValue } = swapQuoteData.nearPrice;
  const bidNearPrice = bidNearPriceValue === '' ? '' : ` @${bidNearPriceValue}`;
  const askNearPrice = askNearPriceValue === '' ? '' : ` @${askNearPriceValue}`;

  const { bid: bidNearPointsValue, ask: askNearPointsValue } = swapQuoteData.nearPoints;
  const bidNearPoints = formatPointsValue(bidNearPointsValue);
  const askNearPoints = formatPointsValue(askNearPointsValue);

  const { bid: bidFarPriceValue, ask: askFarPriceValue } = swapQuoteData.farPrice;
  const bidFarPrice = bidFarPriceValue === '' ? '' : ` @${bidFarPriceValue}`;
  const askFarPrice = askFarPriceValue === '' ? '' : ` @${askFarPriceValue}`;

  const { bid: bidFarPointsValue, ask: askFarPointsValue } = swapQuoteData.farPoints;
  const bidFarPoints = formatPointsValue(bidFarPointsValue);
  const askFarPoints = formatPointsValue(askFarPointsValue);

  const { bid: bidSwapPointsValue, ask: askSwapPointsValue } = swapQuoteData.swapPoints;
  const bidSwapPoints = formatPointsValue(bidSwapPointsValue);
  const askSwapPoints = formatPointsValue(askSwapPointsValue);

  const stringToCopy =
    `Swap ${swapStateData.currencyPair}\n` +
    `Spot ref: ${spot}, Dodd Frank mid pts: ${swapQuoteData.midPoints}\n` +
    `-------${way[0]} & ${way[1]} ${dealtCurrency} (BID)-------\n` +
    `Near leg: ${way[0]} ${swapStateData.nearNotional} ${dealtCurrency}${bidNearPrice}${bidNearPoints}, value date ${swapStateData.nearDate} (${swapStateData.nearTenor})\n` +
    `Far leg: ${way[1]} ${swapStateData.farNotional} ${dealtCurrency}${bidFarPrice}${bidFarPoints}, value date ${swapStateData.farDate} (${swapStateData.farTenor})\n` +
    `ALL-IN points : ${bidSwapPoints}\n` +
    `-------${way[1]} & ${way[0]} ${dealtCurrency} (OFFER)-------\n` +
    `Near leg: ${way[1]} ${swapStateData.nearNotional} ${dealtCurrency}${askNearPrice}${askNearPoints}, value date ${swapStateData.nearDate} (${swapStateData.nearTenor})\n` +
    `Far leg: ${way[0]} ${swapStateData.farNotional} ${dealtCurrency}${askFarPrice}${askFarPoints}, value date ${swapStateData.farDate} (${swapStateData.farTenor})\n` +
    `ALL-IN points : ${askSwapPoints}`;

  return stringToCopy;
}

/* ################## ACCUMULATOR #################### */
export function formatAccumulatorRawData(
  { accumulatorStateData, accumulatorQuoteData }: CopyToClipBoardAccumulatorRawData,
  formatNumber: IntlFormatters['formatNumber'],
): string {
  const formatNumberWithDecimal = (value: number) =>
    formatNumber(value, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  const {
    amountSplitType,
    currencyPair,
    way,
    fixingFrequency,
    fixingReference1,
    leverage,
    leverageAmount,
    firstFixingDate,
    firstFixingDateTenor,
    hedgeType,
    expiryDate,
    expiryTenor,
    strike,
    priceCurrency,
    numberOfFixings,
    amount,
  } = accumulatorStateData.values;

  let premium;
  let premiumPercent;

  const currency = currencyPair?.split('/')[priceCurrency - 1];

  if (accumulatorQuoteData) {
    const { premiumCcy1, premiumCcy2, premiumPctCcy1, premiumPctCcy2 } = accumulatorQuoteData;
    premium = [premiumCcy1, premiumCcy2][priceCurrency - 1].ask;
    premiumPercent = [premiumPctCcy1, premiumPctCcy2][priceCurrency - 1];
  }

  const underlyingField = `Underlying: ${currencyPair} (${way} ${currency})\n`;
  const fixingFrequencyField = `Fixing Frequency: ${numberOfFixings} ${fixingFrequency} (${fixingReference1})\n`;
  const settlementFrequencyField = `Settlement Frequency: ${numberOfFixings} ${fixingFrequency}\n`;
  const leverageField = `Leverage: ${leverage}\n`;

  const amountMode = amountSplitType === 'Total' || firstFixingDate === expiryDate ? 'Total Amount' : 'Per Fixing';
  const notionalField = `Notional: ${formatNumberWithDecimal(Number(amount))} ${currency} (${amountMode})\n`;
  const leveragedNotionalField = `Leveraged Notional: ${formatNumberWithDecimal(Number(leverageAmount))} ${currency}\n`;

  const firstFixingField = `First Fixing: ${firstFixingDate} ${
    firstFixingDateTenor ? '(' + firstFixingDateTenor + ')' : ''
  }\n`;
  const lastDateField =
    expiryDate !== null ? `Last Date: ${expiryDate} ${expiryTenor ? '(' + expiryTenor + ')' : ''}\n` : '';
  const strikeField = strike !== null ? `Strike: ${strike}\n` : '';

  const hedge = isArray(accumulatorQuoteData?.hedge) ? accumulatorQuoteData?.hedge[0] : accumulatorQuoteData?.hedge;

  const spotRef = hedgeType === 'Spot' ? `Spot Ref: ${accumulatorQuoteData ? `${hedge?.rateMid}` : ''}\n` : '';

  const clientPaysOrReceives = premium ? (premium >= 0 ? 'pays' : 'receives') : '';

  const clientPremium = accumulatorQuoteData
    ? `Client ${clientPaysOrReceives} ${formatNumberWithDecimal(
        Math.abs(Number(premium)),
      )} ${currency} (${premiumPercent}%)`
    : '';
  const premiumField = clientPremium ? `Premium: ${clientPremium}` : '';

  if (isTargetAccumulator(accumulatorStateData)) {
    // TARF
    const { accuType, targetProfitType, target, strikeDown, pivot, strikeUp, ekiDown, ekiUp, eki } =
      accumulatorStateData.values;

    const accuTypeVariant = accuType !== 'TargetAccu' ? accuType : '';
    const ekiValueField = accuType === 'EKI' ? `EKI Trigger: ${eki}\n` : '';

    let accuTypeAdditionalData = '';

    if (accuType === 'PIVOT') {
      accuTypeAdditionalData = `Strike down: ${strikeDown}\n` + `Pivot: ${pivot}\n` + `Strike up: ${strikeUp}\n`;
    } else if (accuType === 'PIVOTEKI') {
      accuTypeAdditionalData =
        `EKI down: ${ekiDown}\n` +
        `Strike down: ${strikeDown}\n` +
        `Pivot: ${pivot}\n` +
        `Strike up: ${strikeUp}\n` +
        `EKI up: ${ekiUp}\n`;
    }

    return (
      `Product: TARF ${accuTypeVariant}\n` +
      `${underlyingField}` +
      `${fixingFrequencyField}` +
      `${settlementFrequencyField}` +
      `${leverageField}` +
      `${notionalField}` +
      `${leveragedNotionalField}` +
      `${firstFixingField}` +
      `${lastDateField}` +
      `${spotRef}` +
      `${accuTypeAdditionalData}` +
      `${ekiValueField}` +
      `${strikeField}` +
      `Profit Target: ${target} (${targetProfitType ?? ''})\n` +
      `${premiumField}`
    );
  } else {
    // FWAC
    const { akoTrigger, ekiTrigger } = accumulatorStateData.values;

    const ekiValueField = ekiTrigger ? `EKI trigger: ${ekiTrigger}\n` : '';
    const akoValueField = akoTrigger ? `AKO trigger: ${akoTrigger}\n` : '';

    return (
      `Product: FWAC\n` +
      `${underlyingField}` +
      `${fixingFrequencyField}` +
      `${settlementFrequencyField}` +
      `${leverageField}` +
      `${notionalField}` +
      `${leveragedNotionalField}` +
      `${firstFixingField}` +
      `${lastDateField}` +
      `${spotRef}` +
      `${strikeField}` +
      `${akoValueField}` +
      `${ekiValueField}` +
      `${premiumField}`
    );
  }
}

const isTargetAccumulator = (accumulatorState: FxAccumulatorState): accumulatorState is FxTargetAccumulatorState =>
  accumulatorState.values.productName === 'FxTargetAccumulator';
