import type { IBlotterOrderNotificationData, IBlotterTradeNotificationData } from 'bootstrap/streams';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import type { HttpCall, ISgmeHttp } from 'utils/sgmeHttp';

export type TradeData = readonly IBlotterTradeNotificationData[];

export type OrderData = readonly IBlotterOrderNotificationData[];

const url = 'api/blotter';

export interface IBlotterServiceApi {
  timeout: number;
  delayToAllowRefetch: number;
  getActiveOrderList: (showMyMainDeals: boolean) => Observable<OrderData | null>;
  getIntradayTrades: (showMyMainDeals: boolean) => Observable<TradeData | null>;
  getHistoricalTrades: (
    showMyMainDeals: boolean,
    counterpartyBdrIds: readonly string[],
    fromDate: Date,
    toDate: Date,
  ) => Observable<TradeData | null>;
  register(connectionId: string, showMyMainDeals: boolean): Observable<HttpCall<void>>;
}

export function blotterServiceApi(http: ISgmeHttp): IBlotterServiceApi {
  return {
    getIntradayTrades(showMyMainDeals) {
      return http
        .post<TradeData | null>(`${url}/todayTradesList`, {
          showMyMainDeals,
        })
        .pipe(map(extract('payload')));
    },
    getHistoricalTrades(showMyMainDeals, counterpartyBdrInitials, fromDate, toDate) {
      return http
        .post<TradeData | null>(`${url}/historicalTradesList`, {
          showMyMainDeals,
          counterpartyBdrInitials,
          fromDate,
          toDate,
        })
        .pipe(map(extract('payload')));
    },
    getActiveOrderList(showMyMainDeals: boolean) {
      return http.post<OrderData | null>(`${url}/activeOrderList`, { showMyMainDeals }).pipe(map(extract('payload')));
    },
    register(connectionId, showMyMainDeals) {
      return http.post<void>(`${url}/register`, {
        dataStreamId: connectionId,
        showMyMainDeals,
      });
    },

    timeout: 110 * 1000,
    delayToAllowRefetch: 3 * 1000,
  };
}
