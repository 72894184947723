import { createAsyncThunk } from '@reduxjs/toolkit';
import { format } from 'date-fns';
import type { Dispatch } from 'redux';
import { toUtc } from '../../../utils/dateFormats';
import type { AppState } from '../../model';
import {
  type PostValidateOrderBody,
  type PostValidateOrderResponse,
  type PostValidateOrderResponseError,
  postValidateOrder,
} from '../api/validate';
import { type StopLossOrder, StopLossOrderStatus } from '../fxOrderBulkModel';
import { stopLossOrderInvalid, stopLossOrderStatusChanged, stopLossOrdersLoaded } from '../fxOrderBulkSlice';
import { extractErrorsFromValidation, loadStopLossOrderCSV } from '../fxOrderBulksUtilities';

export const loadStopLossOrdersThunk = createAsyncThunk(
  'fxOrderBulks/loadStopLossOrders',
  async (csvContent: string, { getState, dispatch }) => {
    const state = getState() as AppState; // TODO: how to avoid the cast ?

    const defaultEmails = state.userPreferences.data.splitNotifications
      ? state.userPreferences.data.splitNotificationsEmailsOrders
      : state.userPreferences.data.emails;

    const allStopLossOrders = loadStopLossOrderCSV(csvContent, [...defaultEmails]);

    dispatch(stopLossOrdersLoaded({ stopLosses: allStopLossOrders }));

    const bearer = window.sgwtConnect.getAuthorizationHeader();

    if (bearer === null) {
      return;
    }

    await Promise.all(allStopLossOrders.map((order) => validateOrder(order, bearer, dispatch)));
  },
);

const validateOrder = async (order: StopLossOrder, bearer: string, dispatch: Dispatch) => {
  const body = {
    isGtc: order.expiryDate === undefined,
    expiryDay: order.expiryDate !== undefined ? format(order.expiryDate, 'yyyy-MM-dd') : undefined,
    expiryTime: order.expiryDate !== undefined ? format(toUtc(order.expiryDate), 'HH:mm:ss') : undefined,
    bdrId: order.bdrId,
    ccyPair: order.currencyPair,
    way: order.way === 'Ask' ? 'buy' : 'sell',
    amountInCcy1: order.currencyIndex === 1 ? order.notional : undefined,
    amountInCcy2: order.currencyIndex === 2 ? order.notional : undefined,
    limitPrice: String(order.limitPrice),
    base64Emails: btoa(order.notificationEmails.join(';')),
    triggerMode: 'Spot',
    localTimeWithOffSet: 0, // TODO: do same thing from orderToBackendPayload ? -> orderClockOffset !== undefined && dateNow !== undefined ? dateNow.getTime() + orderClockOffset : 0,
  } satisfies PostValidateOrderBody;

  dispatch(stopLossOrderStatusChanged({ orderId: order.id, status: StopLossOrderStatus.VALIDATING }));

  const validation = await postValidateOrder(body, bearer).catch((error) => {
    console.error('error during the order validation', error);
    dispatch(stopLossOrderInvalid({ orderId: order.id, errors: [] }));

    return {
      Status: 500,
      Title: error.message,
    } as PostValidateOrderResponseError;
  });

  if (isValidationError(validation)) {
    dispatch(
      stopLossOrderInvalid({
        orderId: order.id,
        errors: [
          {
            code: 500,
            message: validation.Title,
          },
        ],
      }),
    );

    return;
  }

  validation.isReadyToSubmit
    ? dispatch(stopLossOrderStatusChanged({ orderId: order.id, status: StopLossOrderStatus.VALID }))
    : dispatch(
        stopLossOrderInvalid({
          orderId: order.id,
          errors: extractErrorsFromValidation(validation.validationResponseDetailContent),
        }),
      );

  return validation;
};

const isValidationError = (validation: PostValidateOrderResponse): validation is PostValidateOrderResponseError => {
  const testedValidation = validation as PostValidateOrderResponseError;
  return 'Status' in testedValidation && testedValidation.Status >= 400;
};
