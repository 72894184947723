import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { extract } from 'utils/object';
import type { ISgmeHttp } from 'utils/sgmeHttp';
import type { TradeCaptureBulkRequestWrapper, TradeCaptureBulkResponseWrapper } from './tradeCaptureModel';

export interface ITradeCaptureBulkApi {
  timeout: number;
  createNewVersion(
    sessionId: string,
    request: TradeCaptureBulkRequestWrapper,
  ): Observable<TradeCaptureBulkResponseWrapper>;
}

export function tradeCaptureApi(http: ISgmeHttp): ITradeCaptureBulkApi {
  return {
    createNewVersion(sessionId: string, request: TradeCaptureBulkRequestWrapper) {
      return http
        .post<TradeCaptureBulkResponseWrapper>(`api/tradeCapture/Bulk/${sessionId}`, request)
        .pipe(map(extract('payload')));
    },
    timeout: 10_000,
  };
}
