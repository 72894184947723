import { ClientPicker, type ClientPickerProps } from 'components/ClientPicker/ClientPicker';
import { UserHasProducts } from 'components/share';
import { IntlComponentBoundary } from 'utils/i18n/IntlComponentBoundary';
import { withTabId } from '../TabId';
import { ChangeTabTypeButton } from './ChangeTabTypeButton';
import { connectTabPopover } from './connect';
import en from './locales/en.json';
import fr from './locales/fr.json';

const messagesMap = { en, fr };

function TabPopoverRaw(props: Exclude<ClientPickerProps, 'banner' | 'button'>) {
  return (
    <IntlComponentBoundary messagesMap={messagesMap}>
      <ClientPicker
        button={
          <UserHasProducts products={['spot', 'forward']}>
            <ChangeTabTypeButton close={props.close} />
          </UserHasProducts>
        }
        {...props}
      />
    </IntlComponentBoundary>
  );
}

export const TabPopover = withTabId(connectTabPopover(TabPopoverRaw));
