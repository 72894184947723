import type { AppState } from 'state/model';
import { getTileMaxDate } from 'state/referenceData/referenceDataSelectors';
import { getAccumulatorState } from './accumulator';
import { getAccumulatorPriceCurrency } from './accumulatorInput';
import { getAccumulatorRfsPremiumByQuoteId } from './accumulatorRfsByQuoteId';
import { getForwardAccumulatorAccuType, getTargetAccumulatorAccuType } from './accumulatorValue';

export function isValidDateAccumulator(currentState: AppState, accuId: string) {
  const state = getAccumulatorState(currentState, accuId);
  const maxDate = getTileMaxDate(currentState, accuId);
  return (
    !state.values.expiryDate ||
    maxDate === undefined ||
    new Date(state.values.expiryDate) <= maxDate
  );
}
export const getPremiumSign = (state: AppState, quoteId: string) => {
  const premium = getAccumulatorRfsPremiumByQuoteId(state, quoteId);
  const priceCurrency = getAccumulatorPriceCurrency(state, quoteId);

  const priceCurrencyPremium = premium[priceCurrency.value - 1];
  return priceCurrencyPremium.ask > 0;
};

export const getAccuTypeAccumulator = (state: AppState, quoteId: string, instrument: string) => {
  if (instrument === "ForwardAccumulator" || instrument === "TargetAccumulator") {
    return instrument === "ForwardAccumulator"
      ? getForwardAccumulatorAccuType(state, quoteId)
      : getTargetAccumulatorAccuType(state, quoteId);
  }

}