import { createSelector } from 'reselect';
import type { AppState } from 'state/model';
import { getClient } from 'state/referenceData/referenceDataSelectors';
import { throwIfUndefined } from 'utils/maps';
import { type IWorkspaceTabState, type IWorkspaceTabs, type TabType, tabNameGenerators } from '../clientWorkspaceModel';
import { getTileOverriddenClientId } from './clientWorkspaceTilesSelectors';

export function getClientWorkspaceTabs(state: AppState) {
  return state.clientWorkspace.tabs;
}

export const getClientWorkspaceTabIds = createSelector(getClientWorkspaceTabs, Object.keys);

export function getClientWorkspaceTabNextName(state: AppState, type: TabType) {
  return tabNameGenerators[type](getBiggestNumber(state.clientWorkspace.tabs) + 1);
}

const getBiggestNumber = (tabs: IWorkspaceTabs): number =>
  Math.max(
    0,
    ...Object.values(tabs).map((tab) => {
      if (tab === undefined) {
        return 0;
      }

      const searchForNumber = /(\d+)$/.exec(tab.name);

      return searchForNumber === null ? 0 : Number.parseInt(searchForNumber[1], 10);
    }),
  );

export function isTabPresent(state: AppState, tabId: string) {
  return getClientWorkspaceTabs(state)[tabId] !== undefined;
}

export function getClientWorkspaceTab(state: AppState, tabId: string): Readonly<IWorkspaceTabState> {
  return throwIfUndefined(getClientWorkspaceTabs(state)[tabId], `Tab ${tabId} does not exist in state`);
}

export function getClientWorkspaceActiveTab(state: AppState) {
  return state.clientWorkspace.activeTab;
}

export function getTabType(state: AppState, tabId: string): TabType {
  return getClientWorkspaceTab(state, tabId).type;
}

export function getTabZoomedTile(state: AppState, tabId: string): string | null {
  return getClientWorkspaceTab(state, tabId).zoomedTile;
}

export function getClientWorkspaceActiveTabType(state: AppState): TabType | null {
  const { activeTab: activeTabId, tabs } = state.clientWorkspace;
  const activeTab = activeTabId === null ? null : tabs[activeTabId];
  return activeTab ? activeTab.type : null;
}

export function getClientWorkspaceIdByQuoteId(state: AppState, quoteId: string) {
  const clientWorkspaceEntry = Object.entries(getClientWorkspaceTabs(state)).find(
    ([tabId, tabState]) => tabState !== undefined && (tabState.tiles.includes(quoteId) || tabId === quoteId),
  );

  return throwIfUndefined(clientWorkspaceEntry, `Cannot find clientId for quote ${quoteId}`)[0];
}

export function getClientIdByQuoteId(state: AppState, quoteId: string) {
  const overrideClientId = getTileOverriddenClientId(state, quoteId);
  if (overrideClientId !== null && getClient(state, overrideClientId) !== undefined) {
    return overrideClientId;
  }
  const clientWorkspaceId = getClientWorkspaceIdByQuoteId(state, quoteId);
  const { clientId } = getClientWorkspaceTab(state, clientWorkspaceId);
  return clientId;
}

export function getWorkspaceTileIds(state: AppState, tabId: string) {
  return getClientWorkspaceTab(state, tabId).tiles;
}

// ███████╗██╗  ██╗ █████╗ ██████╗ ██╗███╗   ██╗ ██████╗
// ██╔════╝██║  ██║██╔══██╗██╔══██╗██║████╗  ██║██╔════╝
// ███████╗███████║███████║██████╔╝██║██╔██╗ ██║██║  ███╗
// ╚════██║██╔══██║██╔══██║██╔══██╗██║██║╚██╗██║██║   ██║
// ███████║██║  ██║██║  ██║██║  ██║██║██║ ╚████║╚██████╔╝
// ╚══════╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝╚═╝╚═╝  ╚═══╝ ╚═════╝

export const isTabShared = (state: AppState, tabId: string) => {
  return (state.clientWorkspace.tabs[tabId]?.sharedWithEmails ?? []).length > 0;
};

export const getTabOwnerEmail = (state: AppState, tabId: string) => {
  return state.clientWorkspace.tabs[tabId]?.ownerEmail;
};

export const getTabSharedWithEmails = (state: AppState, tabId: string) => {
  return state.clientWorkspace.tabs[tabId]?.sharedWithEmails ?? [];
};
