import { useAppSelector } from '../../state';
import { bulkOrderBdrCurrencyPairs } from '../../state/fxOrderBulks/fxOrderBulksSelectors';
import { getUserClientById } from '../../state/referenceData/referenceDataSelectors';
import { CurrencyPairSection } from './CurrencyPairSection';

type BdrSectionProps = {
  bdrId: string;
};

export const BdrSection = (props: BdrSectionProps) => {
  const { bdrId } = props;

  const allBulkOrderBdrIdCurrencyPairs = useAppSelector((state) => bulkOrderBdrCurrencyPairs(state, bdrId));
  const client = useAppSelector((state) => getUserClientById(state, bdrId));

  return (
    <div className="pb-3">
      <h4 data-e2e="bdr">
        <span data-e2e={`bdrId-${bdrId}`}>{bdrId}</span> -{' '}
        <span data-e2e={`bdr-${bdrId}-name`}>{client?.companyName}</span>
      </h4>

      {allBulkOrderBdrIdCurrencyPairs.map((currencyPair) => (
        <CurrencyPairSection key={currencyPair} bdrId={bdrId} currencyPair={currencyPair} />
      ))}
    </div>
  );
};
