import type { MapDispatchToPropsHOF, MapStateToPropsFactoryHOF } from 'typings/redux-utils';
import type { Selectors } from 'state/selectors';
import type { ActionCreators } from 'state/actions';
import type { AppState } from 'state/model';
import {
  type BlotterTab,
  type BlotterCountByTab,
  type BlotterMode,
  blotterTabs,
} from 'state/blotter/blotterModel';
import { createSelector } from 'reselect';

export type LiveBlotterTabConnectOwnProps = {}

export interface LiveBlotterTabConnectStateProps {
  tabs: readonly BlotterTab[];
  activeTab: BlotterTab;
  countByTab: BlotterCountByTab;
}

export interface LiveBlotterTabConnectDispatchProps {
  onTabClicked: (event: React.MouseEvent<HTMLLIElement>) => void;
}

// you can whitelist selectors after implementation

export type LiveBlotterTabConnectSelectorsKeys =
  | 'getBlotterActiveTab'
  | 'getProductsAccess'
  | 'getBlotterMode'
  | 'getBlotterIntradayCashTrades'
  | 'getBlotterHistoricalCashTrades'
  | 'getBlotterIntradayOptionTrades'
  | 'getBlotterHistoricalOptionTrades'
  | 'getBlotterIntradayAccumulatorTrades'
  | 'getBlotterHistoricalAccumulatorTrades'
  | 'getBlotterOrders';
export type LiveBlotterTabConnectSelectors = Pick<Selectors, LiveBlotterTabConnectSelectorsKeys>;

type BlotterCountGetter = (state: AppState, mode: BlotterMode) => number;

export const mapStateToPropsLiveBlotterTab: MapStateToPropsFactoryHOF<
  LiveBlotterTabConnectStateProps,
  LiveBlotterTabConnectOwnProps,
  AppState,
  LiveBlotterTabConnectSelectors
> = sl => () => {
  const getTabs = createSelector([sl.getProductsAccess], ({ targetAccu }) =>
    blotterTabs.filter(tab => {
      switch (tab) {
        case 'accumulator':
          return targetAccu;
        default:
          return true;
      }
    }),
  );
  const cashs: BlotterCountGetter = (state, mode = 'intraday') =>
    (mode === 'intraday'
      ? sl.getBlotterIntradayCashTrades(state)
      : sl.getBlotterHistoricalCashTrades(state)
    ).length;
  const options: BlotterCountGetter = (state, mode = 'intraday') =>
    (mode === 'intraday'
      ? sl.getBlotterIntradayOptionTrades(state)
      : sl.getBlotterHistoricalOptionTrades(state)
    ).length;
  const accumulators: BlotterCountGetter = (state, mode = 'intraday') =>
    (mode === 'intraday'
      ? sl.getBlotterIntradayAccumulatorTrades(state)
      : sl.getBlotterHistoricalAccumulatorTrades(state)
    ).length;
  const orders: BlotterCountGetter = state => sl.getBlotterOrders(state).length;
  const getCountByTab = createSelector(
    [cashs, options, accumulators, orders],
    (cash, option, accumulator, order) => ({
      cash,
      option,
      accumulator,
      order,
    }),
  );
  return state => ({
    tabs: getTabs(state),
    activeTab: sl.getBlotterActiveTab(state),
    countByTab: getCountByTab(state, sl.getBlotterMode(state)),
  });
};

export type LiveBlotterTabConnectActionCreatorsKeys = 'blotterTabChanged';
export type LiveBlotterTabConnectActionCreators = Pick<
  ActionCreators,
  LiveBlotterTabConnectActionCreatorsKeys
>;

export const mapDispatchToPropsLiveBlotterTab: MapDispatchToPropsHOF<
  LiveBlotterTabConnectDispatchProps,
  LiveBlotterTabConnectOwnProps,
  LiveBlotterTabConnectActionCreators
> = ac => (dispatch, _ownProps) => ({
  onTabClicked: (event: React.MouseEvent<HTMLLIElement>) => {
    dispatch(ac.blotterTabChanged(event.currentTarget.id as BlotterTab));
  },
});
